import React, { useContext } from "react";
import ClientVehicleInfoContext from "context/ClientVehicleProvider";

export default function AlterClientInfo() {
  const { clientVehicleInfo } = useContext(ClientVehicleInfoContext);

  const { name, vehicleNo, principalCode, bizPartnerCode } = clientVehicleInfo;
  return (
    <div className="overflow-auto">
      <table className="client_detail_Table">
        <tbody>
          <tr>
            <td>
              <label>Client: </label>
            </td>
            <td>
              <input
                type="text"
                className="form-control name_upper"
                name={name}
                value={name ?? ""}
                disabled={true}
              />
            </td>
            <td>
              <label>Vehicle No: </label>
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name={vehicleNo}
                value={vehicleNo ?? ""}
                disabled={true}
              />
            </td>
            <td>
              <label>Ins. Principal: </label>
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name={principalCode}
                value={principalCode ?? ""}
                disabled={true}
              />
            </td>
            <td>
              <label>Biz Partner: </label>
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name={bizPartnerCode}
                value={bizPartnerCode ?? ""}
                disabled={true}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
