import useAxiosPrivate from "context/useAxiosPrivate";
import { format } from "date-fns";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Enquires/ExistingEnquires/datatable.css";

export default function CollectionByBizPartner() {
  const axiosPrivate = useAxiosPrivate();

  // Start of datatable props
  const [orReport, setOrReport] = useState([]);
  const dt = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [orReportTotal, setOrReportTotal] = useState([]);

  const [lazyState, setlazyState] = useState({
    sortField: "createdAt",
    sortOrder: 1,
  });
  // End of datatable props

  // Start of Search filter Value
  const [searchPartner, setSearchPartner] = useState(null);
  const [searchOr, setSearchOr] = useState(null);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
  );
  // End of Search filter Value

  // Start of Dropdown Options
  const [bizPartnerDropdown, setBizPartnerDropdown] = useState([]);

  useEffect(() => {
    loadBizPartner();
    // eslint-disable-next-line
  }, []);

  const loadBizPartner = async (e) => {
    const result_Biz = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/biz_partner/names`
    );
    setBizPartnerDropdown(result_Biz.data);
  };
  // End of Dropdown Options

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Start of onSort -> set value and retrigger get method
  const onSort = (event) => {
    setlazyState(event);
  };

  useEffect(() => {
    loadOrReport();
    // eslint-disable-next-line
  }, [first, rows, lazyState, searchPartner, searchOr, startDate, endDate]);
  // End of onSort -> set value and retrigger get method

  const loadOrReport = async () => {
    setLoading(true);
    const sortOrder = lazyState.sortOrder === -1 ? "desc" : "asc";

    const result = await axiosPrivate.post(
      `${process.env.REACT_APP_API_URL}/report/biz_partner/search`,
      {
        bizPartnerId: searchPartner ? searchPartner : null,
        orNote: searchOr ? searchOr.trim() : null,
        startDate: format(new Date(startDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        endDate: format(new Date(endDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      },
      {
        params: {
          page: page,
          size: rows,
          sort: lazyState.sortField + "," + sortOrder,
        },
      }
    );

    let resultData = result.data.content;
    let getCash = 0.0;
    let getCheque = 0.0;
    let getCreditCard = 0.0;
    let getEPayment = 0.0;
    let getOther = 0.0;
    let getTotalAmount = 0.0;
    let getBalance = 0.0;

    resultData.forEach((item) => {
      getCash += parseFloat(item.cash);
      getCheque += parseFloat(item.cheque);
      getCreditCard += parseFloat(item.creditCard);
      getEPayment += parseFloat(item.epayment);
      getOther += parseFloat(item.other);
      getTotalAmount += parseFloat(item.totalAmount);
      getBalance += parseFloat(item.balance);
    });

    setOrReportTotal({
      cash: numberWithCommas(getCash),
      cheque: numberWithCommas(getCheque),
      creditCard: numberWithCommas(getCreditCard),
      ePayment: numberWithCommas(getEPayment),
      other: numberWithCommas(getOther),
      totalAmount: numberWithCommas(getTotalAmount),
      balance: numberWithCommas(getBalance),
    });

    setOrReport(result.data.content);
    setTotalRecords(result.data.totalElements);
    setLoading(false);
  };
  // End of Load Data to Datatable

  // Start of Datatable Options
  // -- Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // -- Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  // -- Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  // -- Datatable Header
  const header = (
    <div className="overflow-auto">
      {/* Start of 1st Row */}
      <div className="container ms-0 ps-0 mt-3">
        <div className="row row-cols-auto">
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-users"></i>
              </span>
              <Dropdown
                value={searchPartner}
                options={bizPartnerDropdown}
                optionLabel="code"
                optionValue="id"
                placeholder="Partner"
                className="custom_padding"
                onChange={(event) => setSearchPartner(event.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-file-pdf"></i>
              </span>
              <InputText
                placeholder="Official Receipt ORP"
                value={searchOr ?? ""}
                onChange={(event) => setSearchOr(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* End of 1st Row */}
      {/* Start of 2nd Row */}
      <div className="container ms-0 ps-0">
        <div className="row row-cols-auto mt-3">
          <div className="d-flex col-12">
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="startDate"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.value);
              }}
            />
            <span className="d-flex align-self-center px-2">to</span>
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="endDate"
              value={endDate}
              onChange={(e) => {
                const selectedDate = e.value;
                if (selectedDate) {
                  selectedDate.setHours(23, 59, 59, 999);
                  setEndDate(selectedDate);
                }
              }}
            />
          </div>
        </div>
      </div>
      {/* End of 3rd Row */}
      {/* Start of 3rd Row */}
      <div className="table-header mt-3">
        <span></span>
        <div>
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button w-8"
            onClick={exportCSV}
          />
        </div>
      </div>
      {/* End of 3rd Row */}
    </div>
  );

  // -- Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={3}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footerStyle={{ textAlign: "right" }}
          footer={orReportTotal.cash}
        />
        <Column
          footerStyle={{ textAlign: "right" }}
          footer={orReportTotal.cheque}
        />
        <Column
          footerStyle={{ textAlign: "right" }}
          footer={orReportTotal.creditCard}
        />
        <Column
          footerStyle={{ textAlign: "right" }}
          footer={orReportTotal.ePayment}
        />
        <Column
          footerStyle={{ textAlign: "right" }}
          footer={orReportTotal.other}
        />
        <Column
          footerStyle={{ textAlign: "right" }}
          footer={orReportTotal.totalAmount}
        />
        <Column
          footerStyle={{
            textAlign: "right",
            color: orReportTotal.balance < 0 ? "red" : "green",
          }}
          footer={orReportTotal.balance}
        />
      </Row>
    </ColumnGroup>
  );

  // -- Start of Datatable custom body template
  const orNoteBody = (rowData) => {
    let id = rowData.id;
    return (
      <React.Fragment>
        <Link
          to={`/eias/reports/collection_by_business_partner_view/${id}`}
          target={"_blank"}
        >
          {rowData.orNote}
        </Link>
      </React.Fragment>
    );
  };

  const balanceBody = (rowData) => {
    let balance = numberWithCommas(rowData.balance);
    let textStyle = { color: balance < 0 ? "red" : "green" };
    let formattedBalance = balance < 0 ? `(${balance.substring(1)})` : balance;

    return (
      <React.Fragment>
        <span style={textStyle}>{formattedBalance}</span>
      </React.Fragment>
    );
  };
  // -- End of Datatable custom body template
  // End of Datatable Options

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">
              Collection By Business Partner Report
            </h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={orReport}
              loading={loading}
              lazy={true}
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              header={header}
              onPage={onCustomPage}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              columnResizeMode="fit"
              showGridlines
              size="small"
              footerColumnGroup={footerGroup}
            >
              <Column
                field="bizPartnerCode"
                header="Biz Partner"
                sortable
              ></Column>
              <Column
                field="orid"
                body={orNoteBody}
                header="Official Receipt"
                sortable
              ></Column>
              <Column
                field="createdAt"
                header="Date"
                sortable
                style={{ textAlign: "right" }}
                body={(data) =>
                  format(new Date(data.createdAt), "dd/MM/yyyy") ?? "-"
                }
              ></Column>
              <Column
                field="cash"
                header="Cash"
                sortable
                style={{ textAlign: "right" }}
                body={(data) => numberWithCommas(data.cash) ?? "-"}
              ></Column>
              <Column
                field="cheque"
                header="Cheque"
                sortable
                style={{ textAlign: "right" }}
                body={(data) => numberWithCommas(data.cheque) ?? "-"}
              ></Column>
              <Column
                field="creditCard"
                header="Credit Card"
                sortable
                style={{ textAlign: "right" }}
                body={(data) => numberWithCommas(data.creditCard) ?? "-"}
              ></Column>
              <Column
                field="epayment"
                header="e-Payment"
                sortable
                style={{ textAlign: "right" }}
                body={(data) => numberWithCommas(data.epayment) ?? "-"}
              ></Column>
              <Column
                field="other"
                header="Other"
                sortable
                style={{ textAlign: "right" }}
                body={(data) => numberWithCommas(data.other) ?? "-"}
              ></Column>
              <Column
                field="totalAmount"
                header="Total"
                sortable
                style={{ textAlign: "right" }}
                body={(data) => numberWithCommas(data.totalAmount) ?? "-"}
              ></Column>
              <Column
                field="balance"
                header="Balance"
                style={{ textAlign: "right" }}
                body={balanceBody}
                sortable
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </section>
  );
}
