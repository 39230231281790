import useAxiosPrivate from "context/useAxiosPrivate";
import { format } from "date-fns";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Row } from "primereact/row";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../Enquires/ExistingEnquires/datatable.css";

export default function RoadTaxByType() {
  const axiosPrivate = useAxiosPrivate();

  const [roadTax, setRoadTax] = useState([]);
  const dt = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [chargesTotal, setChargesTotal] = useState(0);

  const [lazyState, setlazyState] = useState({
    sortField: "vehicleNo",
    sortOrder: 1,
  });

  const [searchKeyword, setSearchKeyword] = useState("");

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
  );

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Load Data to Datatable
  const onSort = (event) => {
    setlazyState(event);
  };

  useEffect(() => {
    loadReport();
    // eslint-disable-next-line
  }, [first, rows, searchKeyword, lazyState, startDate, endDate]);
  // End of onSort -> set value and retrigger get method

  const loadReport = async () => {
    setLoading(true);

    const sortOrder = lazyState.sortOrder === -1 ? "desc" : "asc";

    const result = await axiosPrivate.post(
      `${process.env.REACT_APP_API_URL}/report/road_tax_by_type/search`,
      {
        searchKey: searchKeyword ? searchKeyword.trim() : null,
        startDate: format(new Date(startDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        endDate: format(new Date(endDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      },
      {
        params: {
          page: page,
          size: rows,
          sort: lazyState.sortField + "," + sortOrder,
        },
      }
    );

    let resultData = result.data.content;
    let getTotal = 0.0;

    resultData.forEach((item) => {
      getTotal += parseFloat(item.supSvcRoadTaxCharges);
    });

    setChargesTotal(numberWithCommas(getTotal));
    setRoadTax(result.data.content);
    setTotalRecords(result.data.totalElements);
    setLoading(false);
  };
  // End of Load Data to Datatable

  // Start of Datatable Options
  // -- Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  // Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  // -- Datatable Header
  const header = (
    <div className="table-header">
      <div className="container ms-0 ps-0">
        <div className="row row-cols-auto mt-3">
          <div className="d-flex col-12">
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="startDate"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.value);
              }}
            />
            <span className="d-flex align-self-center px-2">to</span>
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="endDate"
              value={endDate}
              onChange={(e) => {
                const selectedDate = e.value;
                if (selectedDate) {
                  selectedDate.setHours(23, 59, 59, 999);
                  setEndDate(selectedDate);
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="table-header-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Keyword Search"
            value={searchKeyword}
            onChange={(event) => setSearchKeyword(event.target.value)}
          />
        </span>

        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
          onClick={exportCSV}
        />
      </div>
    </div>
  );

  // -- Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={8}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={chargesTotal} footerStyle={{ textAlign: "right" }} />
      </Row>
    </ColumnGroup>
  );

  // -- Datatable Column Header
  let roadTaxGroup = (
    <ColumnGroup>
      <Row>
        <Column
          field="vehicleNo"
          header="Vehicle No."
          sortable
          rowSpan={2}
        ></Column>
        <Column
          field="motorMakeModel"
          header="Make/Model"
          sortable
          rowSpan={2}
        ></Column>
        <Column field="motorCc" header="CC" rowSpan={2}></Column>
        <Column
          field="motorTypeOfBody"
          header="Body"
          sortable
          rowSpan={2}
        ></Column>
        <Column
          field="motorSelectClass"
          header="Class"
          sortable
          rowSpan={2}
        ></Column>
        <Column field="motorYear" header="Year" sortable rowSpan={2}></Column>
        <Column header="Road Tax" colSpan={3} />
      </Row>
      <Row>
        <Column field="supSvcIssuedDate" header="Issued" sortable />
        <Column field="supSvcRoadTaxMonths" header="Months" />
        <Column field="supSvcRoadTaxCharges" header="Charges" />
      </Row>
    </ColumnGroup>
  );

  // - Start of Datatable custom body template for
  const vehicleBody = (rowData) => {
    let id = rowData.id;
    let vn = rowData.vehicleNo;
    return (
      <React.Fragment>
        <Link to={`/eias/enquires/${id}/view`} target={"_blank"}>
          {vn ? vn : ""}
        </Link>
      </React.Fragment>
    );
  };

  // End of Datatable Options

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Road Tax by Type</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              dataKey="v_id"
              ref={dt}
              value={roadTax}
              lazy={true}
              loading={loading}
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              header={header}
              onPage={onCustomPage}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              columnResizeMode="fit"
              showGridlines
              size="small"
              headerColumnGroup={roadTaxGroup}
              footerColumnGroup={footerGroup}
            >
              <Column
                field="vehicleNo"
                header="Vehicle No."
                body={vehicleBody}
                sortable
              ></Column>
              <Column
                field="motorMakeModel"
                header="Make/Model"
                sortable
                body={(data) => data.motorMakeModel ?? "-"}
              ></Column>
              <Column
                field="motorCc"
                header="CC"
                sortable
                body={(data) => data.motorCc ?? "-"}
              ></Column>
              <Column
                field="motorTypeOfBody"
                header="Body"
                sortable
                body={(data) => data.motorTypeOfBody ?? "-"}
              ></Column>
              <Column
                field="motorSelectClass"
                header="Class"
                sortable
                body={(data) => data.motorSelectClass ?? "-"}
              ></Column>
              <Column
                field="motorYear"
                header="Year"
                sortable
                body={(data) => data.motorYear ?? "-"}
              ></Column>
              <Column
                field="supSvcIssuedDate"
                header="Issued"
                sortable
                body={(data) =>
                  format(new Date(data.supSvcIssuedDate), "dd/MM/yyyy") ?? "-"
                }
              />
              <Column
                field="supSvcRoadTaxMonths"
                header="Months"
                sortable
                body={(data) => data.supSvcRoadTaxMonths ?? "-"}
              />
              <Column
                field="supSvcRoadTaxCharges"
                header="Charges"
                sortable
                style={{ textAlign: "right" }}
                body={(data) =>
                  numberWithCommas(data.supSvcRoadTaxCharges) ?? "-"
                }
              />
            </DataTable>
          </div>
        </div>
      </div>
    </section>
  );
}
