import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClientVehicleInfoContext from "context/ClientVehicleProvider";
import { StatusEnum } from "context/globalEnums";
import useAxiosPrivate from "context/useAxiosPrivate";
import AlterClientInfo from "./Tab/AlterClientInfo";
import BillingInfo from "./Tab/BillingInfo";
import ClientInfo from "./Tab/ClientInfo";
import MotorInfo from "./Tab/MotorInfo";
import NonMotorInfo from "./Tab/NonMotorInfo";
import PremiumInfo from "./Tab/PremiumInfo";
import SupSvcInfo from "./Tab/SupSvcInfo";
import "./css/custom.css";
import "./css/nav.css";
import "./css/table.css";
import { addYears } from "date-fns";

export default function NewClientVehicle() {
  const axiosPrivate = useAxiosPrivate();
  const toast = useRef(null);
  const [key, setKey] = useState("client");

  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const { id } = useParams();
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  const handleKeyPress = useCallback((event) => {
    let keysPressed = {};

    keysPressed[event.key] = true;
    if (keysPressed["Alt"] && event.key === "c") {
      setKey("client");
    }
    if (keysPressed["Alt"] && event.key === "o") {
      setKey("motor");
    }
    if (keysPressed["Alt"] && event.key === "n") {
      setKey("non_motor_medical");
    }
    if (keysPressed["Alt"] && event.key === "u") {
      setKey("sup_svc");
    }
    if (keysPressed["Alt"] && event.key === "p") {
      setKey("premium");
    }
    if (keysPressed["Alt"] && event.key === "b") {
      setKey("billing");
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const fetchClientVehicleData = async () => {
    const result = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/client_vehicle/${id}`
    );
    setTimeout(() => {
      setClientVehicleInfo(result.data);
      setClientVehicleInfo((prevState) => ({
        ...prevState,
        motorIssuedDate: new Date(result.data.motorIssuedDate),
        motorPeriodStart: new Date(result.data.motorPeriodStart),
        motorPeriodEnd: new Date(result.data.motorPeriodEnd),
        nonMotorIssuedDate: new Date(result.data.nonMotorIssuedDate),
        nonMotorPeriodStart: new Date(result.data.nonMotorPeriodStart),
        nonMotorPeriodEnd: new Date(result.data.nonMotorPeriodEnd),
        supSvcIssuedDate: new Date(result.data.supSvcIssuedDate),
      }));
      setLoading(false);
      if (result.data.status !== StatusEnum.DRAFT) {
        setKey("billing");
      }
    }, 200);
  };

  const fetchClientVehicleDataNew = async () => {
    const result = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/client_vehicle/${id}`
    );
    setTimeout(() => {
      setClientVehicleInfo(result.data);
      setClientVehicleInfo((prevState) => ({
        ...prevState,
        status: StatusEnum.DRAFT,
        getStatus: StatusEnum.DRAFT,
        motorCoverNote: "",
        nonMotorCoverNote: "",
        motorIssuedDate: new Date(),
        motorPeriodStart: addYears(result.data.motorPeriodStart, 1),
        motorPeriodEnd: addYears(result.data.motorPeriodEnd, 1),
        nonMotorIssuedDate: new Date(),
        nonMotorPeriodStart: addYears(result.data.nonMotorPeriodStart, 1),
        nonMotorPeriodEnd: addYears(result.data.nonMotorPeriodEnd, 1),
      }));
      setLoading(false);
    }, 200);
  };

  const loadClientVehicleData = async () => {
    if (location.pathname === `/eias/enquires/create`) {
      setLoading(true);
      setTimeout(() => {
        setClientVehicleInfo({
          principalId: "",
          principalCode: "",
          bizPartnerId: "",
          bizPartnerCode: "",
          status: StatusEnum.DRAFT,
          getStatus: StatusEnum.DRAFT,

          // Clients Detail
          name: "",
          nric: "",
          address: "",
          phoneNumber1: "",
          phoneNumber2: "",
          phoneNumber3: "",
          email: "",

          // Motor Detail
          vehicleNo: "",
          motorMakeModel: "",
          motorCc: "",
          motorYear: "",
          motorSeating: "",
          motorSelectClass: "PRIVATE CAR",

          motorCoverNote: "",
          motorIssuedDate: new Date(),
          motorSelectAction: "NEW",
          motorPeriodStart: new Date(),
          motorPeriodEnd: new Date(
            new Date().setDate(new Date().getDate() + 364)
          ),
          motorTypeOfBody: "",
          motorHPCo: "",
          motorRemarks: "",
          motorSelectCoverType: "COMPREHENSIVE",
          motorPartnerCommissionRate: "0",
          motorPrincipalTariff: "0",

          // Non-Motor Detail
          nonMotorCoverNote: "",
          nonMotorIssuedDate: new Date(),
          nonMotorSelectAction: "NEW",
          nonMotorPeriodStart: new Date(),
          nonMotorPeriodEnd: new Date(
            new Date().setDate(new Date().getDate() + 364)
          ),
          nonMotorClassOfIns: "",
          nonMotorPrincipalTariff: "0.00",
          nonMotorPartnerCommission: "0.00",
          nonMotorDescription: "",
          nonMotorRmPerRoom: "0.00",
          nonMotorSumInsuredRM: "0.00",
          nonMotorBasicPremium: "0.00",
          nonMotorGovtTax: "8",
          nonMotorTotal: "0.00",
          nonMotorStampDuty: "0.00",
          nonMotorRemarks: "",
          nonMotorOtherCharges: "0.00",
          nonMotorTotalPremium: "0.00",
          nonMotorPrincipalCommission: "0.00",
          nonMotorGrossPremium: "0.00",

          // Supporting Services
          supSvc: {
            supSvcIssuedDate: new Date(),
            supSvcRoadTaxMonths: "12",
            supSvcRoadTaxCharges: "0.00",
            supSvcRoadTaxJpjRunner: "0.00",
            supSvcRoadTaxRevenue: "0.00",
            supSvcLicenseYears: "1",
            supSvcLicenseCharges: "0.00",
            supSvcLicenseJpjRunner: "0.00",
            supSvcLicenseRevenue: "0.00",
            supSvcJpjRenewal: "ROAD TAX RENEWAL CHARGE",
            supSvcJpjRenewalCharges: "20.00",
            supSvcJpjRenewalJpjRunner: "10.00",
            supSvcJpjRenewalRevenue: "10.00",
            supSvcOthers1: "",
            supSvcOthers1Charges: "0.00",
            supSvcOthers1JpjRunner: "0.00",
            supSvcOthers1Revenue: "0.00",
            supSvcOthers2: "",
            supSvcOthers2Charges: "0.00",
            supSvcOthers2JpjRunner: "0.00",
            supSvcOthers2Revenue: "0.00",
            supSvcOthers3: "",
            supSvcOthers3Charges: "0.00",
            supSvcOthers3JpjRunner: "0.00",
            supSvcOthers3Revenue: "0.00",
            supSvcTotalCharges: "0.00",
            supSvcTotalJpjRunner: "0.00",
            supSvcTotalRevenue: "0.00",
          },

          // Premium
          // Motor Premium
          premiumSumInsured: "0",
          premiumExcess: "0",
          premiumBasicPremium: "0.00",
          premiumTrailerSchedulePremium: "0.00",
          premiumAllRiderPercentage: "0",
          premiumAllRider: "0.00",
          premiumSubTotal1: "0.00",
          premiumLoadingChargePercentage: "0",
          premiumLoadingCharge: "0.00",
          premiumSubTotal2: "0.00",
          premiumNcdPercentage: "0",
          premiumNcd: "0.00",
          premiumExtraBenefits: "0.00",
          premiumServiceTaxPercentage: "8",
          premiumServiceTax: "0.00",
          premiumStampDuty: "10.00",
          premiumTransferFees: "0.00",
          premiumTotalMotorPremium: "0.00",

          // Supporting Services Premium
          premiumRoadTaxTotal: "0.00",
          premiumLicenseTotal: "0.00",
          premiumJpjRenewal: "0.00",
          premiumOthersTotal: "0.00",
          premiumTotalSupportingServices: "0.00",

          // Total Amount Payable
          premiumTotalAmountPayable: "0.00",
          premiumGrossPremium: "0.00",
          premiumPrincipalCommission: "0.00",
          premiumNetPremium: "0.00",

          //Extra Benefits
          premiumExtraBenefitsWindscreenCoverValue: "0.00",
          premiumExtraBenefitsWindscreenCoverTotal: "0.00",
          premiumExtraBenefitsLegalLiabilityToPassengersCheckBox: "",
          premiumExtraBenefitsLegalLiabilityToPassengersValue: "0",
          premiumExtraBenefitsLegalLiabilityToPassengersTotal: "0.00",
          premiumExtraBenefitsActOfNegligenceCheckbox: "",
          premiumExtraBenefitsActOfNegligenceTotal: "0.00",
          premiumExtraBenefitsStrikeCommotionCoverCheckbox: "",
          premiumExtraBenefitsStrikeCommotionCoverTotal: "0.00",
          premiumExtraBenefitsAllDriversCheckbox: "",
          premiumExtraBenefitsAllDriversTotal: "0.00",
          premiumExtraBenefitsAllDriversInvCheckbox: "",
          premiumExtraBenefitsAllDriversInvTotal: "0.00",
          premiumExtraBenefitsConvulsionOfNatureCheckbox: "",
          premiumExtraBenefitsConvulsionOfNatureTotal: "0.00",
          premiumExtraBenefitsAdditionalDriverNamed: "",
          premiumExtraBenefitsAdditionalDriverTotal: "0.00",
          premiumExtraBenefitsRadioCasetteValue: "0.00",
          premiumExtraBenefitsRadioCasetteTotal: "0.00",
          premiumExtraBenefitsInsuranceProtectionTotal: "0.00",
          premiumExtraBenefitsInclusionOfDamageTotal: "0.00",
          premiumExtraBenefitsInclusionOfAccidentalTotal: "0.00",
          premiumExtraBenefitsExtensionOfCoverTotal: "0.00",
          premiumExtraBenefitsGeneraliMCPATotal: "0.00",
          premiumExtraBenefitsUnlimitedTowingTotal: "0.00",
          premiumExtraBenefitsOther1Value: "",
          premiumExtraBenefitsOther1Total: "0.00",
          premiumExtraBenefitsOther2Value: "",
          premiumExtraBenefitsOther2Total: "0.00",
          premiumExtraBenefitsOther3Value: "",
          premiumExtraBenefitsOther3Total: "0.00",
          premiumExtraBenefitsTotal: "0.00",

          //Billing
          billingMotorPremium: "0.00",
          billingDiscount: "0.00",
          billingNonMotorPremium: "0.00",
          billingDiscountNonMotor: "0.00",
          billingSupportingServices: "0.00",
          billingSubtotal: "0.00",
          billingSubtotalDiscount: "0.00",
          billingTotalPayable: "0.00",
          orpayment: "",

          ssn: [],
          third_party_premium: "0.00",
        });
        setLoading(false);
      }, 200);
    }
    if (location.pathname === `/eias/enquires/${id}/view`) {
      fetchClientVehicleData();
    }

    if (location.pathname === `/eias/enquires/${id}/new`) {
      fetchClientVehicleDataNew();
    }
  };

  useEffect(() => {
    loadClientVehicleData();
    // eslint-disable-next-line
  }, [id, location]);

  let navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    if (clientVehicleInfo.name === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Name Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (clientVehicleInfo.nric === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>NRIC Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (clientVehicleInfo.vehicleNo === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Vehicle No. Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      clientVehicleInfo.motorCoverNote === "" &&
      clientVehicleInfo.nonMotorCoverNote === "" &&
      (clientVehicleInfo.supSvc === null ||
        clientVehicleInfo.supSvc?.supSvcTotalCharges === "0.00")
    ) {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Motor Cover No Field</b> or &nbsp;
            <b>Non Motor Cover No Field</b> or &nbsp;
            <b>Sup Svc Charges Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      clientVehicleInfo.principalId === "" &&
      (clientVehicleInfo.supSvc === null ||
        clientVehicleInfo.supSvc?.supSvcTotalCharges === "0.00")
    ) {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Principal Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (clientVehicleInfo.bizPartnerId === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Biz Partner Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      clientVehicleInfo.motorCoverNote === "" &&
      Math.abs(clientVehicleInfo.premiumTotalMotorPremium) > 0
    ) {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Motor Cover No Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      clientVehicleInfo.nonMotorCoverNote === "" &&
      Math.abs(clientVehicleInfo.nonMotorTotalPremium) > 0
    ) {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Non Motor Cover No Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      clientVehicleInfo.billingTotalPayable === "" ||
      isNaN(clientVehicleInfo.billingTotalPayable) === true ||
      clientVehicleInfo.billingTotalPayable === "0.00"
    ) {
      toast.current.show({
        severity: "error",
        summary: "Empty Total Payable",
        detail: (
          <span>
            <b>Total Payable Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      clientVehicleInfo.motorPeriodStart < clientVehicleInfo.motorIssuedDate
    ) {
      toast.current.show({
        severity: "error",
        summary: "Check Period Date",
        detail: (
          <span>
            <b>Period Date Cannot Before Issue Date</b>
          </span>
        ),
        life: 3000,
      });
    } else {
      createClientVehicle();
    }
  };

  const createClientVehicle = async (e) => {
    var object = clientVehicleInfo;
    var format_ClientVehicleInfo = {};

    Object.keys(object).forEach(function (key) {
      if (object[key] !== "" && object[key] !== undefined) {
        const value = object[key];
        if (typeof value === "string") {
          format_ClientVehicleInfo[key] = value.trim();
        } else {
          format_ClientVehicleInfo[key] = value;
        }
      }
    });

    format_ClientVehicleInfo["name"] =
      format_ClientVehicleInfo["name"].toUpperCase();

    format_ClientVehicleInfo["status"] = format_ClientVehicleInfo["getStatus"];

    if (format_ClientVehicleInfo.supSvc) {
      format_ClientVehicleInfo.supSvc.supSvcIssuedDate = new Date(
        format_ClientVehicleInfo.supSvc.supSvcIssuedDate
      );
    }

    if (location.pathname.includes("view")) {
      try {
        await axiosPrivate.put(
          `${process.env.REACT_APP_API_URL}/client_vehicle/${id}`,
          JSON.stringify(format_ClientVehicleInfo),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        toast.current.show({
          severity: "info",
          summary: "Client/Vehicle Updated",
          detail: (
            <span>
              Client: <b>{format_ClientVehicleInfo["name"]}</b>
              <br></br>
              Vehicle: <b>{format_ClientVehicleInfo["vehicleNo"]}</b>
              <br></br> Records Updated Successfully
            </span>
          ),
          life: 3000,
        });
        navigate(`/eias/enquires/${format_ClientVehicleInfo["id"]}/view`);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Failed to Create Client/Vehicle",
          detail: (
            <span>
              <b>{error.response.data[0].description}</b>
            </span>
          ),
          life: 3000,
        });
      }
    } else {
      try {
        const result = await axiosPrivate.post(
          `${process.env.REACT_APP_API_URL}/client_vehicle`,
          JSON.stringify(format_ClientVehicleInfo),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        toast.current.show({
          severity: "info",
          summary: "Client/Vehicle Created",
          detail: (
            <span>
              Client: <b>{format_ClientVehicleInfo["name"]}</b>
              <br></br>
              Vehicle: <b>{format_ClientVehicleInfo["vehicleNo"]}</b>
              <br></br> Records Created Successfully
            </span>
          ),
          life: 3000,
        });
        navigate(`/eias/enquires/${result.data}/view`);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Failed to Create Client/Vehicle",
          detail: (
            <span>
              <b>{error.response.data[0].description}</b>
            </span>
          ),
          life: 3000,
        });
      }
    }
  };

  return (
    <section className="pt-2 pb-5 bg-theme">
      <b>
        <div className="card m-4 bg-theme-light">
          <div className="card-body">
            <div className="card-title">
              <Toast ref={toast} />
              <ConfirmDialog />
              <h2 className="text-start">Client</h2>
            </div>
            {loading ? (
              <div className="loading-container">
                <ProgressSpinner />
              </div>
            ) : (
              <div className="card-text text-start">
                <form onSubmit={(e) => onSubmit(e)}>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 nav nav-tabs tabs-dark bg-dark"
                  >
                    <Tab
                      eventKey="client"
                      title={
                        <span>
                          <u>C</u>lient
                        </span>
                      }
                    >
                      <AlterClientInfo />
                      <ClientInfo />
                      <Button
                        type="button"
                        className="float-end mt-3"
                        label="Next"
                        icon="pi pi-angle-double-right"
                        iconPos="right"
                        onClick={() => setKey("motor")}
                      />
                      {/* pi-angle-double-right */}
                    </Tab>
                    {/* Start of Motor Tab */}
                    {(() => {
                      if (
                        location.pathname === `/eias/enquires/${id}/view` &&
                        clientVehicleInfo.motorCoverNote === null &&
                        clientVehicleInfo.status !== StatusEnum.DRAFT
                      ) {
                        return <React.Fragment></React.Fragment>;
                      } else {
                        return (
                          <Tab
                            eventKey="motor"
                            title={
                              <span>
                                M<u>o</u>tor
                              </span>
                            }
                          >
                            <AlterClientInfo />
                            <MotorInfo />
                            {/* Start of Get Next Tab */}
                            {(() => {
                              if (
                                location.pathname ===
                                  `/eias/enquires/${id}/view` &&
                                clientVehicleInfo.nonMotorCoverNote ===
                                  undefined &&
                                (clientVehicleInfo.supSvc === undefined ||
                                  clientVehicleInfo.supSvc
                                    ?.supSvcTotalCharges === "0.00") &&
                                clientVehicleInfo.status !== StatusEnum.DRAFT
                              ) {
                                return (
                                  <React.Fragment>
                                    <Button
                                      type="button"
                                      className="float-end mt-3"
                                      label="Next"
                                      icon="pi pi-angle-double-right"
                                      iconPos="right"
                                      onClick={() => setKey("premium")}
                                    />
                                  </React.Fragment>
                                );
                              } else if (
                                location.pathname.includes("view") &&
                                clientVehicleInfo.nonMotorCoverNote !==
                                  undefined &&
                                clientVehicleInfo.status !== StatusEnum.DRAFT
                              ) {
                                return (
                                  <React.Fragment>
                                    <Button
                                      type="button"
                                      className="float-end mt-3"
                                      label="Next"
                                      icon="pi pi-angle-double-right"
                                      iconPos="right"
                                      onClick={() =>
                                        setKey("non_motor_medical")
                                      }
                                    />
                                  </React.Fragment>
                                );
                              } else if (
                                location.pathname.includes("view") &&
                                clientVehicleInfo.nonMotorCoverNote ===
                                  undefined &&
                                clientVehicleInfo.supSvc?.supSvcTotalCharges !==
                                  "0.00" &&
                                clientVehicleInfo.status !== StatusEnum.DRAFT
                              ) {
                                return (
                                  <React.Fragment>
                                    <Button
                                      type="button"
                                      className="float-end mt-3"
                                      label="Next"
                                      icon="pi pi-angle-double-right"
                                      iconPos="right"
                                      onClick={() => setKey("sup_svc")}
                                    />
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <React.Fragment>
                                    <Button
                                      type="button"
                                      className="float-end mt-3"
                                      label="Next"
                                      icon="pi pi-angle-double-right"
                                      iconPos="right"
                                      onClick={() =>
                                        setKey("non_motor_medical")
                                      }
                                    />
                                  </React.Fragment>
                                );
                              }
                            })()}
                            {/* End of Get Next Tab */}
                          </Tab>
                        );
                      }
                    })()}
                    {/* End of Motor Tab */}

                    {/* Start of Non Motor Tab */}
                    {(() => {
                      if (
                        location.pathname.includes("view") &&
                        clientVehicleInfo.nonMotorCoverNote === undefined &&
                        clientVehicleInfo.status !== StatusEnum.DRAFT
                      ) {
                        return <React.Fragment></React.Fragment>;
                      } else {
                        return (
                          <Tab
                            eventKey="non_motor_medical"
                            title={
                              <span>
                                <u>N</u>on-Motor/Medical
                              </span>
                            }
                          >
                            <AlterClientInfo />
                            <NonMotorInfo />
                            {/* Start of Get Next Tab */}
                            {(() => {
                              if (
                                location.pathname.includes("view") &&
                                (clientVehicleInfo.supSvc === undefined ||
                                  clientVehicleInfo.supSvc
                                    ?.supSvcTotalCharges === "0.00") &&
                                clientVehicleInfo.status !== StatusEnum.DRAFT
                              ) {
                                return (
                                  <React.Fragment>
                                    <div className="w-75">
                                      <Button
                                        type="button"
                                        className="float-end mt-3"
                                        label="Next"
                                        icon="pi pi-angle-double-right"
                                        iconPos="right"
                                        onClick={() => setKey("premium")}
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <React.Fragment>
                                    <div className="w-75">
                                      <Button
                                        type="button"
                                        className="float-end mt-3"
                                        label="Next"
                                        icon="pi pi-angle-double-right"
                                        iconPos="right"
                                        onClick={() => setKey("sup_svc")}
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                              }
                            })()}
                            {/* End of Get Next Tab */}
                          </Tab>
                        );
                      }
                    })()}
                    {/* End of Non Motor Tab */}

                    {/* Start of Sup Svc Tab */}
                    {(() => {
                      if (
                        location.pathname.includes("view") &&
                        (clientVehicleInfo.supSvc === undefined ||
                          clientVehicleInfo.supSvc?.supSvcTotalCharges ===
                            "0.00") &&
                        clientVehicleInfo.status !== StatusEnum.DRAFT
                      ) {
                        return <React.Fragment></React.Fragment>;
                      } else {
                        return (
                          <Tab
                            eventKey="sup_svc"
                            title={
                              <span>
                                S<u>u</u>p.Svc
                              </span>
                            }
                          >
                            <AlterClientInfo />
                            <SupSvcInfo />
                            <div className="width_80">
                              <Button
                                type="button"
                                className="float-end mt-3"
                                label="Next"
                                icon="pi pi-angle-double-right"
                                iconPos="right"
                                onClick={() => setKey("premium")}
                              />
                            </div>
                          </Tab>
                        );
                      }
                    })()}
                    {/* End of Sup Svc Tab */}
                    <Tab
                      eventKey="premium"
                      title={
                        <span>
                          <u>P</u>remium
                        </span>
                      }
                    >
                      <AlterClientInfo />
                      <PremiumInfo />
                      <div className="w-75">
                        <Button
                          type="button"
                          className="float-end mt-3"
                          label="Next"
                          icon="pi pi-angle-double-right"
                          iconPos="right"
                          onClick={() => setKey("billing")}
                        />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="billing"
                      title={
                        <span>
                          <u>B</u>illing
                        </span>
                      }
                    >
                      <AlterClientInfo />
                      <BillingInfo />
                    </Tab>
                  </Tabs>
                </form>
              </div>
            )}
          </div>
        </div>
      </b>
    </section>
  );
}
