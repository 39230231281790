import useAxiosPrivate from "context/useAxiosPrivate";
import { format } from "date-fns";
import "primeicons/primeicons.css";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Row } from "primereact/row";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PrintNoteEnum } from "context/globalEnums";
import "../Enquires/ExistingEnquires/datatable.css";

export default function CollectionByBizPartnerView() {
  const axiosPrivate = useAxiosPrivate();

  // Start of datatable props
  const [clientVehicle, setClientVehicle] = useState([]);
  const dt = useRef(null);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [totals, setTotals] = useState([]);

  const [loading, setLoading] = useState(true);

  const {
    balance,
    cash,
    cashRef,
    cheque,
    chequeRef,
    creditCard,
    creditCardRef,
    epayment,
    epaymentRef,
    otherRef,
    other,
    totalAmount,
    orNote,
    createdAt,
  } = clientVehicle;
  // End of datatable props

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Load Data to Datatable
  useEffect(() => {
    loadReport();
    initFilters1();
    // eslint-disable-next-line
  }, []);

  const { id } = useParams();

  const loadReport = async () => {
    setLoading(true);
    const result = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/payment/${id}`
    );

    let result_data = result.data;

    let getTotalPayable = 0.0;
    let getPayAmount = 0.0;

    result_data.payments.forEach(({ totalPayable, payAmount }) => {
      getTotalPayable += parseFloat(totalPayable);
      getPayAmount += parseFloat(payAmount);
    });

    setTotals({
      getTotalPayable: numberWithCommas(getTotalPayable),
      getPayAmount: numberWithCommas(getPayAmount),
    });

    setClientVehicle(result_data);
    setLoading(false);
  };

  // End of Load Data to Datatable

  // Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // Filter Datatable Global
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value.trim();

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  // Initialize Filter Values
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  // Datatable Header
  const header = (
    <div className="table-header">
      <Link to={`/eias/reports/collection_by_business_partner`}>
        <Button
          icon="pi pi-angle-left"
          label="Back"
          className="width"
          type="button"
        ></Button>
      </Link>
      <div className="table-header-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>

        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
          onClick={exportCSV}
        />
      </div>
    </div>
  );

  // Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={4}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={totals.getTotalPayable} />
        <Column footer={totals.getPayAmount} />
        <Column />
      </Row>
    </ColumnGroup>
  );

  // - Start of Datatable custom body template for
  const totalPayableBody = (rowData) => {
    return <>{numberWithCommas(parseFloat(rowData.totalPayable).toFixed(2))}</>;
  };

  const paymentAmountBody = (rowData) => {
    return <>{numberWithCommas(parseFloat(rowData.payAmount).toFixed(2))}</>;
  };

  const vehicleNoBody = (rowData) => {
    return (
      <>
        <Link to={`/eias/enquires/${rowData.id}/view`} target={"_blank"}>
          {rowData.vehicleNo}
        </Link>
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-print"
          type="button"
          label="Print Customer Receipt"
          onClick={() =>
            printNote(PrintNoteEnum.OFFICIAL_RECEIPT_CUSTOMER, rowData.id)
          }
        ></Button>
      </div>
    );
  };
  // End of Datatable Options

  const printNote = async (printType, clientVehicleId) => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_API_URL}/print/${id}`,
        {
          params: {
            printType: printType,
            subId: clientVehicleId,
          },
          responseType: "blob",
        }
      );

      if (response.data) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        window.open(url, "_blank");
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
    setLoading(false);
  };

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">
              Collection By Business Partner Report
            </h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={clientVehicle.payments}
              loading={loading}
              dataKey="v_id"
              responsiveLayout="scroll"
              filters={filters1}
              header={header}
              footerColumnGroup={footerGroup}
              resizableColumns
              columnResizeMode="fit"
              showGridlines
              size="small"
            >
              <Column
                field="debitNote"
                header="Debit Note No"
                sortable
              ></Column>
              <Column
                field="issuedAt"
                header="Debit Note Date"
                sortable
              ></Column>
              <Column
                field="vehicleNo"
                header="Vehicle No"
                body={vehicleNoBody}
                sortable
              ></Column>
              <Column field="coverNote" header="Cover No."></Column>
              <Column
                field="totalPayable"
                header="Total Payable (RM)"
                body={totalPayableBody}
                sortable
                className="pe-3"
              ></Column>
              <Column
                field="payAmount"
                header="Paid Amount (RM)"
                body={paymentAmountBody}
                sortable
                className="pe-3"
              ></Column>
              <Column
                header="Action"
                body={actionBodyTemplate}
                exportable={false}
                style={{ width: "10%" }}
              ></Column>
            </DataTable>
            <br />
            <table className="bg-white table-bordered w-50">
              <thead>
                <tr>
                  <th className="p-2">
                    <b>Collection Mode</b>
                  </th>
                  <th className="p-2">
                    <b>Reference</b>
                  </th>
                  <th className="p-2 text-end">
                    <b>Amount (RM)</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {parseFloat(cash) > 0 && (
                  <tr>
                    <td>Cash</td>
                    <td>{cashRef}</td>
                    <td className="text-end">
                      {cash ? numberWithCommas(parseFloat(cash).toFixed(2)) : 0}
                    </td>
                  </tr>
                )}
                {parseFloat(cheque) > 0 && (
                  <tr>
                    <td>Cheque</td>
                    <td>{chequeRef}</td>
                    <td className="text-end">
                      {cheque
                        ? numberWithCommas(parseFloat(cheque).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                )}
                {parseFloat(creditCard) > 0 && (
                  <tr>
                    <td>DIRECTORS CREDIT CARD</td>
                    <td>{creditCardRef}</td>
                    <td className="text-end">
                      {creditCard
                        ? numberWithCommas(parseFloat(creditCard).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                )}
                {parseFloat(epayment) > 0 && (
                  <tr>
                    <td>ePAYMENT</td>
                    <td>{epaymentRef}</td>
                    <td className="text-end">
                      {epayment
                        ? numberWithCommas(parseFloat(epayment).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                )}
                {parseFloat(other) > 0 && (
                  <tr>
                    <td>OTHER</td>
                    <td>{otherRef}</td>
                    <td className="text-end">
                      {other
                        ? numberWithCommas(parseFloat(other).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                )}
                {parseFloat(balance) !== 0 && (
                  <tr>
                    <td>Balance</td>
                    <td>{balance > 0 ? "Surplus" : "Shortfall"}</td>
                    <td className="text-end">
                      {balance
                        ? numberWithCommas(
                            parseFloat(Math.abs(balance)).toFixed(2)
                          )
                        : 0}
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={2} className="text-end">
                    <b>Total Payment RM</b>
                  </td>
                  <td className="text-end">
                    <b>{totals.getPayAmount}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table className="or_table bg-white w-50 custom_padding">
              <tbody>
                <tr>
                  <td className="w_40">
                    <b>Official Receipt: {orNote}</b>
                  </td>
                  <td>
                    <b>
                      Created Date:{" "}
                      {createdAt && format(new Date(createdAt), "dd-MM-yyyy")}
                    </b>
                  </td>
                  <td className="w-25">
                    <Button
                      icon="pi pi-print"
                      type="button"
                      label="Print"
                      className="w-100"
                      onClick={() => printNote(PrintNoteEnum.OFFICIAL_RECEIPT)}
                    ></Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}
