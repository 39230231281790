import React, { createContext, useState } from "react";

const ClientVehicleInfoContext = createContext({});

export const ClientVehicleProvider = ({ children }) => {
  const [clientVehicleInfo, setClientVehicleInfo] = useState({});
  return (
    <ClientVehicleInfoContext.Provider
      value={{ clientVehicleInfo, setClientVehicleInfo }}
    >
      {children}
    </ClientVehicleInfoContext.Provider>
  );
};

export default ClientVehicleInfoContext;
