import { StatusEnum } from "context/globalEnums";
import useAxiosPrivate from "context/useAxiosPrivate";
import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { Toast } from "primereact/toast";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./css/custom.css";
import "./css/nav.css";
import "./css/table.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calendar } from "primereact/calendar";
import { addDays, format } from "date-fns";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

export default function QuickQuotation() {
  const axiosPrivate = useAxiosPrivate();
  const toast = useRef(null);
  const [key, setKey] = useState("client");
  const [nameModal, setNameModal] = useState(false);
  const [vehicleModal, setVehicleModal] = useState(false);
  const [benefitsModal, setBenefitsModal] = useState(false);

  function openNameModal() {
    setNameModal(!nameModal);
  }

  function openVehicleModal() {
    setVehicleModal(!vehicleModal);
  }

  function openBenefitsModal() {
    setBenefitsModal(!benefitsModal);
  }

  const vehicle_class = [
    { name: "PRIVATE CAR", value: "PRIVATE_CAR" },
    { name: "GOODS VEHICLE - C PERMIT", value: "GOODS_VEHICLE_C_PERMIT" },
    { name: "GOODS VEHICLE - A PERMIT", value: "GOODS_VEHICLE_A_PERMIT" },
    { name: "MOTORCYCLE", value: "MOTORCYCLE" },
    { name: "COMMERCIAL MOTORCYCLE", value: "COMMERCIAL_MOTORCYCLE" },
    { name: "MOTORCYCLE WITH SIDECAR", value: "MOTORCYCLE_WITH_SIDECAR" },
    { name: "HIRE CAR", value: "HIRE_CAR" },
    { name: "OMNI FACTORY BUS - PRIVATE", value: "OMNI_FACTORY_BUS_PRIVATE" },
    { name: "OMNI FACTORY BUS - PUBLIC", value: "OMNI_FACTORY_BUS_PUBLIC" },
    { name: "OMNI SCHOOL BUS", value: "OMNI_SCHOOL_BUS" },
    { name: "OMNI PUBLIC BUS", value: "OMNI_PUBLIC_BUS" },
    { name: "TOWN TAXI", value: "TOWN_TAXI" },
    { name: "OUTSTATION TAXI", value: "OUTSTATION_TAXI" },
    { name: "HIRE CAR - HIRER DRIVEN", value: "HIRE_CAR_HIRER_DRIVEN" },
    { name: "SPECIAL TYPE VEHICLE", value: "SPECIAL_TYPE_VEHICLE" },
    { name: "MOTOR TRADE PLATE", value: "MOTOR_TRADE_PLATE" },
  ];

  // Dropdown Options Cover Type
  const cover_type = [
    { name: "COMPREHENSIVE" },
    { name: "THIRD PARTY - FIRE & THEFT" },
    { name: "THIRD PARTY" },
  ];

  const [quotationInfo, setQuotationInfo] = useState({
    // Clients Detail
    name: "",
    nric: "",
    address: "",
    phoneNumber1: "",
    phoneNumber2: "",
    phoneNumber3: "",
    email: "",
    bizPartnerId: 1,
    principalId: 1,
    status: StatusEnum.DRAFT,

    // Motor Detail
    vehicleNo: "",
    motorCc: "",
    motorYear: "",
    motorSeating: "",
    motorSelectClass: "PRIVATE CAR",

    motorCoverNote: "",
    motorIssuedDate: new Date(),
    nonMotorIssuedDate: new Date(),
    motorPeriodStart: new Date(),
    motorPeriodEnd: new Date(new Date().setDate(new Date().getDate() + 364)),
    motorRemarks: "",
    motorSelectCoverType: "COMPREHENSIVE",

    // Premium
    // Motor Premium
    premiumSumInsured: "12345",
    premiumExcess: "0",
    premiumBasicPremium: 0.0,
    premiumTrailerSchedulePremium: "0.00",
    premiumAllRiderPercentage: "0",
    premiumAllRider: "0.00",
    premiumSubTotal1: "0.00",
    premiumLoadingChargePercentage: "0",
    premiumLoadingCharge: "0.00",
    premiumSubTotal2: "0.00",
    premiumNcdPercentage: "0",
    premiumNcd: "0.00",
    premiumExtraBenefits: "0.00",
    premiumGrossPremium: "0.00",
    premiumServiceTaxPercentage: "8",
    premiumServiceTax: "0.00",
    premiumStampDuty: "10.00",
    premiumTotalMotorPremium: "0.00",
  });

  let navigate = useNavigate();

  const onInputNumberChange = useCallback(
    (e) => {
      const name = e.originalEvent.target.name;
      const value = parseFloat(e.value ?? 0.0);

      const updates = { ...quotationInfo, [name]: value };

      const getPercentageValue = (base, percentage) =>
        base * (percentage / 100);
      const getPercentageFromValue = (base, value) => (value / base) * 100;

      switch (name) {
        case "premiumBasicPremium":
          updates.premiumAllRider = getPercentageValue(
            quotationInfo.premiumBasicPremium,
            quotationInfo.premiumAllRiderPercentage
          );
          break;

        case "premiumAllRider":
          updates.premiumAllRiderPercentage = getPercentageFromValue(
            quotationInfo.premiumBasicPremium,
            value
          );
          break;

        case "premiumAllRiderPercentage":
          updates.premiumAllRider = getPercentageValue(
            quotationInfo.premiumBasicPremium,
            value
          );
          break;

        case "premiumLoadingCharge":
          updates.premiumLoadingChargePercentage = getPercentageFromValue(
            quotationInfo.premiumSubTotal1,
            value
          );
          break;

        case "premiumLoadingChargePercentage":
          updates.premiumLoadingCharge = getPercentageValue(
            quotationInfo.premiumSubTotal1,
            value
          );
          break;

        default:
          break;
      }

      setQuotationInfo(updates);
    },
    [quotationInfo]
  );

  const onInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      // Use function update form state to avoid stale closure issue
      setQuotationInfo((prevQuotationInfo) => ({
        ...prevQuotationInfo,
        [name]: value,
      }));
    },
    [quotationInfo]
  );

  useEffect(() => {
    const {
      premiumBasicPremium,
      premiumTrailerSchedulePremium,
      premiumAllRider,
    } = quotationInfo;

    const premiumSubTotal = (
      parseFloat(premiumBasicPremium) +
      parseFloat(premiumTrailerSchedulePremium) +
      parseFloat(premiumAllRider)
    ).toFixed(2);

    setQuotationInfo((prev) => ({
      ...prev,
      premiumSubTotal1: premiumSubTotal,
    }));
  }, [
    quotationInfo.premiumBasicPremium,
    quotationInfo.premiumTrailerSchedulePremium,
    quotationInfo.premiumAllRider,
  ]);

  useEffect(() => {
    const { premiumSubTotal1, premiumLoadingCharge } = quotationInfo;

    const premiumSubTotal =
      parseFloat(premiumSubTotal1) + parseFloat(premiumLoadingCharge);

    setQuotationInfo((prev) => ({
      ...prev,
      premiumSubTotal2: premiumSubTotal.toFixed(2),
    }));
  }, [quotationInfo.premiumSubTotal1, quotationInfo.premiumLoadingCharge]);

  useEffect(() => {
    const {
      premiumSubTotal2,
      premiumExtraBenefitsTotal,
      premiumNcdPercentage,
    } = quotationInfo;

    const premiumSubTotal = parseFloat(premiumSubTotal2);
    const ncdPercentage = parseFloat(premiumNcdPercentage) / 100 || 0;
    const premiumNcd = premiumSubTotal * ncdPercentage;
    const premiumGross =
      premiumSubTotal + parseFloat(premiumExtraBenefitsTotal || 0) - premiumNcd;

    setQuotationInfo((prev) => ({
      ...prev,
      premiumNcd: premiumNcd.toFixed(2),
      premiumGrossPremium: premiumGross.toFixed(2),
    }));
  }, [
    quotationInfo.premiumSubTotal2,
    quotationInfo.premiumExtraBenefitsTotal,
    quotationInfo.premiumNcdPercentage,
  ]);

  useEffect(() => {
    const parse = (value) => parseFloat(value) || 0;

    const calculate = (base, percentage) =>
      (parse(base) * (parse(percentage) / 100)).toFixed(2);

    const total = (...values) =>
      values.reduce((sum, value) => sum + parse(value), 0).toFixed(2);

    const premiumServiceTax = calculate(
      quotationInfo.premiumGrossPremium,
      quotationInfo.premiumServiceTaxPercentage
    );

    const premiumTotalMotorPremium = total(
      quotationInfo.premiumGrossPremium,
      quotationInfo.premiumStampDuty,
      premiumServiceTax
    );

    setQuotationInfo((prev) => ({
      ...prev,
      premiumServiceTax,
      premiumTotalMotorPremium,
    }));
    // eslint-disable-next-line
  }, [
    quotationInfo.premiumGrossPremium,
    quotationInfo.premiumStampDuty,
    quotationInfo.premiumServiceTaxPercentage,
  ]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (quotationInfo.name === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Name Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (quotationInfo.nric === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>NRIC Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (quotationInfo.vehicleNo === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Vehicle No. Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (quotationInfo.principalId === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Principal Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (quotationInfo.bizPartnerId === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Biz Partner Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (quotationInfo.motorCoverNote === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Motor Cover No Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      quotationInfo.premiumTotalMotorPremium === "" ||
      isNaN(quotationInfo.premiumTotalMotorPremium) === true ||
      quotationInfo.premiumTotalMotorPremium === "0.00"
    ) {
      toast.current.show({
        severity: "error",
        summary: "Empty Total Payable",
        detail: (
          <span>
            <b>Total Premium Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else {
      createQuotation();
    }
  };

  const createQuotation = async (e) => {
    var object = quotationInfo;
    var format_QuotationInfo = {};

    Object.keys(object).forEach(function (key) {
      if (object[key] !== "" && object[key] !== undefined) {
        const value = object[key];
        if (typeof value === "string") {
          format_QuotationInfo[key] = value.trim();
        } else {
          format_QuotationInfo[key] = value;
        }
      }
    });

    format_QuotationInfo["name"] = format_QuotationInfo["name"].toUpperCase();

    const result = await axiosPrivate.post(
      `${process.env.REACT_APP_API_URL}/client_vehicle`,
      JSON.stringify(format_QuotationInfo),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    toast.current.show({
      severity: "info",
      summary: "Client/Vehicle Created",
      detail: (
        <span>
          Client: <b>{format_QuotationInfo["name"]}</b>
          <br></br>
          Vehicle: <b>{format_QuotationInfo["vehicleNo"]}</b>
          <br></br> Quotation Created Successfully
        </span>
      ),
      life: 3000,
    });
    navigate(`/eias/enquires/${result.data}/view`);
  };

  const handleInputChange = (e) => {
    const value = e.target.value; // User input
    const regex = /^\d{2}\/\d{2}\/\d{4}$/; // Validate dd/mm/yyyy format

    if (regex.test(value)) {
      // Parse input manually if required or pass as is
      const [day, month, year] = value.split("/").map(Number);
      const date = new Date(year, month - 1, day);
      onInputChange({ ...e, value: date });
    } else if (e.value instanceof Date) {
      // When PrimeReact passes a Date object
      const formattedDate = format(e.value, "dd/MM/yyyy");
      onInputChange({ ...e, value: formattedDate });
    } else {
      console.error("Invalid date format. Please use dd/mm/yyyy.");
    }
  };

  return (
    <section className="pt-2 pb-5 bg-theme">
      <b>
        <div className="card m-4 bg-theme-light">
          <div className="card-body">
            <div className="card-title">
              <Toast ref={toast} />
              <ConfirmDialog />
              <h2 className="text-start">Quick Quotation</h2>
            </div>
            <div className="card-text text-start">
              <form onSubmit={(e) => onSubmit(e)}>
                <table className="name_Table">
                  <tbody>
                    <tr>
                      <td>
                        Name
                        <FontAwesomeIcon
                          icon="address-book"
                          className="ms-2 icon"
                          onClick={openNameModal}
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control name_upper"
                          name="name"
                          value={quotationInfo.name}
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>

                      <td className="text-right">&nbsp; NRIC No.</td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="nric"
                          value={quotationInfo.nric}
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td rowSpan="2">Address</td>

                      <td rowSpan="2">
                        <textarea
                          className="form-control"
                          name="address"
                          value={quotationInfo.address}
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>

                      <td className="text-right">&nbsp; Phone No. 1</td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="phoneNumber1"
                          value={quotationInfo.phoneNumber1}
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="text-right">&nbsp; Phone No. 2</td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="phoneNumber2"
                          value={quotationInfo.phoneNumber2}
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Email</td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          value={quotationInfo.email}
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>

                      <td className="text-right">&nbsp; Phone No. 3</td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="phoneNumber3"
                          value={quotationInfo.phoneNumber3}
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="card m-4 bg-theme-light">
                  <table className="motor_Table">
                    <tbody>
                      <tr>
                        <td>Cover Note No.</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="motorCoverNote"
                            value={quotationInfo.motorCoverNote}
                            onChange={(e) => onInputChange(e)}
                          />
                        </td>
                        <td>Period From</td>
                        <td>
                          <div className="d-flex">
                            <Calendar
                              id="icon"
                              name="motorPeriodStart"
                              value={new Date(quotationInfo.motorPeriodStart)}
                              onChange={(e) => handleInputChange(e)}
                              showIcon
                              showButtonBar
                              className="width_12_em"
                              inputClassName="padding-left"
                              clearButtonClassName="d-none"
                              dateFormat="dd/mm/yy"
                            />
                            <span className="d-flex align-self-center px-2">
                              to
                            </span>
                            <Calendar
                              id="icon"
                              name="motorPeriodEnd"
                              value={new Date(quotationInfo.motorPeriodEnd)}
                              onChange={(e) => handleInputChange(e)}
                              showIcon
                              showButtonBar
                              className="width_12_em"
                              inputClassName="padding-left"
                              clearButtonClassName="d-none"
                              dateFormat="dd/mm/yy"
                              minDate={addDays(
                                quotationInfo.motorPeriodStart,
                                1
                              )}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Cover Type</td>
                        <td>
                          <Dropdown
                            name="motorSelectCoverType"
                            value={quotationInfo.motorSelectCoverType}
                            options={cover_type}
                            optionLabel="name"
                            optionValue="name"
                            onChange={(e) => onInputChange(e)}
                            placeholder="Select Cover Type"
                            className="form-control"
                          />
                        </td>
                        <td>Class</td>
                        <td>
                          <Dropdown
                            name="motorSelectClass"
                            value={quotationInfo.motorSelectClass}
                            options={vehicle_class}
                            optionLabel="name"
                            optionValue="name"
                            onChange={(e) => onInputChange(e)}
                            placeholder="Select Class"
                            className="form-control width_20_em"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-right">
                          Vehicle No.
                          <FontAwesomeIcon
                            icon="car"
                            className="ms-2 icon"
                            onClick={openVehicleModal}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="vehicleNo"
                            value={quotationInfo.vehicleNo}
                            onChange={(e) => onInputChange(e)}
                          />
                        </td>
                        <td>CC / KG</td>
                        <td>
                          <div className="d-flex">
                            <input
                              type="number"
                              min="0"
                              className="form-control input_percentage"
                              name="motorCc"
                              value={quotationInfo.motorCc}
                              onChange={(e) => onInputChange(e)}
                            />
                            <span className="align-self-center px-2">Year</span>
                            <input
                              type="number"
                              min="0"
                              className="form-control input_percentage"
                              name="motorYear"
                              value={quotationInfo.motorYear}
                              onChange={(e) => onInputChange(e)}
                            />
                            <span className="align-self-center px-2">
                              Seating
                            </span>
                            <input
                              type="number"
                              min="0"
                              className="form-control input_percentage"
                              name="motorSeating"
                              value={quotationInfo.motorSeating ?? ""}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Sum Insured</td>
                        <td>
                          <InputNumber
                            mode="decimal"
                            className="w-100"
                            format
                            name="premiumSumInsured"
                            value={quotationInfo.premiumSumInsured ?? "0"}
                            onChange={(e) => onInputNumberChange(e)}
                            min={0}
                          />
                        </td>
                        <td>Remarks</td>
                        <td>
                          <InputText
                            className="form-control"
                            name="motorRemarks"
                            value={quotationInfo.motorRemarks ?? ""}
                            onChange={(e) => onInputChange(e)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          <p className="div_bottom_line"></p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">Basic Premium</td>
                        <td className="float-end">
                          <InputNumber
                            mode="decimal"
                            className="number-end"
                            format
                            name="premiumBasicPremium"
                            value={quotationInfo.premiumBasicPremium ?? 0.0}
                            onChange={(e) => onInputNumberChange(e)}
                            min={0.0}
                            maxFractionDigits={2}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">+ Trailer Schedule Premium</td>
                        <td className="float-end">
                          <InputNumber
                            mode="decimal"
                            className="number-end"
                            format
                            name="premiumTrailerSchedulePremium"
                            value={
                              quotationInfo.premiumTrailerSchedulePremium ?? 0.0
                            }
                            onChange={(e) => onInputNumberChange(e)}
                            min={0.0}
                            maxFractionDigits={2}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">+ All Rider</td>
                        <td className="float-end">
                          <div className="d-flex float-end">
                            <InputNumber
                              mode="decimal"
                              className="w-8 number-end"
                              format
                              name="premiumAllRiderPercentage"
                              value={
                                quotationInfo.premiumAllRiderPercentage ?? 0.0
                              }
                              onChange={(e) => onInputNumberChange(e)}
                              min={0.0}
                              max={100}
                              maxFractionDigits={2}
                              suffix=" %"
                            />
                            <span className="ps-3"></span>
                            <InputNumber
                              mode="decimal"
                              className="number-end"
                              format
                              name="premiumAllRider"
                              value={quotationInfo.premiumAllRider ?? 0.0}
                              onChange={(e) => onInputNumberChange(e)}
                              min={0.0}
                              maxFractionDigits={2}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          <p className="div_bottom_line"></p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">Sub Total 1</td>
                        <td className="float-end">
                          <InputNumber
                            mode="decimal"
                            className="background_green number-end"
                            format
                            name="premiumSubTotal1"
                            value={quotationInfo.premiumSubTotal1 ?? 0.0}
                            onChange={(e) => onInputNumberChange(e)}
                            min={0.0}
                            maxFractionDigits={2}
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          <p className="div_bottom_line"></p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">+ Loading Charge</td>
                        <td className="float-end">
                          <div className="d-flex float-end">
                            <InputNumber
                              mode="decimal"
                              className="w-8 number-end"
                              format
                              name="premiumLoadingChargePercentage"
                              value={
                                quotationInfo.premiumLoadingChargePercentage ??
                                0.0
                              }
                              onChange={(e) => onInputNumberChange(e)}
                              max={100}
                              min={0.0}
                              maxFractionDigits={2}
                              suffix=" %"
                            />
                            <span className="ps-3"></span>
                            <InputNumber
                              mode="decimal"
                              className="number-end"
                              format
                              name="premiumLoadingCharge"
                              value={quotationInfo.premiumLoadingCharge ?? 0.0}
                              onChange={(e) => onInputNumberChange(e)}
                              min={0.0}
                              maxFractionDigits={2}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">- NCD</td>
                        <td className="float-end">
                          <div className="d-flex float-end">
                            <InputNumber
                              mode="decimal"
                              className="w-8 number-end"
                              format
                              name="premiumNcdPercentage"
                              value={quotationInfo.premiumNcdPercentage ?? 0.0}
                              onChange={(e) => onInputNumberChange(e)}
                              max={100}
                              min={0.0}
                              maxFractionDigits={2}
                              suffix=" %"
                            />
                            <span className="ps-3"></span>
                            <InputNumber
                              mode="decimal"
                              className="background_green number-end"
                              format
                              name="premiumNcd"
                              value={quotationInfo.premiumNcd ?? 0.0}
                              onChange={(e) => onInputNumberChange(e)}
                              min={0.0}
                              maxFractionDigits={2}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">+ Extra Benefits</td>
                        <td className="float-end">
                          <div className="d-flex float-end">
                            <FontAwesomeIcon
                              icon="database"
                              className="icon pt-3"
                              onClick={openBenefitsModal}
                            />
                            <span className="ps-2"></span>
                            <InputNumber
                              mode="decimal"
                              className="number-end"
                              format
                              name="premiumExtraBenefitsTotal"
                              value={
                                quotationInfo.premiumExtraBenefitsTotal ?? 0.0
                              }
                              onChange={(e) => onInputNumberChange(e)}
                              min={0.0}
                              maxFractionDigits={2}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">+ Service Tax</td>
                        <td className="float-end">
                          <div className="d-flex float-end">
                            <InputNumber
                              mode="decimal"
                              className="w-8 number-end"
                              format
                              name="premiumServiceTaxPercentage"
                              value={
                                quotationInfo.premiumServiceTaxPercentage ?? 0.0
                              }
                              onChange={(e) => onInputNumberChange(e)}
                              max={100}
                              min={0.0}
                              maxFractionDigits={2}
                              suffix=" %"
                            />
                            <span className="ps-3"></span>
                            <InputNumber
                              mode="decimal"
                              className="background_green number-end"
                              format
                              name="premiumServiceTax"
                              value={quotationInfo.premiumServiceTax ?? 0.0}
                              onChange={(e) => onInputNumberChange(e)}
                              min={0.0}
                              maxFractionDigits={2}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">+ Stamp Duty</td>
                        <td className="float-end">
                          <InputNumber
                            mode="decimal"
                            className="number-end"
                            format
                            name="premiumStampDuty"
                            value={quotationInfo.premiumStampDuty ?? 0.0}
                            onChange={(e) => onInputNumberChange(e)}
                            min={0.0}
                            maxFractionDigits={2}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          <p className="div_bottom_line"></p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">Total Motor Premium</td>
                        <td className="float-end">
                          <InputNumber
                            mode="decimal"
                            className="background_green number-end"
                            format
                            name="premiumTotalMotorPremium"
                            value={
                              quotationInfo.premiumTotalMotorPremium ?? 0.0
                            }
                            onChange={(e) => onInputNumberChange(e)}
                            min={0.0}
                            maxFractionDigits={2}
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}></td>
                        <td className="float-end">
                          <Button
                            icon="pi pi-save"
                            type="submit"
                            label="Save Quotation"
                          ></Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </div>
      </b>
    </section>
  );
}
