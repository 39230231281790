import axios from "axios";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./css/print.css";

export default function PrintCaseDetail() {
  const [clientVehicleInfo, setClientVehicleInfo] = useState([]);

  const {
    // Client Details
    name,
    address,
    nric,
    phoneNumber1,
    phoneNumber2,
    phoneNumber3,
    email,
    principalId,
    bizPartnerId,
    vehicleNo,

    // Motor Details
    motorCoverNote,
    motorIssuedDate,
    motorSelectAction,
    motorPeriodStart,
    motorPeriodEnd,
    motorSelectCoverType,
    motorPartnerCommissionRate,
    motorSelectClass,
    motorCc,
    motorYear,
    motorSeating,
    motorPrincipalTariff,
    motorMakeModel,
    motorTypeOfBody,
    motorHPCo,

    // Non motor Details
    nonMotorCoverNote,
    nonMotorIssuedDate,
    nonMotorPeriodStart,
    nonMotorPeriodEnd,
    nonMotorPrincipalTariff,
    nonMotorClassOfIns,
    nonMotorPartnerCommission,
    nonMotorDescription,
    nonMotorSumInsuredRM,
    nonMotorBasicPremium,
    nonMotorGovtTax,
    nonMotorTotal,
    nonMotorStampDuty,
    nonMotorOtherCharges,
    nonMotorTotalPremium,

    //Motor Premium
    premiumSumInsured,
    premiumExcess,
    premiumBasicPremium,
    premiumTrailerSchedulePremium,
    premiumAllRiderPercentage,
    premiumAllRider,
    premiumLoadingChargePercentage,
    premiumLoadingCharge,
    premiumNcdPercentage,
    premiumNcd,
    premiumServiceTaxPercentage,
    premiumServiceTax,
    premiumStampDuty,
    premiumTransferFees,
    premiumTotalMotorPremium,

    //Sup Svc
    supSvcIssuedDate,
    supSvcRoadTaxCharges,
    supSvcRoadTaxJpjRunner,
    supSvcRoadTaxRevenue,
    supSvcLicenseYears,
    supSvcLicenseCharges,
    supSvcLicenseJpjRunner,
    supSvcLicenseRevenue,
    supSvcJpjRenewalCharges,
    supSvcJpjRenewalJpjRunner,
    supSvcJpjRenewalRevenue,
    supSvcOthers1,
    supSvcOthers1Charges,
    supSvcOthers1JpjRunner,
    supSvcOthers1Revenue,
    supSvcOthers2,
    supSvcOthers2Charges,
    supSvcOthers2JpjRunner,
    supSvcOthers2Revenue,
    supSvcOthers3,
    supSvcOthers3Charges,
    supSvcOthers3JpjRunner,
    supSvcOthers3Revenue,
    supSvcTotalCharges,
    supSvcTotalJpjRunner,
    supSvcTotalRevenue,

    //Benefits
    premiumExtraBenefitsWindscreenCoverValue,
    premiumExtraBenefitsWindscreenCoverTotal,
    premiumExtraBenefitsLegalLiabilityToPassengersTotal,
    premiumExtraBenefitsActOfNegligenceTotal,
    premiumExtraBenefitsStrikeCommotionCoverTotal,
    premiumExtraBenefitsAllDriversTotal,
    premiumExtraBenefitsAllDriversInvTotal,
    premiumExtraBenefitsConvulsionOfNatureTotal,
    premiumExtraBenefitsAdditionalDriverTotal,
    premiumExtraBenefitsRadioCasetteTotal,
    premiumExtraBenefitsInsuranceProtectionTotal,
    premiumExtraBenefitsInclusionOfDamageTotal,
    premiumExtraBenefitsInclusionOfAccidentalTotal,
    premiumExtraBenefitsExtensionOfCoverTotal,
    premiumExtraBenefitsGeneraliMCPATotal,
    premiumExtraBenefitsUnlimitedTowingTotal,
    premiumExtraBenefitsOther1Value,
    premiumExtraBenefitsOther1Total,
    premiumExtraBenefitsOther2Value,
    premiumExtraBenefitsOther2Total,
    premiumExtraBenefitsOther3Value,
    premiumExtraBenefitsOther3Total,

    //Billing
    billingMotorPremium,
    billingDiscount,
    billingNonMotorPremium,
    billingDiscountNonMotor,
    billingSupportingServices,
    billingSubtotal,
    // billingSubtotalDiscount,
    billingTotalPayable,
    status,
    debit_notes,
    credit_notes,
    orpayment,
  } = clientVehicleInfo;

  // Load Data to Datatable
  useEffect(() => {
    loadClientVehicle();
    // eslint-disable-next-line
  }, []);

  const { id } = useParams();
  const loadClientVehicle = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/enquires/get_id_enquires/${id}`
    );
    setClientVehicleInfo(result.data);
  };

  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }

  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="main_table">
      <div className="container">
        {/* Case Details */}
        <div className="row">
          <div className="col text-center">
            <b className="custom_font_20px">
              CASE DETAILS (STATUS: {status ? status.toUpperCase() : ""})
            </b>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="padding-0 custom_font_14px">
              <tbody>
                <tr>
                  <td className="w-15">Name</td>
                  <td>
                    <div className="d-flex">
                      :&nbsp;
                      <span>{name}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-top">Address</td>
                  <td>
                    <div className="d-flex">
                      :&nbsp;
                      <span>{address}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>NRIC No.</td>
                  <td>: {nric}</td>
                </tr>
                {phoneNumber1 !== null && (
                  <tr>
                    <td>Phone No.</td>
                    <td>
                      : {phoneNumber1} - {phoneNumber2} - {phoneNumber3}
                    </td>
                  </tr>
                )}
                {email !== null && (
                  <tr>
                    <td>Email</td>
                    <td>: {email}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="break_line"></div>
        </div>
        {/* Detail */}
        <div className="row mt-2">
          <div className="col text-center">
            <b className="custom_font_20px">DETAILS</b>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="padding-0 custom_font_14px">
              <tbody>
                <tr>
                  <td className="w_15">
                    <b>Principal</b>
                  </td>
                  <td>
                    :&nbsp;
                    {principalId
                      ? principalId.toUpperCase()
                      : ""}
                  </td>
                  <td className="w_15">
                    <b>Partner</b>
                  </td>
                  <td>
                    :{" "}
                    {bizPartnerId ? bizPartnerId.toUpperCase() : ""}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Vehicle No</b>
                  </td>
                  <td>: {vehicleNo}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="break_line"></div>
        </div>
        {/* Motor Schedule */}
        {motorCoverNote !== null && (
          <span>
            <div className="row mt-2">
              <div className="col text-center">
                <b className="custom_font_20px">MOTOR SCHEDULE</b>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <table className="padding-0 custom_font_14px">
                  <tbody>
                    <tr>
                      <td className="w_15">
                        <b>Cover Note No</b>
                      </td>
                      <td className="w_30">: {motorCoverNote}</td>
                      <td className="w_20">
                        <b>Issue Date</b>
                      </td>
                      <td>
                        :&nbsp;
                        {motorIssuedDate
                          ? format(parseISO(motorIssuedDate), "dd/MM/yyyy")
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Action</b>
                      </td>
                      <td>: {motorSelectAction}</td>
                      <td>
                        <b>Period</b>
                      </td>
                      <td>
                        :&nbsp;
                        {motorPeriodStart
                          ? format(parseISO(motorPeriodStart), "dd/MM/yyyy")
                          : ""}
                        &nbsp;to&nbsp;
                        {motorPeriodEnd
                          ? format(parseISO(motorPeriodEnd), "dd/MM/yyyy")
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>CC/KG</b>
                      </td>
                      <td>: {motorCc}</td>
                      <td>
                        <b>Year</b>
                      </td>
                      <td>: {motorYear}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Seating</b>
                      </td>
                      <td>: {motorSeating}</td>
                      <td>
                        <b>HP Co.</b>
                      </td>
                      <td>: {motorHPCo}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Sum Insured</b>
                      </td>
                      <td>
                        :&nbsp;
                        {premiumSumInsured
                          ? numberWithCommas(premiumSumInsured)
                          : 0}
                      </td>
                      <td>
                        <b>Cover Type</b>
                      </td>
                      <td>: {motorSelectCoverType}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Excess</b>
                      </td>
                      <td>: {premiumExcess}</td>
                      <td>
                        <b>Type of Body</b>
                      </td>
                      <td>: {motorTypeOfBody}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Principal Tariff</b>
                      </td>
                      <td>: {motorPrincipalTariff}%</td>
                      <td>
                        <b>Class Name of Vehicle</b>
                      </td>
                      <td>
                        <div className="d-flex">
                          :&nbsp;
                          <span>{motorSelectClass}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b>Partner Commission</b>
                      </td>
                      <td className="align-top">
                        : {motorPartnerCommissionRate}%
                      </td>
                      <td className="align-top">
                        <b>Make/Model</b>
                      </td>
                      <td>
                        <div className="d-flex">
                          :&nbsp;
                          <span>{motorMakeModel}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <table className="padding-0">
                  <tbody>
                    <tr>
                      <td className="w_80">
                        <b>Premium Pricing</b>
                      </td>
                      <td className="text-end">
                        <b>RM</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Basic Premium</td>
                      <td className="text-end">
                        {premiumBasicPremium
                          ? numberWithCommas(premiumBasicPremium)
                          : 0}
                      </td>
                    </tr>
                    {premiumTrailerSchedulePremium > 0 && (
                      <tr>
                        <td>+ Trailer Schedule Premium</td>
                        <td className="text-end">
                          {premiumTrailerSchedulePremium
                            ? numberWithCommas(premiumTrailerSchedulePremium)
                            : 0}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        + All Rider Premium ({premiumAllRiderPercentage}%)
                      </td>
                      <td className="text-end">
                        {premiumAllRider
                          ? numberWithCommas(premiumAllRider)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Loading ({premiumLoadingChargePercentage}%)</td>
                      <td className="text-end">
                        {premiumLoadingCharge
                          ? numberWithCommas(premiumLoadingCharge)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>- NCD ({premiumNcdPercentage}%)</td>
                      <td className="text-end">
                        {premiumNcd ? numberWithCommas(premiumNcd) : 0}
                      </td>
                    </tr>

                    {premiumExtraBenefitsWindscreenCoverTotal > 0 && (
                      <tr>
                        <td>
                          Windscreen Cover (RM
                          {premiumExtraBenefitsWindscreenCoverValue
                            ? numberWithCommas(
                                premiumExtraBenefitsWindscreenCoverValue
                              )
                            : 0}
                          )
                        </td>
                        <td className="text-end">
                          {premiumExtraBenefitsWindscreenCoverTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsWindscreenCoverTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsLegalLiabilityToPassengersTotal >
                      0 && (
                      <tr>
                        <td>Legal Liability to Passengers</td>
                        <td className="text-end">
                          {premiumExtraBenefitsLegalLiabilityToPassengersTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsLegalLiabilityToPassengersTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsActOfNegligenceTotal > 0 && (
                      <tr>
                        <td>
                          Legal Liability of Passengers for Acts of Negligence
                        </td>
                        <td className="text-end">
                          {premiumExtraBenefitsActOfNegligenceTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsActOfNegligenceTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsStrikeCommotionCoverTotal >
                      0 && (
                      <tr>
                        <td>Strike, Riot & Civil Commotion Cover</td>
                        <td className="text-end">
                          {premiumExtraBenefitsStrikeCommotionCoverTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsStrikeCommotionCoverTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsAllDriversTotal > 0 && (
                      <tr>
                        <td>All Drivers (Organization Policy Only)</td>
                        <td className="text-end">
                          {premiumExtraBenefitsAllDriversTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsAllDriversTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsAllDriversInvTotal > 0 && (
                      <tr>
                        <td>All Drivers (Individual Policy Only)</td>
                        <td className="text-end">
                          {premiumExtraBenefitsAllDriversInvTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsAllDriversInvTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsConvulsionOfNatureTotal > 0 && (
                      <tr>
                        <td>Convulsion of Nature</td>
                        <td className="text-end">
                          {premiumExtraBenefitsConvulsionOfNatureTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsConvulsionOfNatureTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsAdditionalDriverTotal > 0 && (
                      <tr>
                        <td>Additional Driver</td>
                        <td className="text-end">
                          {premiumExtraBenefitsAdditionalDriverTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsAdditionalDriverTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsRadioCasetteTotal > 0 && (
                      <tr>
                        <td>Radio Casette/Tape Recorder/Air Conditioner</td>
                        <td className="text-end">
                          {premiumExtraBenefitsRadioCasetteTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsRadioCasetteTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsInsuranceProtectionTotal > 0 && (
                      <tr>
                        <td>Insurance Protection for Learner Drivers</td>
                        <td className="text-end">
                          {premiumExtraBenefitsInsuranceProtectionTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsInsuranceProtectionTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsInclusionOfDamageTotal > 0 && (
                      <tr>
                        <td>Inclusion of Damage by Overturning</td>
                        <td className="text-end">
                          {premiumExtraBenefitsInclusionOfDamageTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsInclusionOfDamageTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsInclusionOfAccidentalTotal >
                      0 && (
                      <tr>
                        <td>Inclusion of Accidental Damage to the Boom</td>
                        <td className="text-end">
                          {premiumExtraBenefitsInclusionOfAccidentalTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsInclusionOfAccidentalTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsExtensionOfCoverTotal > 0 && (
                      <tr>
                        <td>Extension of Cover to the Kingdom of Thailand</td>
                        <td className="text-end">
                          {premiumExtraBenefitsExtensionOfCoverTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsExtensionOfCoverTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsGeneraliMCPATotal > 0 && (
                      <tr>
                        <td>Extension of Cover to the Kingdom of Thailand</td>
                        <td className="text-end">
                          {premiumExtraBenefitsGeneraliMCPATotal
                            ? numberWithCommas(
                                premiumExtraBenefitsGeneraliMCPATotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsUnlimitedTowingTotal > 0 && (
                      <tr>
                        <td>Extension of Cover to the Kingdom of Thailand</td>
                        <td className="text-end">
                          {premiumExtraBenefitsUnlimitedTowingTotal
                            ? numberWithCommas(
                                premiumExtraBenefitsUnlimitedTowingTotal
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsOther1Total > 0 && (
                      <tr>
                        <td>Other ({premiumExtraBenefitsOther1Value})</td>
                        <td className="text-end">
                          {premiumExtraBenefitsOther1Total
                            ? numberWithCommas(
                                premiumExtraBenefitsOther1Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsOther2Total > 0 && (
                      <tr>
                        <td>Other ({premiumExtraBenefitsOther2Value})</td>
                        <td className="text-end">
                          {premiumExtraBenefitsOther2Total
                            ? numberWithCommas(
                                premiumExtraBenefitsOther2Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premiumExtraBenefitsOther3Total > 0 && (
                      <tr>
                        <td>Other ({premiumExtraBenefitsOther3Value})</td>
                        <td className="text-end">
                          {premiumExtraBenefitsOther3Total
                            ? numberWithCommas(
                                premiumExtraBenefitsOther3Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        Authorised Driver(s)
                        <br />
                        POLICYHOLDER
                      </td>
                      <td className="text-end">
                        {premiumExtraBenefitsAdditionalDriverTotal
                          ? numberWithCommas(
                              premiumExtraBenefitsAdditionalDriverTotal
                            )
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Service Tax ({premiumServiceTaxPercentage}%)</td>
                      <td className="text-end">
                        {premiumServiceTax
                          ? numberWithCommas(premiumServiceTax)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Stamp Duty</td>
                      <td className="text-end">
                        {premiumStampDuty
                          ? numberWithCommas(premiumStampDuty)
                          : 0}
                      </td>
                    </tr>
                    {premiumTransferFees > 0 && (
                      <tr>
                        <td>+ Transfer Fees</td>
                        <td className="text-end">
                          {premiumTransferFees
                            ? numberWithCommas(premiumTransferFees)
                            : 0}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td className="text-end">
                        <div className="equal_line"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Total Motor Premium</b>
                      </td>
                      <td className="text-end">
                        <b>
                          {premiumTotalMotorPremium
                            ? numberWithCommas(premiumTotalMotorPremium)
                            : 0}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="break_line"></div>
            </div>
          </span>
        )}
        {/* Non Motor Schedule */}
        {nonMotorCoverNote !== null && (
          <span>
            <div className="row mt-2">
              <div className="col text-center">
                <b className="custom_font_20px">NON MOTOR SCHEDULE</b>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <table className="padding-0 custom_font_14px">
                  <tbody>
                    <tr>
                      <td className="w_20">
                        <b>Cover Note No</b>
                      </td>
                      <td className="w_20">: {nonMotorCoverNote}</td>
                      <td className="w_20">
                        <b>Issue Date</b>
                      </td>
                      <td>
                        :&nbsp;
                        {nonMotorIssuedDate
                          ? format(
                              parseISO(nonMotorIssuedDate),
                              "dd/MM/yyyy"
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Sum Insured</b>
                      </td>
                      <td>
                        :&nbsp;
                        {nonMotorSumInsuredRM
                          ? numberWithCommas(nonMotorSumInsuredRM)
                          : 0}
                      </td>
                      <td>
                        <b>Period</b>
                      </td>
                      <td>
                        :&nbsp;
                        {nonMotorPeriodStart
                          ? format(
                              parseISO(nonMotorPeriodStart),
                              "dd/MM/yyyy"
                            )
                          : ""}
                        &nbsp;to&nbsp;
                        {nonMotorPeriodEnd
                          ? format(
                              parseISO(nonMotorPeriodEnd),
                              "dd/MM/yyyy"
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b>Principal Tariff</b>
                      </td>
                      <td className="align-top">
                        : {nonMotorPrincipalTariff}%
                      </td>
                      <td className="align-top">
                        <b>className of Insurance</b>
                      </td>
                      <td>
                        <div className="d-flex">
                          :&nbsp;
                          <span>{nonMotorClassOfIns}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b>Partner Commission</b>
                      </td>
                      <td className="align-top">
                        : {nonMotorPartnerCommission}%
                      </td>
                      <td className="align-top">
                        <b>Description</b>
                      </td>
                      <td>
                        <div className="d-flex">
                          :&nbsp;
                          <span>{nonMotorDescription}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <table className="padding-0">
                  <tbody>
                    <tr>
                      <td className="w_80">
                        <b>Premium Pricing</b>
                      </td>
                      <td className="text-end">
                        <b>RM</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Basic Premium</td>
                      <td className="text-end">
                        {nonMotorBasicPremium
                          ? numberWithCommas(nonMotorBasicPremium)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Stamp Duty</td>
                      <td className="text-end">
                        {nonMotorStampDuty
                          ? numberWithCommas(nonMotorStampDuty)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Service Tax ({nonMotorGovtTax}%)</td>
                      <td className="text-end">
                        {nonMotorTotal
                          ? numberWithCommas(nonMotorTotal)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Other Charges</td>
                      <td className="text-end">
                        {nonMotorOtherCharges
                          ? numberWithCommas(nonMotorOtherCharges)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="text-end">
                        <div className="equal_line"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Total Non Motor Premium</b>
                      </td>
                      <td className="text-end">
                        <b>
                          {nonMotorTotalPremium
                            ? numberWithCommas(nonMotorTotalPremium)
                            : 0}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="break_line"></div>
            </div>
          </span>
        )}
        {/* Sup Svc Schedule */}
        {supSvcTotalCharges > 0 && (
          <span>
            <div className="row mt-2">
              <div className="col text-center">
                <b className="custom_font_20px">SUPPORTING SERVICES SCHEDULE</b>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <table className="padding-0 custom_font_14px">
                  <tbody>
                    <tr>
                      <td className="w-25">
                        <b>SS NO</b>
                      </td>
                      <td className="w_20">: SSN00001</td>
                      <td className="w_15">
                        <b>Issue Date</b>
                      </td>
                      <td>
                        :&nbsp;
                        {supSvcIssuedDate
                          ? format(parseISO(supSvcIssuedDate), "dd/MM/yyyy")
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Road Tax Expiry Date</b>
                      </td>
                      <td>
                        :&nbsp;
                        {motorPeriodEnd
                          ? format(parseISO(motorPeriodEnd), "dd/MM/yyyy")
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <table className="padding-0 custom_font_14px">
                  <tbody>
                    <tr>
                      <td></td>
                      <td className="text-end">
                        <b>Charges (RM)</b>
                      </td>
                      <td className="text-end">
                        <b>JPJ/Runner (RM)</b>
                      </td>
                      <td className="text-end">
                        <b>Revenue (RM)</b>
                      </td>
                    </tr>
                    {supSvcRoadTaxCharges > 0 && (
                      <tr>
                        <td>Road Tax for (12) Month(s)</td>
                        <td className="text-end">
                          {supSvcRoadTaxCharges
                            ? numberWithCommas(supSvcRoadTaxCharges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcRoadTaxJpjRunner
                            ? numberWithCommas(supSvcRoadTaxJpjRunner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcRoadTaxRevenue
                            ? numberWithCommas(supSvcRoadTaxRevenue)
                            : 0}
                        </td>
                      </tr>
                    )}

                    {supSvcLicenseCharges > 0 && (
                      <tr>
                        <td>License for {supSvcLicenseYears} Year(s)</td>
                        <td className="text-end">
                          {supSvcLicenseCharges
                            ? numberWithCommas(supSvcLicenseCharges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcLicenseJpjRunner
                            ? numberWithCommas(supSvcLicenseJpjRunner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcLicenseRevenue
                            ? numberWithCommas(supSvcLicenseRevenue)
                            : 0}
                        </td>
                      </tr>
                    )}

                    {supSvcJpjRenewalCharges > 0 && (
                      <tr>
                        <td>JPJ Renewal Charges</td>
                        <td className="text-end">
                          {supSvcJpjRenewalCharges
                            ? numberWithCommas(supSvcJpjRenewalCharges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcJpjRenewalJpjRunner
                            ? numberWithCommas(supSvcJpjRenewalJpjRunner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcJpjRenewalRevenue
                            ? numberWithCommas(supSvcJpjRenewalRevenue)
                            : 0}
                        </td>
                      </tr>
                    )}

                    {supSvcOthers1Charges > 0 && (
                      <tr>
                        <td>Others ({supSvcOthers1})</td>
                        <td className="text-end">
                          {supSvcOthers1Charges
                            ? numberWithCommas(supSvcOthers1Charges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcOthers1JpjRunner
                            ? numberWithCommas(supSvcOthers1JpjRunner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcOthers1Revenue
                            ? numberWithCommas(supSvcOthers1Revenue)
                            : 0}
                        </td>
                      </tr>
                    )}

                    {supSvcOthers2Charges > 0 && (
                      <tr>
                        <td>Others ({supSvcOthers2})</td>
                        <td className="text-end">
                          {supSvcOthers2Charges
                            ? numberWithCommas(supSvcOthers2Charges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcOthers2JpjRunner
                            ? numberWithCommas(supSvcOthers2JpjRunner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcOthers2Revenue
                            ? numberWithCommas(supSvcOthers2Revenue)
                            : 0}
                        </td>
                      </tr>
                    )}

                    {supSvcOthers3Charges > 0 && (
                      <tr>
                        <td>Others ({supSvcOthers3})</td>
                        <td className="text-end">
                          {supSvcOthers3Charges
                            ? numberWithCommas(supSvcOthers3Charges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcOthers3JpjRunner
                            ? numberWithCommas(supSvcOthers3JpjRunner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {supSvcOthers3Revenue
                            ? numberWithCommas(supSvcOthers3Revenue)
                            : 0}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td colSpan={3}>
                        <div className="equal_line_sup_svc"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Supporting Services Premium</b>
                      </td>
                      <td className="text-end">
                        <b>
                          {supSvcTotalCharges
                            ? numberWithCommas(supSvcTotalCharges)
                            : 0}
                        </b>
                      </td>
                      <td className="text-end">
                        <b>
                          {supSvcTotalJpjRunner
                            ? numberWithCommas(supSvcTotalJpjRunner)
                            : 0}
                        </b>
                      </td>
                      <td className="text-end">
                        <b>
                          {supSvcTotalRevenue
                            ? numberWithCommas(supSvcTotalRevenue)
                            : 0}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="break_line"></div>
            </div>
          </span>
        )}
        {/* Billing */}
        <div className="row mt-2">
          <div className="col text-center">
            <b className="custom_font_20px">BILLING</b>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <table className="padding-0 custom_font_14px">
              <tbody>
                <tr>
                  <td></td>
                  <td className="text-end">
                    <b>Total (RM)</b>
                  </td>
                  <td className="text-end">
                    <b>Discount (RM)</b>
                  </td>
                </tr>
                <tr>
                  <td>+ Motor Premium</td>
                  <td className="text-end">
                    {billingMotorPremium
                      ? numberWithCommas(billingMotorPremium)
                      : 0}
                  </td>
                  <td className="text-end">
                    ({billingDiscount ? numberWithCommas(billingDiscount) : 0}
                    )
                  </td>
                </tr>
                <tr>
                  <td>+ Non Motor Premium</td>
                  <td className="text-end">
                    {billingNonMotorPremium
                      ? numberWithCommas(billingNonMotorPremium)
                      : 0}
                  </td>
                  <td className="text-end">
                    (
                    {billingDiscountNonMotor
                      ? numberWithCommas(billingDiscountNonMotor)
                      : 0}
                    )
                  </td>
                </tr>
                <tr>
                  <td>+ Supporting Services Premium</td>
                  <td className="text-end">
                    {billingSupportingServices
                      ? numberWithCommas(billingSupportingServices)
                      : 0}
                  </td>
                  <td className="text-end"></td>
                </tr>
                <tr>
                  <td>
                    <b>Subtotal</b>
                  </td>
                  <td className="text-end">
                    <b>
                      {billingSubtotal
                        ? numberWithCommas(billingSubtotal)
                        : 0}
                    </b>
                  </td>
                  <td className="text-end"></td>
                </tr>
                <tr>
                  <td></td>
                  <td colSpan={2}>
                    <div className="equal_line_sup_svc"></div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Total</b>
                  </td>
                  <td className="text-end">
                    <b>
                      {billingTotalPayable
                        ? numberWithCommas(billingTotalPayable)
                        : 0}
                    </b>
                  </td>
                  <td className="text-end"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="break_line"></div>
        </div>
        {/* Tracking Information */}
        <div className="row mt-2">
          <div className="col text-center">
            <b className="custom_font_20px">TRACKING INFORMATION</b>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <table className="padding-0 custom_font_14px">
              <tbody>
                <tr>
                  <td className="w_30">
                    <b>Debit Note No</b>
                  </td>
                  <td className="w-25">
                    DN {debit_notes ? addLeadingZeros(debit_notes[0].dn_id) : 0}
                  </td>
                  <td>
                    RM&nbsp;
                    {billingTotalPayable
                      ? numberWithCommas(billingTotalPayable)
                      : 0}
                  </td>
                </tr>
                {credit_notes?.length > 0 && (
                  <tr>
                    <td>
                      <b>Credit Note No</b>
                    </td>
                    <td>
                      CN&nbsp;
                      {credit_notes !== []
                        ? addLeadingZeros(credit_notes[0].cn_id)
                        : 0}
                    </td>
                    <td>
                      RM&nbsp;
                      {billingDiscount
                        ? numberWithCommas(billingDiscount)
                        : 0}
                    </td>
                  </tr>
                )}
                {orpayment?.length > 0 && (
                  <tr>
                    <td>Customer Receipt No:</td>
                    <td>
                      ORC
                      {orpayment ? addLeadingZeros(orpayment[0].orid) : 0}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}
