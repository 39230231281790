import axios from "axios";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../Enquires/NewClientVehicle/css/print.css";

export default function PrintSubmissionReceipts() {
  const [companyProfile, setCompanyProfile] = useState([]);
  const [clientVehicle, setClientVehicle] = useState([]);
  const [principal, setPrincipal] = useState([]);
  const [submission, setSubmission] = useState([]);

  const {
    type,
    cash,
    cash_ref,
    cheque,
    cheque_ref,
    credit_card,
    credit_card_ref,
    epayment,
    epayment_ref,
    other_ref,
    other,
    sid,
    total_pay,
    created,
  } = submission;

  // Load Data to Datatable
  useEffect(() => {
    loadCompanyProfile();
    loadClientVehicle();
    document.title = "Print Princial Submission";
    // eslint-disable-next-line
  }, []);

  const loadCompanyProfile = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/company_profile`
    );
    setCompanyProfile(result.data);
  };

  const { id } = useParams();
  const loadClientVehicle = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/enquires/get_s_report/${id}`
    );
    const result_submission = await axios.get(
      `${process.env.REACT_APP_API_URL}/reports/get_id_submission_receipts/${id}`
    );

    setClientVehicle(result.data);
    setSubmission(result_submission.data);

    let get_principal = result_submission.data.principalId;
    const result_bp = await axios.get(
      `${process.env.REACT_APP_API_URL}/principal/get_code_principal/${get_principal}`
    );
    setPrincipal(result_bp.data[0]);
  };

  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }

  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="main_table">
      <title>ABC</title>
      <div className="container p-0">
        <div className="row">
          <div className="col">
            <b className="toUpperCase custom_font_25px margin_right_10px">
              {companyProfile.name}
            </b>
            ({companyProfile.business_registration_no})
          </div>
        </div>
        <div className="row">
          <div className="col">{companyProfile.address}</div>
        </div>
        <div className="row">
          <div className="col">
            {companyProfile.postcode} {companyProfile.city}
          </div>
        </div>
        <div className="row">
          <div className="col">{companyProfile.state}</div>
        </div>
        <div className="row row-cols-auto">
          <div className="col">
            <table className="padding-0">
              <tbody>
                <tr>
                  <td>Office No</td>
                  <td className="pe-4">: {companyProfile.phoneNumber1}</td>
                  <td>Tel No 1</td>
                  <td>: {companyProfile.phoneNumber2}</td>
                </tr>
                <tr>
                  <td>E-mail To</td>
                  <td className="pe-4">: {companyProfile.email}</td>
                  <td>Tel No 2</td>
                  <td>: {companyProfile.phoneNumber3}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="break_line"></div>
        </div>
        <div className="row">
          <div className="col">
            <table className="padding-0">
              <tbody>
                <tr>
                  <td className="w_30">
                    <b>Submitted to</b>
                  </td>
                  <td>
                    <div className="d-flex">:&nbsp;{principal.name}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Being payment of</b>
                  </td>
                  <td>
                    <div className="d-flex">:&nbsp;{type}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-4 text-end">
            <table className="padding-0">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <b className="custom_font_20px">SUBMISSION SHEET</b>
                  </td>
                </tr>
                <tr>
                  <td className="text-start">
                    <b>No:</b>
                  </td>
                  <td>S {sid ? addLeadingZeros(sid) : 0}</td>
                </tr>
                <tr>
                  <td className="text-start">
                    <b>Date:</b>
                  </td>
                  <td>
                    {created ? format(parseISO(created), "dd/MM/yyyy") : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <table className="table-bordered border border-2 border-dark text-center padding-0">
              <thead>
                <tr>
                  <th className="p-1 custom_font_14px">
                    <b>Item</b>
                  </th>
                  <th className="p-1 custom_font_14px">
                    <b>Cover Note No.</b>
                  </th>
                  <th className="p-1 custom_font_14px">
                    <b>Vehicle No.</b>
                  </th>
                  <th className="p-1 custom_font_14px">
                    <b>Issued Date</b>
                  </th>
                  <th className="p-1 custom_font_14px">
                    <b>Gross Premium</b>
                  </th>
                  {type === "Motor" && (
                    <th className="p-1 custom_font_14px">
                      <b>NCD %</b>
                    </th>
                  )}
                  <th className="p-1 custom_font_14px">
                    <b>Commission</b>
                  </th>
                  <th className="p-1 custom_font_14px">
                    <b>Stamp Duty</b>
                  </th>
                  {type === "Motor" && (
                    <th className="p-1 custom_font_14px">
                      <b>Transfer Fees</b>
                    </th>
                  )}
                  <th className="p-1 custom_font_14px">
                    <b>Service Tax</b>
                  </th>
                  <th className="p-1 custom_font_14px">
                    <b>Net</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {clientVehicle.map((cv, index) => {
                  const paddingTopClass = index === 0 ? "pt-1" : "pt-3";
                  return (
                    <tr key={index} className="border-top-0 border-bottom-0">
                      <td className={`${paddingTopClass}`}>{index + 1}</td>
                      <td className={`text-start ps-2 ${paddingTopClass}`}>
                        {type === "Motor"
                          ? cv.motorCoverNote
                          : cv.nonMotorCoverNote}
                      </td>
                      <td className={`text-start ps-2 ${paddingTopClass}`}>
                        {cv.vehicleNo}
                      </td>
                      <td className={`text-start ps-2 ${paddingTopClass}`}>
                        {type === "Motor"
                          ? format(
                              parseISO(cv.motorIssuedDate),
                              "dd/MM/yyyy"
                            )
                          : format(
                              parseISO(cv.nonMotorIssuedDate),
                              "dd/MM/yyyy"
                            )}
                      </td>
                      <td className={`text-start ps-2 ${paddingTopClass}`}>
                        {type === "Motor"
                          ? numberWithCommas(cv.premiumGrossPremium)
                          : numberWithCommas(cv.nonMotorBasicPremium)}
                      </td>
                      {type === "Motor" && (
                        <td className={`text-start ps-2 ${paddingTopClass}`}>
                          {numberWithCommas(cv.premiumNcdPercentage)}
                        </td>
                      )}
                      <td className={`text-start ps-2 ${paddingTopClass}`}>
                        {type === "Motor"
                          ? numberWithCommas(cv.premiumPrincipalCommission)
                          : numberWithCommas(cv.nonMotorPrincipalCommission)}
                      </td>
                      <td className={`text-start ps-2 ${paddingTopClass}`}>
                        {type === "Motor"
                          ? numberWithCommas(cv.premiumStampDuty)
                          : numberWithCommas(cv.nonMotorStampDuty)}
                      </td>
                      {type === "Motor" && (
                        <td className={`text-start ps-2 ${paddingTopClass}`}>
                          {numberWithCommas(cv.premiumTransferFees)}
                        </td>
                      )}
                      <td className={`text-start ps-2 ${paddingTopClass}`}>
                        {type === "Motor"
                          ? numberWithCommas(cv.premiumServiceTax)
                          : numberWithCommas(cv.nonMotorTotal)}
                      </td>
                      <td className={`text-start ps-2 ${paddingTopClass}`}>
                        {type === "Motor"
                          ? numberWithCommas(cv.premiumNetPremium)
                          : numberWithCommas(cv.nonMotorGrossPremium)}
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td
                    colSpan={type === "Motor" ? 10 : 8}
                    className="text-end p-2 pe-3"
                  >
                    <b>Total RM</b>
                  </td>
                  <td className="text-end p-1 pe-2">
                    <b>
                      {total_pay
                        ? numberWithCommas(parseFloat(total_pay).toFixed(2))
                        : 0}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <table className="border border-1 border-dark text-start">
              <tbody>
                <tr>
                  <td className="w_20">
                    <b>Amount Paid:</b>
                  </td>
                  <td className="w-25">
                    <b>
                      RM &nbsp;
                      {total_pay
                        ? numberWithCommas(parseFloat(total_pay).toFixed(2))
                        : 0}
                    </b>
                  </td>
                  <td className="w_10">
                    <b>By: </b>
                  </td>
                  <td>
                    {parseFloat(cash) > 0 && (
                      <React.Fragment>
                        CASH ({cash_ref ? cash_ref.toUpperCase() : ""})
                        <br />
                      </React.Fragment>
                    )}
                    {parseFloat(cheque) > 0 && (
                      <React.Fragment>
                        CHEQUE ({cheque_ref ? cheque_ref.toUpperCase() : ""})
                      </React.Fragment>
                    )}
                    {parseFloat(credit_card) > 0 && (
                      <React.Fragment>
                        CREDIT CARD (
                        {credit_card_ref ? credit_card_ref.toUpperCase() : ""})
                      </React.Fragment>
                    )}
                    {parseFloat(epayment) > 0 && (
                      <React.Fragment>
                        E-PAYMENT (
                        {epayment_ref ? epayment_ref.toUpperCase() : ""})
                      </React.Fragment>
                    )}
                    {parseFloat(other) > 0 && (
                      <React.Fragment>
                        OTHER ({other_ref ? other_ref.toUpperCase() : ""})
                      </React.Fragment>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            This is a computer generated document. Signature is not required.
          </div>
        </div>
        <br></br>
      </div>
    </div>
  );
}
