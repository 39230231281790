import { format } from "date-fns";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Row } from "primereact/row";
import { Tag } from "primereact/tag";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BillingStatusSeverity } from "context/globalEnums";
import useAxiosPrivate from "context/useAxiosPrivate";
import "../ExistingEnquires/datatable.css";

export default function Payment() {
  const axiosPrivate = useAxiosPrivate();

  // Start of params props
  const { id, bizPartnerId, dateTime } = useParams();
  const scrollRef = useRef(null);
  const [getBizPartner, setGetBizPartner] = useState([]);

  const [selectedId, setSelectedId] = useState([
    {
      id: parseInt(id),
      toPay: parseFloat(0),
    },
  ]);
  // End of params props

  // Start of datatable props
  const [clientVehicle, setClientVehicle] = useState([]);
  const dt = useRef(null);
  const [sumTotalFooter, setSumTotalFooter] = useState([]);
  const [loading, setLoading] = useState(true);

  const [lazyState, setlazyState] = useState({
    sortField: "id",
    sortOrder: 1,
  });

  const [searchKeyword, setSearchKeyword] = useState("");
  const [issuedMonthFrom, setIssuedMonthFrom] = useState(new Date(dateTime));
  const [issuedMonthTo, setIssuedMonthTo] = useState(new Date(dateTime));

  const onSort = (event) => {
    setlazyState(event);
  };
  // End of datatable props

  // Start of payment
  const [payment, setPayment] = useState({
    cash: 0.0,
    cashRef: "",
    cheque: 0.0,
    chequeRef: "",
    creditCard: 0.0,
    creditCardRef: "",
    epayment: 0.0,
    epaymentRef: "",
    otherRef: "",
    other: 0.0,
    totalAmount: 0.0,
    balance: 0.0,
    bizPartnerId: bizPartnerId,
  });

  const {
    cash,
    cashRef,
    cheque,
    chequeRef,
    creditCard,
    creditCardRef,
    epayment,
    epaymentRef,
    otherRef,
    other,
    totalAmount,
    balance,
  } = payment;

  // End of payment

  // Start of numberWithCommas function

  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  useEffect(() => {
    loadClientVehiclePayment();
    // eslint-disable-next-line
  }, [searchKeyword, issuedMonthFrom, issuedMonthTo]);

  // Load Data to Datatable
  useEffect(() => {
    loadFirstClientVehiclePayment();
    // eslint-disable-next-line
  }, []);

  const loadFirstClientVehiclePayment = async () => {
    setLoading(true);

    const result = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/payment/bizPartner/${bizPartnerId}`,
      {
        params: {
          searchKey: searchKeyword ? searchKeyword.trim() : null,
          startDate: format(new Date(issuedMonthFrom), "yyyy-MM-dd'T'HH:mm:ss"),
          endDate: format(new Date(issuedMonthTo), "yyyy-MM-dd'T'HH:mm:ss"),
        },
      }
    );
    if (result?.data?.length > 0) {
      setGetBizPartner(result.data[0].bizPartnerCode);
      setGetBizPartner(result.data[0].bizPartnerCode);
      let resultData = result.data;
      let getTotal = 0.0;
      let getBalance = 0.0;
      const selectedMap = new Map(selectedId.map((e) => [e.id, e]));

      resultData.forEach((item) => {
        if (selectedMap.has(item.id)) {
          item.toPay = parseFloat(item.balance);
          item.totalAmount = parseFloat(item.totalAmount);
          selectedMap.set(item.id, {
            id: item.id,
            toPay: item.toPay,
            totalAmount: item.totalAmount,
          });
        } else {
          item.toPay = parseFloat(0);
        }
        getBalance += parseFloat(item.balance);
        getTotal += parseFloat(item.totalAmount);
      });
      setSumTotalFooter({
        total: numberWithCommas(getTotal),
        balance: numberWithCommas(getBalance),
      });
      setClientVehicle(resultData);

      setSelectedId(Array.from(selectedMap.values()));

      setTimeout(
        () =>
          scrollRef.current.scrollIntoView({
            inline: "center",
            behavior: "smooth",
          }),
        777
      );
    }
    setLoading(false);
  };

  const loadClientVehiclePayment = async () => {
    setLoading(true);

    const result = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/payment/bizPartner/${bizPartnerId}`,
      {
        params: {
          searchKey: searchKeyword ? searchKeyword.trim() : null,
          startDate: format(new Date(issuedMonthFrom), "yyyy-MM-dd'T'HH:mm:ss"),
          endDate: format(new Date(issuedMonthTo), "yyyy-MM-dd'T'HH:mm:ss"),
        },
      }
    );

    let resultData = result.data;
    let getTotal = 0.0;
    let getBalance = 0.0;
    const selectedMap = new Map(selectedId.map((e) => [e.id, e]));

    resultData.forEach((item) => {
      if (selectedMap.has(item.id)) {
        item.toPay = parseFloat(item.balance);
        item.totalAmount = parseFloat(item.totalAmount);
        selectedMap.set(item.id, {
          id: item.id,
          toPay: item.toPay,
          totalAmount: item.totalAmount,
        });
      } else {
        item.toPay = parseFloat(0);
      }
      getBalance += parseFloat(item.balance);
      getTotal += parseFloat(item.totalAmount);
    });
    setSumTotalFooter({
      total: numberWithCommas(getTotal),
      balance: numberWithCommas(getBalance),
    });
    setClientVehicle(resultData);

    let to_closed = [];
    let total = 0.0;
    const selectedSet = new Set(selectedId.map((e) => e.id));

    resultData.forEach((item) => {
      if (selectedSet.has(item.id)) {
        total -= parseFloat(item.toPay);
        to_closed.push(item);
      }
    });
    let balance_after = parseFloat(total) + parseFloat(totalAmount);
    setPayment({
      ...payment,
      balance: balance_after,
      // bizPartnerId: get_biz_partner,
    });
    setLoading(false);
  };
  // End of Load Data to Datatable

  // Start of Motor Datatable Options
  // -- Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // -- Datatable Header
  const header = (
    <>
      <div className="table-header">
        <div className="table-header-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <Calendar
              id="monthpicker"
              value={issuedMonthFrom}
              onChange={(e) => setIssuedMonthFrom(e.value)}
              view="month"
              dateFormat="mm/yy"
              showIcon
              placeholder="Months"
            />
          </span>
          <span className="d-flex align-self-center px-2">to</span>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <Calendar
              id="monthpicker"
              value={issuedMonthTo}
              onChange={(e) => setIssuedMonthTo(e.value)}
              view="month"
              dateFormat="mm/yy"
              showIcon
              placeholder="Months"
            />
          </span>
        </div>
        <div className="table-header-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={searchKeyword}
              onChange={(event) => setSearchKeyword(event.target.value)}
              placeholder="Keyword Search"
            />
          </span>

          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
            onClick={exportCSV}
          />
        </div>
      </div>
      <br></br>
      <div className="table-header">
        <div className="table-header-2">
          <b className="p-input-icon-left">
            {issuedMonthFrom.toLocaleString("en-US", { month: "long" })} to{" "}
            {issuedMonthTo.toLocaleString("en-US", { month: "long" })} Payment
          </b>
        </div>
      </div>
    </>
  );

  // -- Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total:"
          colSpan={5}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={sumTotalFooter.total}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={sumTotalFooter.balance}
          footerStyle={{ textAlign: "right" }}
        />
        <Column />
        <Column />
      </Row>
    </ColumnGroup>
  );

  // -- Start of Custom Body Templates
  const vehicleNoTemplates = (rowData) => {
    return (
      <React.Fragment>
        <Link to={`/eias/enquires/${rowData.id}/view`} target={"_blank"}>
          {rowData.vehicleNo}
        </Link>
      </React.Fragment>
    );
  };

  const balanceAmountTemplate = (rowData) => {
    return <React.Fragment>{numberWithCommas(rowData.balance)}</React.Fragment>;
  };

  const TotalAmountTemplate = (rowData) => {
    return (
      <React.Fragment>{numberWithCommas(rowData.totalAmount)}</React.Fragment>
    );
  };

  const toPayTemplates = (rowData) => {
    return (
      <React.Fragment>
        <InputNumber
          className="w-8"
          value={rowData.toPay}
          mode="decimal"
          minFractionDigits={2}
          disabled
        />
      </React.Fragment>
    );
  };

  const billingStatusTemplates = (rowData) => {
    const statusKey = rowData.billingStatus;
    const { display, severity } = BillingStatusSeverity[statusKey];

    return (
      <React.Fragment>
        <Tag
          className="w-100 custom_font_14px"
          value={display}
          severity={severity}
        ></Tag>
      </React.Fragment>
    );
  };

  // -- End of Custom Body Templates

  // End of Motor Datatable Options

  // Start of Cell Editable
  const cellEditor = (options) => {
    if (options.field === "toPay") return priceEditor(options);
  };

  const priceEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
        mode="decimal"
        minFractionDigits={2}
        maxFractionDigits={2}
        name={balance}
      />
    );
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field } = e;
    switch (field) {
      case "toPay":
        rowData[field] = newValue;
        onTotalPay();
        break;
      default:
        return "NA";
    }
  };
  // End of Cell Editable

  const onTotalPay = useCallback(() => {
    let total = 0.0;
    const selectedMap = new Map(selectedId.map((e) => [e.id, e]));

    clientVehicle.forEach((vehicle) => {
      if (selectedMap.has(vehicle.id)) {
        total -= parseFloat(vehicle.toPay);
        selectedMap.set(vehicle.id, {
          id: vehicle.id,
          toPay: vehicle.toPay,
        });
      }
    });

    // Avoid setting `selectedId` if it hasn't changed
    const newSelectedId = Array.from(selectedMap.values());
    if (JSON.stringify(newSelectedId) !== JSON.stringify(selectedId)) {
      setSelectedId(newSelectedId);
    }

    let balance_after = parseFloat(total) + parseFloat(totalAmount);
    setPayment((prevPayment) => ({
      ...prevPayment,
      balance: balance_after,
    }));
  }, [selectedId, clientVehicle, totalAmount]);

  useEffect(() => {
    onTotalPay();
  }, [onTotalPay]);
  // End of Function for Cell Editable

  // Start of onInputChange && onInputNumberChange
  const onInputChange = (e) => {
    setPayment({
      ...payment,
      [e.target.name]: e.target.value,
    });
  };

  const onInputNumberChange = (e) => {
    if (e.originalEvent.target.value === "") {
      e.originalEvent.target.value = parseFloat(0.0);
    }
    setPayment({
      ...payment,
      [e.originalEvent.target.name]: parseFloat(
        e.originalEvent.target.value.replace(/,/g, "")
      ).toFixed(2),
    });
  };

  useEffect(() => {
    setPayment({
      ...payment,
      totalAmount:
        parseFloat(cash) +
        parseFloat(cheque) +
        parseFloat(creditCard) +
        parseFloat(epayment) +
        parseFloat(other),
    });
    // eslint-disable-next-line
  }, [cash, cheque, creditCard, epayment, other]);
  // End of onInputChange && onInputNumberChange

  // Start of onSubmit function
  let navigate = useNavigate();
  const onSubmit = async (e) => {
    e.preventDefault();

    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async (e) => {
        const response = await axiosPrivate.post(
          `${process.env.REACT_APP_API_URL}/payment`,
          JSON.stringify({
            ...payment,
            clientVehicle: selectedId,
          })
        );
        navigate(
          `/eias/reports/collection_by_business_partner_view/${response.data}`
        );
      },
      reject: () => {},
    });
  };
  // End of onSubmit function

  return (
    <section className="pt-1 pb-5 bg-theme">
      <ConfirmDialog />
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Payment Settlement Biz Partner</h2>
            <h4 className="text-start">Partner: {getBizPartner}</h4>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={clientVehicle}
              dataKey="id"
              loading={loading}
              lazy={true}
              header={header}
              footerColumnGroup={footerGroup}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              resizableColumns
              columnResizeMode="fit"
              showGridlines
              size="small"
              stateStorage="session"
              selectionMode="checkbox"
              selection={selectedId}
              onSelectionChange={(e) => {
                let value = e.value;
                const valueIdSet = new Set(value.map((e) => e.id));

                clientVehicle.forEach((vehicle) => {
                  if (valueIdSet.has(vehicle.id)) {
                    if (parseFloat(vehicle.toPay) === 0) {
                      vehicle.toPay = parseFloat(vehicle.balance);
                    }
                  } else {
                    vehicle.toPay = 0;
                  }
                });
                const updatedSelection = value.map(({ id, toPay }) => ({
                  id,
                  toPay,
                }));
                setSelectedId(updatedSelection);
              }}
            >
              <Column
                field="debitNote"
                header="Debit Note No"
                sortable
              ></Column>
              <Column field="issuedAt" header="Issued Date" sortable></Column>
              <Column
                body={vehicleNoTemplates}
                field="vehicleNo"
                header="Vehicle No"
                sortable
              ></Column>
              <Column
                field="coverNote"
                header="Cover Note No"
                sortable
              ></Column>
              <Column
                body={billingStatusTemplates}
                field="billingStatus"
                header="Status"
                sortable
                bodyStyle={{ textAlign: "center" }}
              ></Column>
              <Column
                body={TotalAmountTemplate}
                field="totalAmount"
                header="Total (RM)"
                className="text-end pe-3"
              ></Column>
              <Column
                body={balanceAmountTemplate}
                field="balance"
                header="Balance (RM)"
                className="text-end pe-3"
              ></Column>
              <Column
                field="toPay"
                body={toPayTemplates}
                header="To Payable (RM)"
                className="text-end pe-3"
                editor={(options) => cellEditor(options)}
                onCellEditComplete={onCellEditComplete}
              ></Column>
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3em" }}
              ></Column>
            </DataTable>
            <br />
            <form onSubmit={(e) => onSubmit(e)}>
              <table
                className="bg-white table-bordered"
                id="payment_table"
                ref={scrollRef}
              >
                <thead>
                  <tr>
                    <th className="p-2">
                      <b>Collection Mode</b>
                    </th>
                    <th className="p-2">
                      <b>Reference</b>
                    </th>
                    <th className="p-2">
                      <b>Amount (RM)</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CASH</td>
                    <td>
                      <InputText
                        placeholder="Enter Cash's Reference"
                        className="w-100"
                        name="cashRef"
                        value={cashRef}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="cash"
                        value={cash}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CHEQUE</td>
                    <td>
                      <InputText
                        placeholder="Enter Cheque's Reference"
                        className="w-100"
                        name="chequeRef"
                        value={chequeRef}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="cheque"
                        value={cheque}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>DIRECTORS CREDIT CARD</td>
                    <td>
                      <InputText
                        placeholder="Enter Credit Card's Reference"
                        className="w-100"
                        name="creditCardRef"
                        value={creditCardRef}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="creditCard"
                        value={creditCard}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>ePAYMENT</td>
                    <td>
                      <InputText
                        placeholder="Enter ePayment's Reference"
                        className="w-100"
                        name="epaymentRef"
                        value={epaymentRef}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="epayment"
                        value={epayment}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>OTHER</td>
                    <td>
                      <InputText
                        placeholder="Enter Other's Reference"
                        className="w-100"
                        name="otherRef"
                        value={otherRef}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="other"
                        value={other}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Total Payment RM</td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="totalAmount"
                        value={totalAmount}
                        readOnly={true}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Balance</td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="balance"
                        value={balance}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button
                type="submit"
                className="float-end mt-3"
                label="Submit"
                icon="pi pi-check"
                iconPos="right"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
