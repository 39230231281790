import { Checkbox } from "primereact/checkbox";
import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import ClientVehicleInfoContext from "context/ClientVehicleProvider";
import "../../css/custom.css";
export default function ModalBenefits(props) {
  const modalState = props.toggle;
  const action = props.action;
  const [llopChecked, setLlopChecked] = useState(false);
  const [aonChecked, setAonChecked] = useState(false);
  const [adCompanyChecked, setAdCompanyChecked] = useState(false);
  const [adIndvChecked, setAdIndvChecked] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const {
    premiumExtraBenefitsWindscreenCoverValue,
    premiumExtraBenefitsWindscreenCoverTotal,
    premiumExtraBenefitsLegalLiabilityToPassengersValue,
    premiumExtraBenefitsLegalLiabilityToPassengersTotal,
    premiumExtraBenefitsActOfNegligenceTotal,
    premiumExtraBenefitsStrikeCommotionCoverTotal,
    premiumExtraBenefitsAllDriversTotal,
    premiumExtraBenefitsAllDriversInvTotal,
    premiumExtraBenefitsConvulsionOfNatureTotal,
    premiumExtraBenefitsAdditionalDriverNamed,
    premiumExtraBenefitsAdditionalDriverTotal,
    premiumExtraBenefitsRadioCasetteValue,
    premiumExtraBenefitsRadioCasetteTotal,
    premiumExtraBenefitsInsuranceProtectionTotal,
    premiumExtraBenefitsInclusionOfDamageTotal,
    premiumExtraBenefitsInclusionOfAccidentalTotal,
    premiumExtraBenefitsExtensionOfCoverTotal,
    premiumExtraBenefitsGeneraliMCPATotal,
    premiumExtraBenefitsUnlimitedTowingTotal,
    premiumExtraBenefitsOther1Value,
    premiumExtraBenefitsOther1Total,
    premiumExtraBenefitsOther2Value,
    premiumExtraBenefitsOther2Total,
    premiumExtraBenefitsOther3Value,
    premiumExtraBenefitsOther3Total,
    premiumExtraBenefitsTotal,
    third_party_premium,
  } = clientVehicleInfo;

  const onInputChange = (e) => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });
  };

  const location = useLocation();
  useEffect(() => {
    // if (
    //   location.pathname.includes("view") &&
    //   clientVehicleInfo.status !== "Draft"
    // ) {
    //   var all = document.querySelectorAll(".form-control");
    //   for (var i = 0, len = all.length; i < len; i++) {
    //     all[i].disabled = true;
    //   }
    //   setViewOnly(true);
    // }
    // eslint-disable-next-line
  }, [modalState]);

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      premiumExtraBenefitsWindscreenCoverTotal: (
        (parseFloat(premiumExtraBenefitsWindscreenCoverValue) * 15) /
        100
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [premiumExtraBenefitsWindscreenCoverValue]);

  useEffect(() => {
    if (premiumExtraBenefitsLegalLiabilityToPassengersValue > 5) {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        premiumExtraBenefitsLegalLiabilityToPassengersTotal: (
          parseFloat(third_party_premium) +
          (premiumExtraBenefitsLegalLiabilityToPassengersValue - 5) * 10
        ).toFixed(2),
      });
    }
    // eslint-disable-next-line
  }, [premiumExtraBenefitsLegalLiabilityToPassengersValue]);

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      premiumExtraBenefitsTotal: (
        parseFloat(premiumExtraBenefitsWindscreenCoverTotal) +
        parseFloat(premiumExtraBenefitsLegalLiabilityToPassengersTotal) +
        parseFloat(premiumExtraBenefitsActOfNegligenceTotal) +
        parseFloat(premiumExtraBenefitsStrikeCommotionCoverTotal) +
        parseFloat(premiumExtraBenefitsAllDriversTotal) +
        parseFloat(premiumExtraBenefitsAllDriversInvTotal) +
        parseFloat(premiumExtraBenefitsConvulsionOfNatureTotal) +
        parseFloat(premiumExtraBenefitsAdditionalDriverTotal) +
        parseFloat(premiumExtraBenefitsRadioCasetteTotal) +
        parseFloat(premiumExtraBenefitsInsuranceProtectionTotal) +
        parseFloat(premiumExtraBenefitsInclusionOfDamageTotal) +
        parseFloat(premiumExtraBenefitsInclusionOfAccidentalTotal) +
        parseFloat(premiumExtraBenefitsExtensionOfCoverTotal) +
        parseFloat(premiumExtraBenefitsGeneraliMCPATotal) +
        parseFloat(premiumExtraBenefitsUnlimitedTowingTotal) +
        parseFloat(premiumExtraBenefitsOther1Total) +
        parseFloat(premiumExtraBenefitsOther2Total) +
        parseFloat(premiumExtraBenefitsOther3Total)
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [
    premiumExtraBenefitsWindscreenCoverTotal,
    premiumExtraBenefitsLegalLiabilityToPassengersTotal,
    premiumExtraBenefitsActOfNegligenceTotal,
    premiumExtraBenefitsStrikeCommotionCoverTotal,
    premiumExtraBenefitsAllDriversTotal,
    premiumExtraBenefitsAllDriversInvTotal,
    premiumExtraBenefitsConvulsionOfNatureTotal,
    premiumExtraBenefitsAdditionalDriverTotal,
    premiumExtraBenefitsRadioCasetteTotal,
    premiumExtraBenefitsInsuranceProtectionTotal,
    premiumExtraBenefitsInclusionOfDamageTotal,
    premiumExtraBenefitsInclusionOfAccidentalTotal,
    premiumExtraBenefitsExtensionOfCoverTotal,
    premiumExtraBenefitsGeneraliMCPATotal,
    premiumExtraBenefitsUnlimitedTowingTotal,
    premiumExtraBenefitsOther1Total,
    premiumExtraBenefitsOther2Total,
    premiumExtraBenefitsOther3Total,
  ]);

  return (
    <Modal show={modalState} onHide={action} dialogClassName="modal-90w">
      <Modal.Header closeButton>
        <Modal.Title>Extra Benefits</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table>
          <tbody>
            <tr>
              <td>Windscreen Cover</td>
              <td className="text-end">Value RM</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control w-8"
                  name="premiumExtraBenefitsWindscreenCoverValue"
                  value={premiumExtraBenefitsWindscreenCoverValue}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsWindscreenCoverTotal"
                  value={premiumExtraBenefitsWindscreenCoverTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox
                  inputId="cb1"
                  onChange={(e) => {
                    setLlopChecked(e.checked);
                    if (e.checked === true) {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premiumExtraBenefitsLegalLiabilityToPassengersValue: 5,
                        premiumExtraBenefitsLegalLiabilityToPassengersTotal:
                          parseFloat(third_party_premium * 0.25).toFixed(2),
                      });
                    } else {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premiumExtraBenefitsLegalLiabilityToPassengersValue: 0,
                        premiumExtraBenefitsLegalLiabilityToPassengersTotal:
                          (0).toFixed(2),
                      });
                    }
                  }}
                  checked={llopChecked}
                  disabled={viewOnly}
                ></Checkbox>
                &nbsp; &nbsp;Legal Liability to Passengers
              </td>
              <td className="text-end">Passengers</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={1}
                  className="form-control w-8"
                  name="premiumExtraBenefitsLegalLiabilityToPassengersValue"
                  value={premiumExtraBenefitsLegalLiabilityToPassengersValue}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsLegalLiabilityToPassengersTotal"
                  value={premiumExtraBenefitsLegalLiabilityToPassengersTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Checkbox
                  inputId="cb1"
                  onChange={(e) => {
                    setAonChecked(e.checked);
                    if (e.checked === true) {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premiumExtraBenefitsActOfNegligenceTotal: (7.5).toFixed(
                          2
                        ),
                      });
                    } else {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premiumExtraBenefitsActOfNegligenceTotal: (0).toFixed(
                          2
                        ),
                      });
                    }
                  }}
                  checked={aonChecked}
                  disabled={viewOnly}
                ></Checkbox>
                &nbsp; &nbsp;Legal Liability of Passengers for Acts of
                Negligence
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsActOfNegligenceTotal"
                  value={premiumExtraBenefitsActOfNegligenceTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Checkbox
                  inputId="cb1"
                  onChange={(e) => {
                    setAdCompanyChecked(e.checked);
                    if (e.checked === true) {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premiumExtraBenefitsAllDriversTotal: (50).toFixed(2),
                      });
                    } else {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premiumExtraBenefitsAllDriversTotal: (0).toFixed(2),
                      });
                    }
                  }}
                  checked={adCompanyChecked}
                  disabled={viewOnly}
                ></Checkbox>
                &nbsp; &nbsp;All Drivers (Company/Organisation Policy Only)
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsAllDriversTotal"
                  value={premiumExtraBenefitsAllDriversTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Checkbox
                  inputId="cb1"
                  onChange={(e) => {
                    setAdIndvChecked(e.checked);
                    if (e.checked === true) {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premiumExtraBenefitsAllDriversInvTotal: (20).toFixed(2),
                      });
                    } else {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premiumExtraBenefitsAllDriversInvTotal: 0,
                      });
                    }
                  }}
                  checked={adIndvChecked}
                  disabled={viewOnly}
                ></Checkbox>
                &nbsp; &nbsp;All Drivers (Individual Policy Only)
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsAllDriversInvTotal"
                  value={premiumExtraBenefitsAllDriversInvTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Strike, Riot & Civil Commotion Cover</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsStrikeCommotionCoverTotal"
                  value={premiumExtraBenefitsStrikeCommotionCoverTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <details>
                  <summary>Convulsion of Nature</summary>
                  <p>
                    - such as Flood, Typhoon, Hurricane, Storm, Tempest,
                    Volcanic Eruption, Earthquake, Landslide, Landslip or other
                  </p>
                </details>
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsConvulsionOfNatureTotal"
                  value={premiumExtraBenefitsConvulsionOfNatureTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                Additional Authorised Driver(s) (One Name per line)
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <textarea
                  className="form-control"
                  name="premiumExtraBenefitsAdditionalDriverNamed"
                  value={premiumExtraBenefitsAdditionalDriverNamed}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
              <td></td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsAdditionalDriverTotal"
                  value={premiumExtraBenefitsAdditionalDriverTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                Radio Casette/Tape Recorder/Air Conditioner (After Market Only)
              </td>
              <td className="text-end">Value RM</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control w-8"
                  name="premiumExtraBenefitsRadioCasetteValue"
                  value={premiumExtraBenefitsRadioCasetteValue}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsRadioCasetteTotal"
                  value={premiumExtraBenefitsRadioCasetteTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                Insurance Protection for Learner Drivers (Tuition Purpose)
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsInsuranceProtectionTotal"
                  value={premiumExtraBenefitsInsuranceProtectionTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                Inclusion of Damage by Overturning (Only for Mobile
                Crane/Tractor)
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsInclusionOfDamageTotal"
                  value={premiumExtraBenefitsInclusionOfDamageTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                Inclusion of Accidental Damage to the Boom (Only for Mobile
                Crane/Tractor)
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsInclusionOfAccidentalTotal"
                  value={premiumExtraBenefitsInclusionOfAccidentalTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Extension of Cover to the Kingdom of Thailand</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsExtensionOfCoverTotal"
                  value={premiumExtraBenefitsExtensionOfCoverTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Generali MCPA / MPA</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsGeneraliMCPATotal"
                  value={premiumExtraBenefitsGeneraliMCPATotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Unlimted Towing</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsUnlimitedTowingTotal"
                  value={premiumExtraBenefitsUnlimitedTowingTotal}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex">
                  <span className="align-self-center me-2">Other</span>
                  <input
                    type="text"
                    className="form-control"
                    name="premiumExtraBenefitsOther1Value"
                    value={premiumExtraBenefitsOther1Value}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </td>
              <td></td>
              <td></td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsOther1Total"
                  value={premiumExtraBenefitsOther1Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex">
                  <span className="align-self-center me-2">Other</span>
                  <input
                    type="text"
                    className="form-control"
                    name="premiumExtraBenefitsOther2Value"
                    value={premiumExtraBenefitsOther2Value}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </td>
              <td></td>
              <td></td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsOther2Total"
                  value={premiumExtraBenefitsOther2Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex">
                  <span className="align-self-center me-2">Other</span>
                  <input
                    type="text"
                    className="form-control"
                    name="premiumExtraBenefitsOther3Value"
                    value={premiumExtraBenefitsOther3Value}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </td>
              <td></td>
              <td></td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premiumExtraBenefitsOther3Total"
                  value={premiumExtraBenefitsOther3Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <span className="float-end">Total RM</span>
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control text_background_green"
                  name="premiumExtraBenefitsTotal"
                  value={premiumExtraBenefitsTotal}
                  onChange={(e) => onInputChange(e)}
                  readOnly
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={action}>
          Close
        </Button>
        <Button variant="primary" onClick={action}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
