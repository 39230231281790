import React, { useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useLocation, useParams } from "react-router-dom";
import ClientVehicleInfoContext from "context/ClientVehicleProvider";
import { StatusEnum } from "context/globalEnums";
import BillingDraft from "./BillingDraft";
import BillingIssued from "./BillingIssued";

export default function BillingInfo() {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );
  const {
    billingMotorPremium,
    billingDiscount,
    billingNonMotorPremium,
    billingDiscountNonMotor,
    billingSupportingServices,
    billingSubtotal,
    billingTotalPayable,
    status,
  } = clientVehicleInfo;

  const { id } = useParams();
  const location = useLocation();

  const onInputChange = (e) => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      billingTotalPayable: (
        parseFloat(billingSubtotal) -
        parseFloat(billingDiscount) -
        parseFloat(billingDiscountNonMotor)
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [
    billingTotalPayable,
    billingSubtotal,
    billingDiscount,
    billingDiscountNonMotor,
  ]);

  return (
    <div className="overflow-auto mt-4">
      <Container>
        <Row>
          <Col sm>
            <table className="billing_Table">
              <tbody>
                <tr>
                  <td colSpan="3"></td>
                  <td>
                    <span className="billing_Table_floar_right_Column">
                      Preset Discount
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>Motor Premium</td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step={0.01}
                      className="form-control text_background_green"
                      name="billingMotorPremium"
                      value={billingMotorPremium ?? "0.00"}
                      onChange={(e) => onInputChange(e)}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step={0.01}
                      className="form-control"
                      name="billingDiscount"
                      value={billingDiscount ?? "0.00"}
                      disabled={
                        clientVehicleInfo.status !== StatusEnum.DRAFT &&
                        location.pathname === `/eias/enquires/${id}/view`
                      }
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>Non-Motor / Medical Premium</td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step={0.01}
                      className="form-control text_background_green"
                      name="billingNonMotorPremium"
                      value={billingNonMotorPremium ?? "0.00"}
                      onChange={(e) => onInputChange(e)}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step={0.01}
                      className="form-control"
                      name="billingDiscountNonMotor"
                      value={billingDiscountNonMotor ?? "0.00"}
                      disabled={
                        clientVehicleInfo.status !== StatusEnum.DRAFT &&
                        location.pathname === `/eias/enquires/${id}/view`
                      }
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Supporting Services</td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step={0.01}
                      className="form-control text_background_green"
                      name="billingSupportingServices"
                      value={billingSupportingServices ?? "0.00"}
                      onChange={(e) => onInputChange(e)}
                      readOnly
                    />
                  </td>
                  <td hidden>Desired Discounted Amount</td>
                </tr>
                <tr>
                  <td>Subtotal</td>
                  <td></td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step={0.01}
                      className="form-control text_background_green"
                      name="billingSubtotal"
                      value={billingSubtotal ?? "0.00"}
                      onChange={(e) => onInputChange(e)}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Total Payable</td>
                  <td>
                    <span className="premium_Table_last_Column">RM</span>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step={0.01}
                      className="form-control text_background_green"
                      name="billingTotalPayable"
                      value={billingTotalPayable ?? "0.00"}
                      onChange={(e) => onInputChange(e)}
                      readOnly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col sm>
            {status === StatusEnum.DRAFT && <BillingDraft></BillingDraft>}
            {status !== StatusEnum.DRAFT && <BillingIssued></BillingIssued>}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
