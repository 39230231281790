import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "primeicons/primeicons.css";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { default as React, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ClientVehicleInfoContext from "context/ClientVehicleProvider";
import { StatusEnum } from "context/globalEnums";
import useAxiosPrivate from "context/useAxiosPrivate";
import ModalClient from "./Modal/ModalClient";
import ModalVehicle from "./Modal/ModalVehicle";

export default function ClientInfo() {
  const axiosPrivate = useAxiosPrivate();
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const { id } = useParams();
  const location = useLocation();

  const [nameModal, setNameModal] = useState(false);
  const [vehicleModal, setVehicleModal] = useState(false);

  function openNameModal() {
    setNameModal(!nameModal);
  }

  function openVehicleModal() {
    setVehicleModal(!vehicleModal);
  }

  const {
    name,
    nric,
    address,
    phoneNumber1,
    phoneNumber2,
    email,
    phoneNumber3,
    principalId,
    bizPartnerId,
    vehicleNo,
  } = clientVehicleInfo;

  const onInputChange = (e) => {
    console.log(principalDropdown);
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "principalId") {
      const selectedPrincipal = principalDropdown.find(
        (principal) => principal.id === parseInt(e.target.value)
      );

      setClientVehicleInfo({
        ...clientVehicleInfo,
        principalCode: selectedPrincipal.code,
        motorPrincipalTariff: selectedPrincipal.tariffMotor,
        nonMotorPrincipalTariff: selectedPrincipal.tariffNonMotor,
        principalId: e.target.value,
      });
    }
    if (e.target.name === "bizPartnerId") {
      const selectedBizPartner = bizPartnerDropdown.find(
        (bizPartner) => bizPartner.id === parseInt(e.target.value)
      );

      setClientVehicleInfo({
        ...clientVehicleInfo,
        bizPartnerCode: selectedBizPartner.code,
        motorPartnerCommissionRate: selectedBizPartner.commissionMotor,
        nonMotorPartnerCommission: selectedBizPartner.commissionNonMotor,
        bizPartnerId: e.target.value,
      });
    }
  };

  // Dropdown Options
  const [principalDropdown, setPrincipalDropdown] = useState([]);
  const [bizPartnerDropdown, setBizPartnerDropdown] = useState([]);

  useEffect(() => {
    loadPrincipal_BizPartner();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadPrincipal_BizPartner();
    // eslint-disable-next-line
  }, [id, location]);

  const loadPrincipal_BizPartner = async (e) => {
    const result_principal = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/principal/names`
    );
    setPrincipalDropdown(result_principal.data);

    const result_Biz = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/biz_partner/names`
    );
    setBizPartnerDropdown(result_Biz.data);

    if (id ?? false) {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        principalId: clientVehicleInfo.principalId,
        bizPartnerId: clientVehicleInfo.bizPartnerId,
      });
    } else {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        principalId: 1,
        bizPartnerId: 1,
      });

      const selectedPrincipal = result_principal.data.find(
        (principal) => principal.id === parseInt(1)
      );

      const selectedBizPartner = result_Biz.data.find(
        (bizPartner) => bizPartner.id === parseInt(1)
      );

      setClientVehicleInfo({
        ...clientVehicleInfo,
        principalCode: selectedPrincipal.code,
        motorPrincipalTariff: selectedPrincipal.tariffMotor,
        nonMotorPrincipalTariff: selectedPrincipal.tariffNonMotor,
        principalId: 1,
        bizPartnerCode: selectedBizPartner.code,
        motorPartnerCommissionRate: selectedBizPartner.commissionMotor,
        nonMotorPartnerCommission: selectedBizPartner.commissionNonMotor,
        bizPartnerId: 1,
      });
    }
  };

  useEffect(() => {
    if (principalId !== null && principalDropdown.length > 0) {
      for (let i = 0; i < principalDropdown.length; i++) {
        if (principalId === principalDropdown[i].code) {
          setClientVehicleInfo({
            ...clientVehicleInfo,
            motorPrincipalTariff: parseFloat(
              principalDropdown[i].tariff_motor
            ).toFixed(2),
            nonMotorPrincipalTariff: parseFloat(
              principalDropdown[i].tariff_non_motor
            ).toFixed(2),
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [principalId, principalDropdown]);

  useEffect(() => {
    for (let i = 0; i < bizPartnerDropdown.length; i++) {
      if (bizPartnerId === bizPartnerDropdown[i].code) {
        setClientVehicleInfo({
          ...clientVehicleInfo,
          motorPartnerCommissionRate: parseFloat(
            bizPartnerDropdown[i].commission_motor
          ).toFixed(2),
          nonMotorPartnerCommission: parseFloat(
            bizPartnerDropdown[i].commission_non_motor
          ).toFixed(2),
        });
      }
    }
    // eslint-disable-next-line
  }, [bizPartnerId]);
  return (
    <div className="overflow-auto mt-4">
      <table className="name_Table">
        <tbody>
          <tr>
            <td>
              Name
              <FontAwesomeIcon
                icon="address-book"
                className="ms-2 icon"
                onClick={openNameModal}
              />
            </td>

            <td>
              <input
                type="text"
                className="form-control name_upper"
                name="name"
                value={name}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>

            <td className="text-right">&nbsp; NRIC No.</td>

            <td>
              <input
                type="text"
                className="form-control"
                name="nric"
                value={nric ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>

          <tr>
            <td rowSpan="2">Address</td>

            <td rowSpan="2">
              <textarea
                className="form-control"
                name="address"
                value={address ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>

            <td className="text-right">&nbsp; Phone No. 1</td>

            <td>
              <input
                type="text"
                className="form-control"
                name="phoneNumber1"
                value={phoneNumber1 ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>

          <tr>
            <td className="text-right">&nbsp; Phone No. 2</td>

            <td>
              <input
                type="text"
                className="form-control"
                name="phoneNumber2"
                value={phoneNumber2 ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>

          <tr>
            <td>Email</td>

            <td>
              <input
                type="text"
                className="form-control"
                name="email"
                value={email ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>

            <td className="text-right">&nbsp; Phone No. 3</td>

            <td>
              <input
                type="text"
                className="form-control"
                name="phoneNumber3"
                value={phoneNumber3 ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <table className="vehicle_Table">
        <tbody>
          <tr>
            <td>Ins. Principal &nbsp;</td>

            <td>
              <Dropdown
                name="principalId"
                value={principalId}
                options={principalDropdown}
                optionLabel="code"
                optionValue="id"
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                placeholder="Select Principal"
                className="form-control"
              />
            </td>

            <td className="text-right">&nbsp;Biz Partner &nbsp;</td>
            <td>
              <Dropdown
                name="bizPartnerId"
                value={bizPartnerId}
                options={bizPartnerDropdown}
                optionLabel="code"
                optionValue="id"
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                placeholder="Select Biz Partner"
                className="form-control w-full md:w-14rem"
              />
            </td>

            <td className="text-right">
              &nbsp; Vehicle No.
              <FontAwesomeIcon
                icon="car"
                className="ms-2 icon"
                onClick={openVehicleModal}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name="vehicleNo"
                value={vehicleNo ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {clientVehicleInfo.status === StatusEnum.DRAFT && (
        <React.Fragment>
          <ModalClient toggle={nameModal} action={openNameModal} />
          <ModalVehicle toggle={vehicleModal} action={openVehicleModal} />
        </React.Fragment>
      )}
    </div>
  );
}
