import ClientVehicleInfoContext from "context/ClientVehicleProvider";
import {
  BillingStatusSeverity,
  PrintNoteEnum,
  StatusEnum,
} from "context/globalEnums";
import useAxiosPrivate from "context/useAxiosPrivate";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Tag } from "primereact/tag";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

export default function BillingInfo() {
  const axiosPrivate = useAxiosPrivate();
  const { clientVehicleInfo } = useContext(ClientVehicleInfoContext);
  const {
    debitNote,
    creditNote,
    status,
    id,
    bizPartnerId,
    issuedAt,
    officialReceipts,
    billingStatus,
  } = clientVehicleInfo;
  const [loading, setLoading] = useState(false);

  const cancelCase = async (e) => {
    e.preventDefault();

    confirmDialog({
      message: "Are you sure you want to cancel case?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async (e) => {
        // eslint-disable-next-line
        await axiosPrivate.delete(
          `${process.env.REACT_APP_API_URL}/client_vehicle/${clientVehicleInfo.id}`
        );
        window.location.reload();
      },
      reject: () => {},
    });
  };

  const printNote = async (printType, clientVehicleId, subId) => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_API_URL}/print/${clientVehicleId}`,
        {
          params: {
            printType: printType,
            ...(subId !== undefined && { subId: subId }),
          },
          responseType: "blob",
        }
      );

      if (response.data) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        window.open(url, "_blank");
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
    setLoading(false);
  };

  return (
    <Card className="issued_card mt-4 ms-4">
      <table className="issued_Table">
        <tbody>
          <tr>
            <td className="w-75">Billing Status:</td>
            <td>
              <Tag
                className="w-100 custom_font_14px"
                value={BillingStatusSeverity[billingStatus].display}
                severity={BillingStatusSeverity[billingStatus].severity}
              ></Tag>
            </td>
          </tr>
          {debitNote !== undefined &&
            debitNote !== null &&
            status !== StatusEnum.CANCELED && (
              <tr>
                <td className="w-75">Debit Note No:</td>
                <td>{debitNote}</td>
                <td>
                  <Button
                    icon="pi pi-print"
                    type="button"
                    label="Print"
                    className="width_10_em"
                    onClick={() =>
                      printNote(PrintNoteEnum.DEBIT_NOTE, id, null)
                    }
                  ></Button>
                </td>
              </tr>
            )}
          {creditNote !== undefined &&
            creditNote !== null &&
            status !== StatusEnum.CANCELED && (
              <tr>
                <td>Credit Note No:</td>
                <td>{creditNote}</td>
                <td>
                  <Button
                    icon="pi pi-print"
                    type="button"
                    label="Print"
                    className="width_10_em"
                    onClick={() =>
                      printNote(PrintNoteEnum.CREDIT_NOTE, id, null)
                    }
                  ></Button>
                </td>
              </tr>
            )}
          {status === StatusEnum.ISSUED && (
            <React.Fragment>
              <tr>
                <td></td>
                <td colSpan={2}>
                  <Link
                    to={`/eias/enquires/payment/${id}/bizPartner/${bizPartnerId}/${new Date(
                      issuedAt
                    )}`}
                  >
                    <Button
                      icon="pi pi-money-bill"
                      label="Process to Payment"
                      className="width"
                      type="button"
                    ></Button>
                  </Link>
                </td>
              </tr>
            </React.Fragment>
          )}
          {officialReceipts?.length > 0 && status !== StatusEnum.CANCELED && (
            <tr>
              <td>Customer Receipt No:</td>
              <td>{officialReceipts[0].orNote}</td>
              <td>
                <Link
                  onClick={() =>
                    printNote(
                      PrintNoteEnum.OFFICIAL_RECEIPT_CUSTOMER,
                      officialReceipts[0].id,
                      id
                    )
                  }
                >
                  <Button
                    icon="pi pi-print"
                    type="button"
                    label="Print"
                    className="width_10_em"
                  ></Button>
                </Link>
              </td>
            </tr>
          )}
          {officialReceipts?.length > 0 && status !== StatusEnum.CANCELED && (
            <React.Fragment>
              {officialReceipts.map((or, index) => (
                <tr key={index}>
                  <td>Official Receipt No {index + 1}: </td>
                  <td>{or.orNote}</td>
                  <td>
                    <Link
                      target={"_blank"}
                      to={`/eias/reports/collection_by_business_partner_view/${or.id}`}
                    >
                      <Button
                        icon="pi pi-list"
                        type="button"
                        label="View Payment"
                        className="width_10_em"
                      ></Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          )}
          <tr>
            <td>Status:</td>
            <td>{status ?? ""}</td>
          </tr>
          {status !== StatusEnum.CANCELED && (
            <React.Fragment>
              <tr>
                <td></td>
                <td colSpan={2}>
                  <Button
                    icon="pi pi-print"
                    label="Print Case Detail"
                    className="width"
                    type="button"
                    onClick={() =>
                      printNote(PrintNoteEnum.CASE_DETAILS, id, null)
                    }
                  ></Button>
                </td>
              </tr>
              {(status === StatusEnum.ISSUED ||
                status === StatusEnum.CLOSED) && (
                <tr>
                  <td></td>
                  <td colSpan={2}>
                    <Button
                      icon="pi pi-info-circle"
                      label="Cancel Case"
                      className="width"
                      type="button"
                      onClick={(e) => cancelCase(e)}
                    ></Button>
                  </td>
                </tr>
              )}
            </React.Fragment>
          )}
        </tbody>
      </table>

      {loading && (
        <div className="loading-container text-center">
          <ProgressSpinner />
        </div>
      )}
    </Card>
  );
}
