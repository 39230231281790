import axios from "api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    try {
      const response = await axios.post("/refresh", null, {
        withCredentials: true,
      });
      setAuth((prev) => {
        return {
          ...prev,
          roles: response.data.roles,
          accessToken: response.data.accessToken,
        };
      });

      return response.data.accessToken;
    } catch (error) {
      // console.error("Error during refresh:", error);
    }
  };

  return refresh;
};

export default useRefreshToken;
