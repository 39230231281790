import useAxiosPrivate from "context/useAxiosPrivate";
import { format } from "date-fns";
import "primeicons/primeicons.css";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useEffect, useState } from "react";
import "../Enquires/ExistingEnquires/datatable.css";
import { ProgressSpinner } from "primereact/progressspinner";

export default function Statement() {
  const axiosPrivate = useAxiosPrivate();

  // Start of datatable props
  const [statement, setStatement] = useState([]);
  const [loading, setLoading] = useState(true);
  // End of datatable props

  // Start of Search filter Value
  const [searchPrincipal, setSearchPrincipal] = useState("");
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
  );
  // End of Search filter Value

  // Start of Dropdown Options
  const [principalDropdown, setPrincipalDropdown] = useState([]);

  useEffect(() => {
    loadPrincipal();
    // eslint-disable-next-line
  }, []);

  const loadPrincipal = async (e) => {
    const result_principal = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/principal/names`
    );
    setPrincipalDropdown(result_principal.data);
  };
  // End of Dropdown Options

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Start of Load Data to Datatable with filter search
  useEffect(() => {
    loadReport();
    // eslint-disable-next-line
  }, [searchPrincipal, startDate, endDate]);

  const loadReport = async () => {
    setLoading(true);

    const result = await axiosPrivate.post(
      `${process.env.REACT_APP_API_URL}/report/statement`,
      {
        principalId: searchPrincipal ? searchPrincipal : null,
        startDate: format(new Date(startDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        endDate: format(new Date(endDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      }
    );

    setStatement(result.data);
    setLoading(false);
  };
  // End of Load Data to Datatable

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Statement</h2>
          </div>
          <div className="card-text text-start">
            <div className="overflow-auto custom-background p-3">
              {/* Start of 1st Row */}
              <div className="container ms-0 ps-0">
                <div className="row row-cols-auto">
                  <div className="col">
                    <div className="p-inputgroup me-5">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-users"></i>
                      </span>
                      <Dropdown
                        value={searchPrincipal}
                        options={principalDropdown}
                        optionLabel="code"
                        optionValue="id"
                        placeholder="Principal"
                        className="custom_padding"
                        onChange={(event) =>
                          setSearchPrincipal(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End of 1st Row */}
              {/* Start of 2nd Row */}
              <div className="container ms-0 ps-0">
                <div className="row row-cols-auto mt-3">
                  <div className="d-flex col-12">
                    <Calendar
                      id="icon"
                      showIcon
                      iconPos="left"
                      showButtonBar
                      className="width_12_em"
                      inputClassName="padding-left"
                      clearButtonClassName="d-none"
                      dateFormat="dd/mm/yy"
                      name="startDate"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.value);
                      }}
                    />
                    <span className="d-flex align-self-center px-2">to</span>
                    <Calendar
                      id="icon"
                      showIcon
                      iconPos="left"
                      showButtonBar
                      className="width_12_em"
                      inputClassName="padding-left"
                      clearButtonClassName="d-none"
                      dateFormat="dd/mm/yy"
                      name="endDate"
                      value={endDate}
                      onChange={(e) => {
                        const selectedDate = e.value;
                        if (selectedDate) {
                          selectedDate.setHours(23, 59, 59, 999);
                          setEndDate(selectedDate);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* End of 2nd Row */}
            </div>
            {loading ? (
              <div className="loading-container">
                <ProgressSpinner />
              </div>
            ) : (
              <table className="commission-table">
                <tr className="table-header-row">
                  <th colSpan="2">
                    <b>Total Commission - Motor</b>
                  </th>
                  <td>{numberWithCommas(statement.totalMotorCommission)}</td>
                </tr>
                <tr className="table-header-row">
                  <th colSpan="2">
                    <b>Total Commission - Non-Motor</b>
                  </th>
                  <td>{numberWithCommas(statement.totalNonMotorCommission)}</td>
                </tr>
                <tr className="table-header-row">
                  <th colSpan="2">
                    <b>Total Revenue - Supporting Services</b>
                  </th>
                  <td>{numberWithCommas(statement.totalSupSvcRevenue)}</td>
                </tr>
                <tr className="table-header-row">
                  <th colSpan="2" className="text-end">
                    <b>Total Income:</b>
                  </th>
                  <td>
                    <b>{numberWithCommas(statement.totalIncome)}</b>
                  </td>
                </tr>
                <tr className="table-header-row">
                  <th colSpan="2">
                    <b>Partner - Commission*</b>
                  </th>
                  <td>{numberWithCommas(statement.totalPartnerCommission)}</td>
                </tr>
                <tr className="table-header-row">
                  <th colSpan="2">
                    <b>Partner - Discount*</b>
                  </th>
                  <td>{numberWithCommas(statement.totalPartnerDiscount)}</td>
                </tr>
                <tr className="table-header-row">
                  <th colSpan="2" className="text-end">
                    <b>Net Revenue:</b>
                  </th>
                  <td>
                    <b>{numberWithCommas(statement.totalNetRevenue)}</b>
                  </td>
                </tr>
              </table>
            )}
            * - These fields may not be accurate when used in conjunction with a
            specific principal, but will always accurate when the "ALL"
            principals are shown
          </div>
        </div>
      </div>
    </section>
  );
}
