export const StatusEnum = {
  DRAFT: "DRAFT",
  ISSUED: "ISSUED",
  CLOSED: "CLOSED",
  SUBMITTED: "SUBMITTED",
  CANCELED: "CANCELED",
};

export const PrintNoteEnum = {
  DEBIT_NOTE: "DEBIT_NOTE",
  CREDIT_NOTE: "CREDIT_NOTE",
  CASE_DETAILS: "CASE_DETAILS",
  OFFICIAL_RECEIPT: "OFFICIAL_RECEIPT",
  OFFICIAL_RECEIPT_CUSTOMER: "OFFICIAL_RECEIPT_CUSTOMER",
};

export const BillingStatus = {
  PAID: "PAID",
  PARTIALLY_PAID: "PARTIALLY_PAID",
  NOT_PAID: "NOT_PAID",
};

// Configuration for billing statuses
export const BillingStatusSeverity = {
  PAID: { display: "Paid", severity: "success" },
  PARTIALLY_PAID: { display: "Partially Paid", severity: "primary" },
  NOT_PAID: { display: "Not Paid", severity: "danger" },
  CANCELED: { display: "Canceled", severity: "danger" },
};