import { Calendar } from "primereact/calendar";
import { confirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useParams } from "react-router-dom";
import ClientVehicleInfoContext from "context/ClientVehicleProvider";
import { StatusEnum } from "context/globalEnums";
import { Toast } from "primereact/toast";
import { addDays, format } from "date-fns";

export default function MotorInfo() {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );
  const toast = useRef(null);
  const { id } = useParams();
  const location = useLocation();

  const {
    motorCoverNote,
    motorIssuedDate,
    motorSelectAction,
    motorPeriodStart,
    motorPeriodEnd,
    motorSelectCoverType,
    motorPartnerCommissionRate,
    motorSelectClass,
    motorCc,
    motorYear,
    motorSeating,
    motorPrincipalTariff,
    motorMakeModel,
    motorTypeOfBody,
    motorHPCo,
    motorRemarks,
  } = clientVehicleInfo;

  const prevRef = useRef(null);
  const onInputChange = (e) => {
    if (
      e.target.name === "motorPeriodStart" &&
      e.target.value < clientVehicleInfo.motorIssuedDate
    ) {
      toast.current.show({
        severity: "error",
        summary: "Check Period Date",
        detail: (
          <span>
            <b>Period Date Cannot Before Issue Date</b>
          </span>
        ),
        life: 3000,
      });
    }

    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });
  };

  const onPrincipalChange = (e) => {
    prevRef.current = motorPrincipalTariff;

    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Principal Tariff Changed",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        setClientVehicleInfo({
          ...clientVehicleInfo,
          motorPrincipalTariff: parseFloat(e.target.value).toFixed(2),
        });
      },
      reject: () => {
        setClientVehicleInfo({
          ...clientVehicleInfo,
          motorPrincipalTariff: parseFloat(prevRef.current).toFixed(2),
        });
      },
    });
  };

  useEffect(() => {
    let get_third_party_premium = 0;
    if (motorSelectClass === "PRIVATE CAR") {
      if (motorCc <= 1400) {
        get_third_party_premium = (120.6).toFixed(2);
      } else if (motorCc <= 1650) {
        get_third_party_premium = (135.0).toFixed(2);
      } else if (motorCc <= 2200) {
        get_third_party_premium = (151.2).toFixed(2);
      } else if (motorCc <= 3050) {
        get_third_party_premium = (167.4).toFixed(2);
      } else if (motorCc <= 4100) {
        get_third_party_premium = (181.8).toFixed(2);
      } else if (motorCc <= 4250) {
        get_third_party_premium = (198.2).toFixed(2);
      } else if (motorCc <= 4400) {
        get_third_party_premium = (212.4).toFixed(2);
      } else if (motorCc >= 4400) {
        get_third_party_premium = (226.8).toFixed(2);
      }
    }

    if (motorSelectClass === "MOTORCYCLE") {
      if (motorCc <= 50) {
        get_third_party_premium = (30.6).toFixed(2);
      } else if (motorCc <= 100) {
        get_third_party_premium = (36.6).toFixed(2);
      } else if (motorCc <= 125) {
        get_third_party_premium = (41.3).toFixed(2);
      } else if (motorCc <= 225) {
        get_third_party_premium = (50.7).toFixed(2);
      } else if (motorCc <= 350) {
        get_third_party_premium = (64.3).toFixed(2);
      } else if (motorCc <= 500) {
        get_third_party_premium = (73.6).toFixed(2);
      } else if (motorCc >= 500) {
        get_third_party_premium = (82.6).toFixed(2);
      }
    }

    if (motorSelectCoverType === "THIRD PARTY") {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        premiumBasicPremium: parseFloat(get_third_party_premium).toFixed(2),
        third_party_premium: parseFloat(get_third_party_premium).toFixed(2),
      });
    } else {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        third_party_premium: parseFloat(get_third_party_premium).toFixed(2),
      });
    }
    // eslint-disable-next-line
  }, [motorSelectClass, motorCc, motorSelectCoverType]);

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      nonMotorIssuedDate: motorIssuedDate,
      supSvcIssuedDate: motorIssuedDate,
    });
    // eslint-disable-next-line
  }, [motorIssuedDate]);

  useEffect(() => {
    const get_motorDatepicker2 = new Date(motorPeriodStart);
    get_motorDatepicker2.setHours(0, 0, 0, 0);
    get_motorDatepicker2.setFullYear(get_motorDatepicker2.getFullYear() + 1);
    get_motorDatepicker2.setDate(get_motorDatepicker2.getDate() - 1);

    const updatedMotorDatepicker2 = get_motorDatepicker2;

    setClientVehicleInfo({
      ...clientVehicleInfo,
      motorPeriodEnd: updatedMotorDatepicker2,
      nonMotorPeriodStart: motorPeriodStart,
    });
    // eslint-disable-next-line
  }, [motorPeriodStart]);

  // Dropdown Options Vehicle Class
  const vehicle_class = [
    { name: "PRIVATE CAR", value: "PRIVATE_CAR" },
    { name: "GOODS VEHICLE - C PERMIT", value: "GOODS_VEHICLE_C_PERMIT" },
    { name: "GOODS VEHICLE - A PERMIT", value: "GOODS_VEHICLE_A_PERMIT" },
    { name: "MOTORCYCLE", value: "MOTORCYCLE" },
    { name: "COMMERCIAL MOTORCYCLE", value: "COMMERCIAL_MOTORCYCLE" },
    { name: "MOTORCYCLE WITH SIDECAR", value: "MOTORCYCLE_WITH_SIDECAR" },
    { name: "HIRE CAR", value: "HIRE_CAR" },
    { name: "OMNI FACTORY BUS - PRIVATE", value: "OMNI_FACTORY_BUS_PRIVATE" },
    { name: "OMNI FACTORY BUS - PUBLIC", value: "OMNI_FACTORY_BUS_PUBLIC" },
    { name: "OMNI SCHOOL BUS", value: "OMNI_SCHOOL_BUS" },
    { name: "OMNI PUBLIC BUS", value: "OMNI_PUBLIC_BUS" },
    { name: "TOWN TAXI", value: "TOWN_TAXI" },
    { name: "OUTSTATION TAXI", value: "OUTSTATION_TAXI" },
    { name: "HIRE CAR - HIRER DRIVEN", value: "HIRE_CAR_HIRER_DRIVEN" },
    { name: "SPECIAL TYPE VEHICLE", value: "SPECIAL_TYPE_VEHICLE" },
    { name: "MOTOR TRADE PLATE", value: "MOTOR_TRADE_PLATE" },
  ];

  // Dropdown Options Cover Type
  const cover_type = [
    { name: "COMPREHENSIVE" },
    { name: "THIRD PARTY - FIRE & THEFT" },
    { name: "THIRD PARTY" },
  ];

  // Dropdown Options Action
  const action = [{ name: "NEW" }, { name: "RENEWAL" }, { name: "OTHER" }];

  const handleInputChange = (e) => {
    const value = e.target.value; // User input
    const regex = /^\d{2}\/\d{2}\/\d{4}$/; // Validate dd/mm/yyyy format
    
    if (regex.test(value)) {
      // Parse input manually if required or pass as is
      const [day, month, year] = value.split("/").map(Number);
      const date = new Date(year, month - 1, day);
      onInputChange({ ...e, value: date });
    } else if (e.value instanceof Date) {
      // When PrimeReact passes a Date object
      const formattedDate = format(e.value, "dd/MM/yyyy");
      onInputChange({ ...e, value: formattedDate });
    } else {
      console.error("Invalid date format. Please use dd/mm/yyyy.");
    }
  };

  return (
    <div className="overflow-auto mt-4">
      {prevRef.current}
      <Toast ref={toast} />
      <table className="motor_Table">
        <tbody>
          <tr>
            <td>Cover Note No.</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="motorCoverNote"
                value={motorCoverNote ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>Issue Date</td>
            <td>
              <Calendar
                id="icon"
                name="motorIssuedDate"
                value={new Date(motorIssuedDate)}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => handleInputChange(e)}
                showIcon
                showButtonBar
                className="width_full"
                inputClassName="padding-left"
                clearButtonClassName="d-none"
                dateFormat="dd/mm/yy"
              />
            </td>
            <td>Action</td>
            <td>
              <Dropdown
                name="motorSelectAction"
                value={motorSelectAction}
                options={action}
                optionLabel="name"
                optionValue="name"
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                placeholder="Select Action"
                className="form-control"
              />
            </td>
          </tr>
          <tr>
            <td>Period From</td>
            <td>
              <div className="d-flex">
                <Calendar
                  id="icon"
                  name="motorPeriodStart"
                  value={new Date(motorPeriodStart)}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => handleInputChange(e)}
                  showIcon
                  showButtonBar
                  className="width_full"
                  inputClassName="padding-left"
                  clearButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  minDate={clientVehicleInfo.motorIssuedDate}
                />
                <span className="d-flex align-self-center px-2">to</span>
                <Calendar
                  id="icon"
                  name="motorPeriodEnd"
                  value={new Date(motorPeriodEnd)}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => handleInputChange(e)}
                  showIcon
                  showButtonBar
                  className="width_full"
                  inputClassName="padding-left"
                  clearButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  minDate={addDays(clientVehicleInfo.motorPeriodStart, 1)}
                />
              </div>
            </td>
            <td>Cover Type</td>
            <td>
              <Dropdown
                name="motorSelectCoverType"
                value={motorSelectCoverType}
                options={cover_type}
                optionLabel="name"
                optionValue="name"
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                placeholder="Select Cover Type"
                className="form-control"
              />
            </td>
            <td>Partner Commission</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control text_background_blue"
                  name="motorPartnerCommissionRate"
                  value={motorPartnerCommissionRate ?? ""}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center px-2">%</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Class</td>
            <td>
              <Dropdown
                name="motorSelectClass"
                value={motorSelectClass}
                options={vehicle_class}
                optionLabel="name"
                optionValue="name"
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                placeholder="Select Class"
                className="form-control"
              />
            </td>
            <td>CC / KG</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  className="form-control input_percentage"
                  name="motorCc"
                  value={motorCc ?? ""}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center px-2">Year</span>
                <input
                  type="number"
                  min="0"
                  className="form-control input_percentage"
                  name="motorYear"
                  value={motorYear ?? ""}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center px-2">Seating</span>
                <input
                  type="number"
                  min="0"
                  className="form-control input_percentage"
                  name="motorSeating"
                  value={motorSeating ?? ""}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </td>
            <td>Principal Tariff</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control text_background_blue"
                  name="motorPrincipalTariff"
                  value={motorPrincipalTariff ?? ""}
                  onChange={(e) => {
                    onInputChange(e);
                    onPrincipalChange(e);
                  }}
                />
                <span className="align-self-center px-2">%</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Make/Model</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="motorMakeModel"
                value={motorMakeModel ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>Type of Body</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="motorTypeOfBody"
                value={motorTypeOfBody ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>HP Co.</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="motorHPCo"
                value={motorHPCo ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>
          <tr>
            <td>Remarks</td>
            <td colSpan="5">
              <textarea
                className="form-control"
                name="motorRemarks"
                value={motorRemarks ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
