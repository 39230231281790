import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ClientVehicleInfoContext from "context/ClientVehicleProvider";
import { StatusEnum } from "context/globalEnums";
export default function NonMotorInfo() {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const { id } = useParams();
  const location = useLocation();

  const {
    nonMotorCoverNote,
    nonMotorIssuedDate,
    nonMotorSelectAction,
    nonMotorPeriodStart,
    nonMotorPeriodEnd,
    nonMotorPrincipalTariff,
    nonMotorClassOfIns,
    nonMotorPartnerCommission,
    nonMotorDescription,
    nonMotorRmPerRoom,
    nonMotorSumInsuredRM,
    nonMotorBasicPremium,
    nonMotorGovtTax,
    nonMotorTotal,
    nonMotorRemarks,
    nonMotorStampDuty,
    nonMotorOtherCharges,
    nonMotorTotalPremium,
    nonMotorPrincipalCommission,
  } = clientVehicleInfo;

  const onInputChange = (e) => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const get_motorDatepicker5 = new Date(nonMotorPeriodStart);
    get_motorDatepicker5.setHours(0, 0, 0, 0);
    get_motorDatepicker5.setFullYear(get_motorDatepicker5.getFullYear() + 1);
    get_motorDatepicker5.setDate(get_motorDatepicker5.getDate() - 1);

    const updatedMotorDatepicker5 = get_motorDatepicker5;

    setClientVehicleInfo({
      ...clientVehicleInfo,
      nonMotorPeriodEnd: updatedMotorDatepicker5,
    });
    // eslint-disable-next-line
  }, [nonMotorPeriodStart]);

  const [principal_commission, setPrincipal_commission] = useState(0);
  const [govt_total, setGovt_total] = useState(0);

  useEffect(() => {
    setGovt_total(
      parseFloat(nonMotorBasicPremium * (nonMotorGovtTax / 100)).toFixed(2)
    );

    setPrincipal_commission(
      (
        parseFloat(nonMotorBasicPremium) *
        parseFloat(nonMotorPrincipalTariff / 100)
      ).toFixed(2)
    );
    // eslint-disable-next-line
  }, [nonMotorBasicPremium, nonMotorGovtTax, nonMotorPrincipalTariff]);

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      nonMotorPrincipalCommission: principal_commission,
      nonMotorTotal: govt_total,

      nonMotorTotalPremium: (
        parseFloat(nonMotorBasicPremium) +
        parseFloat(govt_total) +
        parseFloat(nonMotorStampDuty) +
        parseFloat(nonMotorOtherCharges)
      ).toFixed(2),

      nonMotorGrossPremium: (
        parseFloat(nonMotorBasicPremium) +
        parseFloat(govt_total) +
        parseFloat(nonMotorStampDuty) +
        parseFloat(nonMotorOtherCharges) -
        parseFloat(principal_commission)
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [
    govt_total,
    principal_commission,
    nonMotorStampDuty,
    nonMotorOtherCharges,
    nonMotorPrincipalCommission,
  ]);

  // Dropdown Options Action
  const action = [{ name: "NEW" }, { name: "RENEWAL" }, { name: "OTHER" }];

  return (
    <div className="overflow-auto mt-4">
      <table className="cover_Table">
        <tbody>
          <tr>
            <td>Cover Note No.</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="nonMotorCoverNote"
                value={nonMotorCoverNote ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td className="text-end">Issue Date</td>
            <td>
              <Calendar
                id="icon"
                name="nonMotorIssuedDate"
                value={new Date(nonMotorIssuedDate)}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                showIcon
                showButtonBar
                className="width_full"
                inputClassName="padding-left"
                clearButtonClassName="d-none"
                dateFormat="dd/mm/yy"
              />
            </td>
            <td className="text-end">Action</td>
            <td>
              <Dropdown
                name="nonMotorSelectAction"
                value={nonMotorSelectAction}
                options={action}
                optionLabel="name"
                optionValue="name"
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                placeholder="Select Action"
                className="form-control"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table className="non_motor_Table" id="form2">
        <tbody>
          <tr>
            <td>Period of Insurance</td>
            <td>
              <div className="d-flex">
                <Calendar
                  id="icon"
                  name="nonMotorPeriodStart"
                  value={new Date(nonMotorPeriodStart)}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                  showIcon
                  showButtonBar
                  className="width_full"
                  inputClassName="padding-left"
                  clearButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                />
                <span className="d-flex align-self-center px-2">to</span>
                <Calendar
                  id="icon"
                  name="nonMotorPeriodEnd"
                  value={new Date(nonMotorPeriodEnd)}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                  showIcon
                  showButtonBar
                  className="width_full"
                  inputClassName="padding-left"
                  clearButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                />
              </div>
            </td>
            <td>Partner Commission</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em text_background_blue"
                  name="nonMotorPartnerCommission"
                  value={nonMotorPartnerCommission ?? "0.00"}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2">%</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Class of Ins.</td>
            <td className="table_custom_column_2">
              <input
                type="text"
                className="form-control"
                name="nonMotorClassOfIns"
                value={nonMotorClassOfIns ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>Principal Tariff</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em text_background_blue"
                  name="nonMotorPrincipalTariff"
                  value={nonMotorPrincipalTariff ?? "0.00"}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2">%</span>
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan="5" className="align-top">
              Description
            </td>
            <td rowSpan="5">
              <textarea
                className="form-control height_15_em"
                name="nonMotorDescription"
                value={nonMotorDescription ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>RM Per Room</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="nonMotorRmPerRoom"
                  value={nonMotorRmPerRoom ?? "0.00"}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Sum Insured RM</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="nonMotorSumInsuredRM"
                  value={nonMotorSumInsuredRM ?? "0.00"}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Basic Premium</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="nonMotorBasicPremium"
                  value={nonMotorBasicPremium ?? "0.00"}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Govt. Tax</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="nonMotorGovtTax"
                  value={nonMotorGovtTax ?? "0.00"}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2">%</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Govt. Tax Total</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  className="form-control width_12_em text_background_green"
                  name="nonMotorTotal"
                  value={nonMotorTotal ?? ""}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                  readOnly
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan="3" className="align-top">
              Remarks
            </td>
            <td rowSpan="3">
              <textarea
                className="form-control height_8_em"
                name="nonMotorRemarks"
                value={nonMotorRemarks ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>Stamp Duty</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="nonMotorStampDuty"
                  value={nonMotorStampDuty ?? "0.00"}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Other Charges</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="nonMotorOtherCharges"
                  value={nonMotorOtherCharges ?? "0.00"}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Total Premium</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  className="form-control width_12_em text_background_green"
                  name="nonMotorTotalPremium"
                  value={nonMotorTotalPremium ?? ""}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                  readOnly
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
