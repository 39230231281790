import { Calendar } from "primereact/calendar";
import React, { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ClientVehicleInfoContext from "context/ClientVehicleProvider";
import { StatusEnum } from "context/globalEnums";

export default function SupSvcInfo() {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const { id } = useParams();
  const location = useLocation();

  const { supSvcNote, status } = clientVehicleInfo;

  const {
    supSvcIssuedDate,
    supSvcRoadTaxMonths,
    supSvcRoadTaxCharges,
    supSvcRoadTaxJpjRunner,
    supSvcRoadTaxRevenue,
    supSvcLicenseYears,
    supSvcLicenseCharges,
    supSvcLicenseJpjRunner,
    supSvcLicenseRevenue,
    supSvcJpjRenewal,
    supSvcJpjRenewalCharges,
    supSvcJpjRenewalJpjRunner,
    supSvcJpjRenewalRevenue,
    supSvcOthers1,
    supSvcOthers1Charges,
    supSvcOthers1JpjRunner,
    supSvcOthers1Revenue,
    supSvcOthers2,
    supSvcOthers2Charges,
    supSvcOthers2JpjRunner,
    supSvcOthers2Revenue,
    supSvcOthers3,
    supSvcOthers3Charges,
    supSvcOthers3JpjRunner,
    supSvcOthers3Revenue,
    supSvcTotalCharges,
    supSvcTotalJpjRunner,
    supSvcTotalRevenue,
  } = clientVehicleInfo.supSvc || {};

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setClientVehicleInfo((prevInfo) => {
      const updatedSupSvc = {
        ...prevInfo.supSvc,
        [name]: value,
        ...(name === "supSvcRoadTaxCharges" && {
          supSvcRoadTaxJpjRunner: value,
        }),
      };
      return { ...prevInfo, supSvc: updatedSupSvc };
    });
  };

  useEffect(() => {
    setClientVehicleInfo((prevInfo) => {
      const supSvc = prevInfo.supSvc || {};
      const parseOrDefault = (key) => parseFloat(supSvc[key] || 0).toFixed(2);

      const calculateRevenue = (charges, runner) =>
        (
          parseFloat(supSvc[charges] || 0) - parseFloat(supSvc[runner] || 0)
        ).toFixed(2);

      const newSupSvc = {
        ...supSvc,
        supSvcRoadTaxMonths: supSvcRoadTaxMonths ?? 12,
        supSvcLicenseYears: supSvcLicenseYears ?? 1,
        supSvcJpjRenewal: supSvcJpjRenewal ?? "ROAD TAX RENEWAL CHARGE",

        supSvcRoadTaxRevenue: calculateRevenue(
          "supSvcRoadTaxCharges",
          "supSvcRoadTaxJpjRunner"
        ),
        supSvcLicenseRevenue: calculateRevenue(
          "supSvcLicenseCharges",
          "supSvcLicenseJpjRunner"
        ),
        supSvcJpjRenewalRevenue: calculateRevenue(
          "supSvcJpjRenewalCharges",
          "supSvcJpjRenewalJpjRunner"
        ),
        supSvcOthers1Revenue: calculateRevenue(
          "supSvcOthers1Charges",
          "supSvcOthers1JpjRunner"
        ),
        supSvcOthers2Revenue: calculateRevenue(
          "supSvcOthers2Charges",
          "supSvcOthers2JpjRunner"
        ),
        supSvcOthers3Revenue: calculateRevenue(
          "supSvcOthers3Charges",
          "supSvcOthers3JpjRunner"
        ),
        supSvcTotalCharges: [
          "supSvcRoadTaxCharges",
          "supSvcLicenseCharges",
          "supSvcJpjRenewalCharges",
          "supSvcOthers1Charges",
          "supSvcOthers2Charges",
          "supSvcOthers3Charges",
        ]
          .reduce((acc, key) => acc + parseFloat(supSvc[key] || 0), 0)
          .toFixed(2),
        supSvcTotalJpjRunner: [
          "supSvcRoadTaxJpjRunner",
          "supSvcLicenseJpjRunner",
          "supSvcJpjRenewalJpjRunner",
          "supSvcOthers1JpjRunner",
          "supSvcOthers2JpjRunner",
          "supSvcOthers3JpjRunner",
        ]
          .reduce((acc, key) => acc + parseFloat(supSvc[key] || 0), 0)
          .toFixed(2),
        supSvcTotalRevenue: (
          [
            "supSvcRoadTaxCharges",
            "supSvcLicenseCharges",
            "supSvcJpjRenewalCharges",
            "supSvcOthers1Charges",
            "supSvcOthers2Charges",
            "supSvcOthers3Charges",
          ].reduce((acc, key) => acc + parseFloat(supSvc[key] || 0), 0) -
          [
            "supSvcRoadTaxJpjRunner",
            "supSvcLicenseJpjRunner",
            "supSvcJpjRenewalJpjRunner",
            "supSvcOthers1JpjRunner",
            "supSvcOthers2JpjRunner",
            "supSvcOthers3JpjRunner",
          ].reduce((acc, key) => acc + parseFloat(supSvc[key] || 0), 0)
        ).toFixed(2),
      };

      return {
        ...prevInfo,
        supSvc: newSupSvc,
        premiumRoadTaxTotal: parseOrDefault("supSvcRoadTaxCharges"),
        premiumJpjRenewal: parseOrDefault("supSvcJpjRenewalCharges"),
        premiumLicenseTotal: parseOrDefault("supSvcLicenseCharges"),
        premiumOthersTotal: (
          parseFloat(supSvc.supSvcOthers1Charges || 0) +
          parseFloat(supSvc.supSvcOthers2Charges || 0) +
          parseFloat(supSvc.supSvcOthers3Charges || 0)
        ).toFixed(2),
        premiumTotalSupportingServices: (
          parseFloat(supSvc.supSvcRoadTaxCharges || 0) +
          parseFloat(supSvc.supSvcLicenseCharges || 0) +
          parseFloat(supSvc.supSvcJpjRenewalCharges || 0) +
          parseFloat(supSvc.supSvcOthers1Charges || 0) +
          parseFloat(supSvc.supSvcOthers2Charges || 0) +
          parseFloat(supSvc.supSvcOthers3Charges || 0)
        ).toFixed(2),
      };
    });
    // eslint-disable-next-line
  }, [
    clientVehicleInfo.supSvc?.supSvcRoadTaxCharges,
    clientVehicleInfo.supSvc?.supSvcRoadTaxJpjRunner,
    clientVehicleInfo.supSvc?.supSvcLicenseCharges,
    clientVehicleInfo.supSvc?.supSvcLicenseJpjRunner,
    clientVehicleInfo.supSvc?.supSvcJpjRenewalCharges,
    clientVehicleInfo.supSvc?.supSvcJpjRenewalJpjRunner,
    clientVehicleInfo.supSvc?.supSvcOthers1Charges,
    clientVehicleInfo.supSvc?.supSvcOthers1JpjRunner,
    clientVehicleInfo.supSvc?.supSvcOthers2Charges,
    clientVehicleInfo.supSvc?.supSvcOthers2JpjRunner,
    clientVehicleInfo.supSvc?.supSvcOthers3Charges,
    clientVehicleInfo.supSvc?.supSvcOthers3JpjRunner,
  ]);

  return (
    <div className="overflow-auto mt-4">
      <table className="w-50">
        <tbody>
          {supSvcNote !== undefined &&
            supSvcNote !== null &&
            status !== StatusEnum.DRAFT && (
              <>
                <tr>
                  <td className="w-8">SSN No.</td>
                  <td>
                    <input
                      type="text"
                      className="form-control width_12_em"
                      name="supSvcRoadTaxMonths"
                      value={supSvcNote}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td className="w-8">Issue Date</td>
                  <td>
                    <Calendar
                      id="icon"
                      name="supSvcIssuedDate"
                      value={new Date(supSvcIssuedDate ?? new Date())}
                      disabled={
                        clientVehicleInfo.status !== StatusEnum.DRAFT &&
                        location.pathname === `/eias/enquires/${id}/view`
                      }
                      onChange={(e) => onInputChange(e)}
                      showIcon
                      showButtonBar
                      className="width_12_em"
                      inputClassName="padding-left"
                      clearButtonClassName="d-none"
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                </tr>
              </>
            )}
        </tbody>
      </table>
      <table className="sup_svc_Table">
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td>Charges (RM)</td>
            <td></td>
            <td>JPJ/ Runner (RM)</td>
            <td></td>
            <td>Revenue (RM)</td>
          </tr>
          <tr>
            <td className="w-8">Road Tax for</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  className="form-control width_12_em"
                  name="supSvcRoadTaxMonths"
                  value={supSvcRoadTaxMonths ?? "12"}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2">Month(s)</span>
              </div>
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcRoadTaxCharges"
                value={supSvcRoadTaxCharges ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcRoadTaxJpjRunner"
                value={supSvcRoadTaxJpjRunner ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="supSvcRoadTaxRevenue"
                value={supSvcRoadTaxRevenue ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>

          <tr>
            <td>License for</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  className="form-control width_12_em"
                  name="supSvcLicenseYears"
                  value={supSvcLicenseYears ?? "1"}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2">Year(s)</span>
              </div>
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcLicenseCharges"
                value={supSvcLicenseCharges ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcLicenseJpjRunner"
                value={supSvcLicenseJpjRunner ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="supSvcLicenseRevenue"
                value={supSvcLicenseRevenue ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>

          <tr>
            <td>JPJ Renewal</td>
            <td>
              <input
                type="text"
                className="form-control text_background_green"
                name="supSvcJpjRenewal"
                value={supSvcJpjRenewal ?? "ROAD TAX RENEWAL CHARGE"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcJpjRenewalCharges"
                value={supSvcJpjRenewalCharges ?? "20.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcJpjRenewalJpjRunner"
                value={supSvcJpjRenewalJpjRunner ?? "10.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="supSvcJpjRenewalRevenue"
                value={supSvcJpjRenewalRevenue ?? "10.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td>Others 1</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="supSvcOthers1"
                value={supSvcOthers1 ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcOthers1Charges"
                value={supSvcOthers1Charges ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcOthers1JpjRunner"
                value={supSvcOthers1JpjRunner ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="supSvcOthers1Revenue"
                value={supSvcOthers1Revenue ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>

          <tr>
            <td>Others 2</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="supSvcOthers2"
                value={supSvcOthers2 ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcOthers2Charges"
                value={supSvcOthers2Charges ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcOthers2JpjRunner"
                value={supSvcOthers2JpjRunner ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="supSvcOthers2Revenue"
                value={supSvcOthers2Revenue ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>

          <tr>
            <td>Others 3</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="supSvcOthers3"
                value={supSvcOthers3 ?? ""}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcOthers3Charges"
                value={supSvcOthers3Charges ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="supSvcOthers3JpjRunner"
                value={supSvcOthers3JpjRunner ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="supSvcOthers3Revenue"
                value={supSvcOthers3Revenue ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>

          <tr>
            <td></td>
            <td className="text_align_right">TOTAL</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="supSvcTotalCharges"
                value={supSvcTotalCharges ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="supSvcTotalJpjRunner"
                value={supSvcTotalJpjRunner ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="supSvcTotalRevenue"
                value={supSvcTotalRevenue ?? "0.00"}
                disabled={
                  clientVehicleInfo.status !== StatusEnum.DRAFT &&
                  location.pathname === `/eias/enquires/${id}/view`
                }
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
