import { PrintNoteEnum } from "context/globalEnums";
import useAxiosPrivate from "context/useAxiosPrivate";
import { format } from "date-fns";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Enquires/ExistingEnquires/datatable.css";

export default function CreditNote() {
  const axiosPrivate = useAxiosPrivate();

  // Start of datatable props
  const [creditNote, setCreditNote] = useState([]);
  const dt = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [creditNoteTotal, setCreditNoteTotal] = useState(0);

  const [lazyState, setlazyState] = useState({
    sortField: "motorIssuedDate",
    sortOrder: 1,
  });
  // End of datatable props

  // Start of Search filter Value
  const [searchPartner, setSearchPartner] = useState("");
  const [searchCreditNote, setSearchCreditNote] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchVehicleNo, setSearchVehicleNo] = useState("");
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
  );
  // End of Search filter Value

  // Start of Dropdown Options
  const [bizPartnerDropdown, setBizPartnerDropdown] = useState([]);

  useEffect(() => {
    loadBizPartner();
    // eslint-disable-next-line
  }, []);

  const loadBizPartner = async (e) => {
    const result_Biz = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/biz_partner/names`
    );
    setBizPartnerDropdown(result_Biz.data);
  };
  // End of Dropdown Options

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Start of onSort -> set value and retrigger get method
  const onSort = (event) => {
    setlazyState(event);
  };
  // End of onSort -> set value and retrigger get method

  // Start of Load Data to Datatable with filter search
  useEffect(() => {
    loadReport();
    // eslint-disable-next-line
  }, [
    first,
    rows,
    lazyState,
    searchName,
    searchPartner,
    searchVehicleNo,
    searchCreditNote,
    startDate,
    endDate,
  ]);

  const loadReport = async () => {
    setLoading(true);

    const sortOrder = lazyState.sortOrder === -1 ? "desc" : "asc";

    const result = await axiosPrivate.post(
      `${process.env.REACT_APP_API_URL}/report/credit_note/search`,
      {
        name: searchName ? searchName.trim() : null,
        vehicleNo: searchVehicleNo ? searchVehicleNo.trim() : null,
        bizPartnerId: searchPartner ? searchPartner : null,
        creditNote: searchCreditNote ? searchCreditNote.trim() : null,
        startDate: format(new Date(startDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        endDate: format(new Date(endDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      },
      {
        params: {
          page: page,
          size: rows,
          sort: lazyState.sortField + "," + sortOrder,
        },
      }
    );

    let resultData = result.data.content;
    let getDiscount = 0.0;

    resultData.forEach((item) => {
      getDiscount += parseFloat(item.discount);
    });

    setCreditNoteTotal(numberWithCommas(getDiscount));
    setCreditNote(result.data.content);
    setTotalRecords(result.data.totalElements);
    setLoading(false);
  };
  // End of Load Data to Datatable with filter search

  // Start of Datatable Options
  // -- Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // -- Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  // -- Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  // -- Datatable Header
  const header = (
    <div className="overflow-auto">
      {/* Start of 1st Row */}
      <div className="container ms-0 ps-0">
        <div className="row row-cols-auto">
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-users"></i>
              </span>
              <Dropdown
                value={searchPartner}
                options={bizPartnerDropdown}
                optionLabel="code"
                optionValue="id"
                placeholder="Partner"
                className="custom_padding"
                onChange={(event) => setSearchPartner(event.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-file-pdf"></i>
              </span>
              <InputText
                placeholder="Credit Notes CN"
                value={searchCreditNote}
                onChange={(event) => setSearchCreditNote(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* End of 1st Row */}
      {/* Start of 2nd Row */}
      <div className="container ms-0 ps-0 mt-3">
        <div className="row row-cols-auto">
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-user"></i>
              </span>
              <InputText
                placeholder="Name"
                value={searchName}
                onChange={(event) => setSearchName(event.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-car"></i>
              </span>
              <InputText
                placeholder="Vehicle No"
                value={searchVehicleNo}
                onChange={(event) => setSearchVehicleNo(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* End of 2nd Row */}
      {/* Start of 3rd Row */}
      <div className="container ms-0 ps-0">
        <div className="row row-cols-auto mt-3">
          <div className="d-flex col-12">
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="startDate"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.value);
              }}
            />
            <span className="d-flex align-self-center px-2">to</span>
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="endDate"
              value={endDate}
              onChange={(e) => {
                const selectedDate = e.value;
                if (selectedDate) {
                  selectedDate.setHours(23, 59, 59, 999);
                  setEndDate(selectedDate);
                }
              }}
            />
          </div>
        </div>
      </div>
      {/* End of 3rd Row */}
      {/* Start of 4th Row */}
      <div className="table-header mt-3">
        <span></span>
        <div>
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button w-8"
            onClick={exportCSV}
          />
        </div>
      </div>
      {/* End of 4th Row */}
    </div>
  );

  // -- Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={6}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={creditNoteTotal} />
      </Row>
    </ColumnGroup>
  );

  // - Start of Datatable custom body template for
  const cnBody = (rowData) => {
    return (
      <React.Fragment>
        <Link onClick={() => printNote(PrintNoteEnum.CREDIT_NOTE, rowData.id)}>
          {rowData.note}
        </Link>
      </React.Fragment>
    );
  };

  const coverNoteBody = (rowData) => {
    return (
      <React.Fragment>
        <Link to={`/eias/enquires/${rowData.id}/view`} target={"_blank"}>
          {rowData.motorCoverNote}
        </Link>
      </React.Fragment>
    );
  };

  // End of Datatable Options

  const printNote = async (printType, id) => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_API_URL}/print/${id}`,
        {
          params: {
            printType: printType,
          },
          responseType: "blob",
        }
      );

      if (response.data) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        window.open(url, "_blank");
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
    setLoading(false);
  };

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Credit Notes</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={creditNote}
              loading={loading}
              lazy={true}
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              header={header}
              onPage={onCustomPage}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              columnResizeMode="fit"
              showGridlines
              size="small"
              footerColumnGroup={footerGroup}
            >
              <Column
                field="issuedAt"
                header="Date"
                sortable
                body={(data) =>
                  format(new Date(data.issuedAt), "dd/MM/yyyy") ?? "-"
                }
              ></Column>
              <Column field="bizPartnerCode" header="Partner" sortable></Column>
              <Column field="cn" header="CN No." body={cnBody}></Column>
              <Column field="name" header="Name (NRIC)" sortable></Column>
              <Column field="vehicleNo" header="Vehicle No." sortable></Column>
              <Column
                field="motorCoverNote"
                header="Cover No."
                body={coverNoteBody}
              ></Column>
              <Column
                field="cn_amount"
                header="CN Amount"
                style={{ textAlign: "right" }}
                body={(data) => numberWithCommas(data.discount) ?? "-"}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </section>
  );
}
