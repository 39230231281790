import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressBook,
  faBriefcase,
  faCar,
  faDatabase,
  faDownload,
  faEnvelope,
  faFileLines,
  faHouseChimney,
  faSignOut,
  faSquareXmark,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import "App.css";
import PersistLogin from "context/PersistLogin";
import RequireAuth from "context/RequireAuth";
import Unauthorized from "page/Error_Page/Unauthorized";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import NavbarLayout from "page/layout/NavbarLayout";
import Login from "page/Login/Login";
import ExistingClientVehicle from "page/MYEIAS/Enquires/ExistingEnquires/ExistingClientVehicle";
import NewClientVehicle from "page/MYEIAS/Enquires/NewClientVehicle/NewClientVehicle";
import Payment from "page/MYEIAS/Enquires/NewClientVehicle/Payment";
import PrintCaseDetail from "page/MYEIAS/Enquires/NewClientVehicle/PrintCaseDetail";
import PrintCollectionByBizPartner from "page/MYEIAS/Enquires/NewClientVehicle/PrintCollectionByBizPartner";
import PrintOR from "page/MYEIAS/Enquires/NewClientVehicle/PrintOR";
import Home from "page/MYEIAS/Home";
import CollectionByBizPartner from "page/MYEIAS/Reports/CollectionByBizPartner";
import CollectionByBizPartnerView from "page/MYEIAS/Reports/CollectionByBizPartnerView";
import CreditNote from "page/MYEIAS/Reports/CreditNote";
import PrintSubmissionReceipts from "page/MYEIAS/Reports/PrintSubmissionReceipts";
import ProductionMotor from "page/MYEIAS/Reports/ProductionMotor";
import ProductionNonMotor from "page/MYEIAS/Reports/ProductionNonMotor";
import RoadTaxByType from "page/MYEIAS/Reports/RoadTaxByType";
import SavedDraft from "page/MYEIAS/Reports/SavedDraft";
import Statement from "page/MYEIAS/Reports/Statement";
import SubmissionReceipts from "page/MYEIAS/Reports/SubmissionReceipts";
import SubmissionReceiptsView from "page/MYEIAS/Reports/SubmissionReceiptsView";
import SupportingServices from "page/MYEIAS/Reports/SupportingServices";
import Settings from "page/MYEIAS/Settings";
import BusinessPartners from "page/MYEIAS/Settings/BusinessPartners";
import Client from "page/MYEIAS/Settings/Client";
import CustRecord from "page/MYEIAS/Settings/CustRecord";
import Principal from "page/MYEIAS/Settings/Principal";
import PrincipalPayment from "page/MYEIAS/Settings/PrincipalPayment";
import Vehicle from "page/MYEIAS/Settings/Vehicle";
import { Route, Routes } from "react-router-dom";
import DebitNote from "page/MYEIAS/Reports/DebitNote";
import QuickQuotation from "page/MYEIAS/Enquires/NewClientVehicle/QuickQuotation";

library.add(
  faCar,
  faAddressBook,
  faDatabase,
  faHouseChimney,
  faBriefcase,
  faEnvelope,
  faFileLines,
  faTools,
  faSignOut,
  faDownload,
  faSquareXmark
);

const ROLES = {
  User: "ROLE_USER",
  Admin: "ADMIN",
};

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<PersistLogin />}>
          <Route
            element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User]} />}
          >
            <Route path="/" element={<NavbarLayout />}>
              <Route path="/unauthorized" element={<Unauthorized />} />

              <Route
                exact
                path="/eias/enquires/create"
                element={<NewClientVehicle></NewClientVehicle>}
              ></Route>

              <Route exact path="/eias/home" element={<Home></Home>}></Route>
              <Route
                exact
                path="/settings"
                element={<Settings></Settings>}
              ></Route>

              <Route
                exact
                path="/eias/enquires/:id/view"
                element={<NewClientVehicle></NewClientVehicle>}
              ></Route>

              <Route
                exact
                path="/eias/enquires/:id/new"
                element={<NewClientVehicle></NewClientVehicle>}
              ></Route>

              <Route
                exact
                path="/eias/enquires/existing_client_vehicle"
                element={<ExistingClientVehicle />}
              ></Route>

              <Route
                exact
                path="/eias/enquires/payment/:id/bizPartner/:bizPartnerId/:dateTime"
                element={<Payment />}
              ></Route>

              <Route
                exact
                path="/eias/enquires/payment/bizPartner/:bizPartnerId/:dateTime"
                element={<Payment />}
              ></Route>

              <Route
                exact
                path="/eias/enquires/quick_quotation"
                element={<QuickQuotation />}
              ></Route>

              {/* Settings Pages */}
              <Route
                exact
                path="/eias/settings/vehicle"
                element={<Vehicle />}
              ></Route>

              <Route
                exact
                path="/eias/settings/business_partners_view"
                element={<BusinessPartners />}
              ></Route>

              <Route
                exact
                path="/eias/settings/principals_view"
                element={<Principal />}
              ></Route>

              <Route
                exact
                path="/eias/settings/principal_payment/:id"
                element={<PrincipalPayment />}
              ></Route>

              <Route
                exact
                path="/eias/settings/clients"
                element={<Client />}
              ></Route>

              <Route
                exact
                path="/eias/settings/cust_record"
                element={<CustRecord />}
              ></Route>
              {/* End of Settings Pages */}

              {/* Report Pages */}
              <Route
                exact
                path="/eias/reports/saved_draft/"
                element={<SavedDraft />}
              ></Route>

              <Route
                exact
                path="/eias/reports/road_tax_by_type/"
                element={<RoadTaxByType />}
              ></Route>

              <Route
                exact
                path="/eias/reports/production_motor/"
                element={<ProductionMotor />}
              ></Route>

              <Route
                exact
                path="/eias/reports/production_non_motor/"
                element={<ProductionNonMotor />}
              ></Route>

              <Route
                exact
                path="/eias/reports/supporting_services/"
                element={<SupportingServices />}
              ></Route>

              <Route
                exact
                path="/eias/reports/submission_receipts"
                element={<SubmissionReceipts />}
              ></Route>

              <Route
                exact
                path="/eias/reports/submission_receipts_view/:id"
                element={<SubmissionReceiptsView />}
              ></Route>

              <Route
                exact
                path="/eias/reports/collection_by_business_partner"
                element={<CollectionByBizPartner />}
              ></Route>

              <Route
                exact
                path="/eias/reports/collection_by_business_partner_view/:id"
                element={<CollectionByBizPartnerView />}
              ></Route>

              <Route
                exact
                path="/eias/reports/debit_notes"
                element={<DebitNote />}
              ></Route>

              <Route
                exact
                path="/eias/reports/credit_notes"
                element={<CreditNote />}
              ></Route>

              <Route
                exact
                path="/eias/reports/statement"
                element={<Statement />}
              ></Route>
              {/* End of Report Pages */}
            </Route>
          </Route>
        </Route>

        {/* No Navbar */}
        <Route exact path="/login" element={<Login></Login>}></Route>
        <Route
          exact
          path="/eias/enquires/printORC/:id"
          element={<PrintOR />}
        ></Route>
        <Route
          exact
          path="/eias/reports/printORP/:id"
          element={<PrintCollectionByBizPartner />}
        ></Route>
        <Route
          exact
          path="/eias/enquires/printCaseDetail/:id"
          element={<PrintCaseDetail></PrintCaseDetail>}
        ></Route>
        <Route
          exact
          path="/eias/reports/printS/:id"
          element={<PrintSubmissionReceipts />}
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
