import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "api/axios";
import useAuth from "context/useAuth";
import "./Login.css";
import Cookies from "js-cookie";
import useLogout from "context/useLogout";

export default function Login() {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();
  localStorage.clear();
  Cookies.remove();
  const logout = useLogout();

  const [user, setUser] = useState({
    company_code: "",
    name: "",
    password: "",
  });

  const [errMsg, setErrMsg] = useState("");

  const { company_code, name, password } = user;

  const onInputChange = (e) => {
    // Standard way to update nested objects (immutable update)
    setUser((prevUser) => ({
      ...prevUser,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    userRef.current.focus();
    setAuth({});
    localStorage.clear();
    logout();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [name, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/authenticate`,
        { name, password },
        { withCredentials: true }
      );
      const accessToken = response?.data?.accessToken;
      const refreshToken = response?.data?.refreshToken;
      const roles = response?.data?.roles;

      setAuth({ name, roles, accessToken, refreshToken });
      setUser({ company_code: "", name: "", password: "" });
      navigate("/eias/home");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <section className="pt-5 pb-5 background-radial-gradient">
      <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
        <div className="row gx-lg-5 align-items-center mb-5">
          <div className="col-lg-6 mb-5 mb-lg-0 div_z-index">
            <h1 className="my-5 display-5 fw-bold ls-tight h1_color">
              The best offer <br />
              <span className="span_color">for your business</span>
            </h1>
            <p className="mb-4 opacity-70 p_color">
              Copyright © MyEIAS 2021
              <br></br>Privacy Policy · Terms & Conditions
            </p>
          </div>

          <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
            <div
              id="radius-shape-1"
              className="position-absolute rounded-circle shadow-5-strong"
            ></div>
            <div
              id="radius-shape-2"
              className="position-absolute shadow-5-strong"
            ></div>

            <div className="card bg-glass">
              <div className="card-body px-4 py-5 px-md-5">
                <h1 className="text-center mb-4">Login</h1>
                <p
                  className="text-center mb-4 fw-bold text-danger"
                  ref={errRef}
                >
                  {errMsg}
                </p>
                <div className="box">
                  <form onSubmit={handleSubmit}>
                    <div className="inputBox">
                      <input
                        type="text"
                        name="company_code"
                        value={company_code}
                        onChange={onInputChange}
                      />
                      <label>Company Code</label>
                    </div>
                    <div className="inputBox">
                      <input
                        type="text"
                        name="name"
                        ref={userRef}
                        required
                        value={name}
                        onChange={onInputChange}
                      />
                      <label>E-mail</label>
                    </div>
                    <div className="inputBox">
                      <input
                        type="password"
                        name="password"
                        required
                        value={password}
                        onChange={onInputChange}
                      />
                      <label>Password</label>
                    </div>
                    <div>
                      <label className="forget_password">
                        Forget Password?
                      </label>
                      <input
                        type="submit"
                        name="sign-in"
                        value="Sign In"
                        className="align-center"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
