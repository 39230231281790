import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import React, { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import ClientVehicleInfoContext from "context/ClientVehicleProvider";
import { StatusEnum } from "context/globalEnums";

export default function BillingInfo() {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );
  const { getStatus } = clientVehicleInfo;

  const { id } = useParams();
  const location = useLocation();

  const onInputChange = (e) => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Card className="draft_card mt-4 ms-4">
      <table className="status_Table">
        <tbody>
          <tr>
            <td>Status:</td>
            <td>
              <div className="field-radiobutton">
                <RadioButton
                  inputId="draft"
                  name="getStatus"
                  value={StatusEnum.DRAFT}
                  disabled={
                    clientVehicleInfo.status !== StatusEnum.DRAFT &&
                    location.pathname === `/eias/enquires/${id}/view`
                  }
                  onChange={(e) => onInputChange(e)}
                  checked={getStatus === StatusEnum.DRAFT}
                />
                <label htmlFor="draft">Draft</label>
              </div>
            </td>
            <td>
              <div className="field-radiobutton">
                <RadioButton
                  inputId="Issued"
                  name="getStatus"
                  value={StatusEnum.ISSUED}
                  onChange={(e) => onInputChange(e)}
                  checked={getStatus === StatusEnum.ISSUED}
                />
                <label htmlFor="Issued">Issued</label>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td colSpan={2} className="text-center">
              <Button
                icon="pi pi-save"
                type="submit"
                label="Save"
                className="p-button-custom"
              ></Button>
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
}
