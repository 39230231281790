import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAxiosPrivate from "context/useAxiosPrivate";
import "../Enquires/ExistingEnquires/datatable.css";
import { Link } from "react-router-dom";

export default function BusinessPartners() {
  const axiosPrivate = useAxiosPrivate();

  const [bizPartner, setBizPartner] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dialogTitle, setdialogTitle] = useState([]);
  const [addBizPartnerModal, setAddBizPartnerModal] = useState(false);
  const dt = useRef(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const toast = useRef(null);
  const formRef = useRef();
  const [loading, setLoading] = useState(true);

  const [lazyState, setlazyState] = useState({
    sortField: "code",
    sortOrder: -1,
  });

  const [searchKeyword, setSearchKeyword] = useState("");

  // Load Data to Datatable
  const onSort = (event) => {
    setlazyState(event);
  };

  useEffect(() => {
    loadBizPartner();
    // eslint-disable-next-line
  }, [first, rows, searchKeyword, lazyState]);

  const loadBizPartner = async () => {
    setLoading(true);

    const sortOrder = lazyState.sortOrder === -1 ? "desc" : "asc";

    const result = await axiosPrivate.post(
      `${process.env.REACT_APP_API_URL}/biz_partner/search`,
      {
        searchKey: searchKeyword ? searchKeyword.trim() : null,
      },
      {
        params: {
          page: page,
          size: rows,
          sort: lazyState.sortField + "," + sortOrder,
        },
      }
    );
    setBizPartner(result.data.content);
    setTotalRecords(result.data.totalElements);
    setLoading(false);
  };

  // End of Load Data to Datatable

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  // Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  // Datatable Header
  const header = (
    <div className="table-header">
      <Button
        label="New"
        icon="pi pi-plus"
        className="p-button-success p-button-rounded p-button-sm mx-0 my-1"
        onClick={() => {
          openAddBizPartnerModal();
          reset();
          setdialogTitle("Add Biz Partner Record");
        }}
      />
      <div className="table-header-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={searchKeyword}
            onChange={(event) => setSearchKeyword(event.target.value)}
            placeholder="Keyword Search"
          />
        </span>

        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
          onClick={exportCSV}
        />
      </div>
    </div>
  );

  // Modal's Footer
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Save"
          icon="pi pi-check"
          type="submit"
          form="form1"
          ref={formRef}
        />
      </div>
    );
  };

  // Datatable action column field
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success me-2"
          onClick={() => {
            reset();
            editBizPartner(rowData);
            setdialogTitle("Update Biz Partner Record");
          }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => {
            setValue("code", rowData.code);
            setValue("id", rowData.id);
            deleteBizPartner();
          }}
        />
      </div>
    );
  };

  const outstandingTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Link
          to={`/eias/enquires/payment/bizPartner/${rowData.id}/${new Date()}`}
        >
          {numberWithCommas(rowData.totalOutstanding) ?? "-"}
        </Link>
      </React.Fragment>
    );
  };

  // Open Modal for Add Record
  function openAddBizPartnerModal() {
    setAddBizPartnerModal(!addBizPartnerModal);
  }

  // Dropdown Options
  const status_select = [{ name: "ENABLED" }, { name: "DISABLED" }];

  // Form's Default Value
  const defaultValues = {
    code: "",
    name: "",
    businessRegNo: "",
    address: "",
    pic: "",
    phoneNumber1: "",
    phoneNumber2: "",
    phoneNumber3: "",
    email: "",
    commissionMotor: 0,
    commissionNonMotor: 0,
    status: "ENABLED",
    overTheCounter: false,
  };

  // Form's Controller
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  // Submit Form
  const onSubmit = async (data) => {
    // Post Method
    if (data.id === undefined) {
      try {
        // eslint-disable-next-line
        const response = await axiosPrivate.post(
          `${process.env.REACT_APP_API_URL}/biz_partner`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        loadBizPartner();
        openAddBizPartnerModal();
        toast.current.show({
          severity: "success",
          summary: "Biz Partner Created",
          detail: (
            <span>
              Added Biz Partner Number:-&nbsp;
              <b>"{getValues("code").toLocaleUpperCase()}"</b>
            </span>
          ),
          life: 3000,
        });
        reset();
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: error.response.data[0].description,
        });
      }
    }

    // Put Method
    else {
      try {
        // eslint-disable-next-line
        const response = await axiosPrivate.put(
          `${process.env.REACT_APP_API_URL}/biz_partner/${data.id}`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        loadBizPartner();
        openAddBizPartnerModal();
        toast.current.show({
          severity: "success",
          summary: "Biz Partner Updated",
          detail: (
            <span>
              Updated Biz Partner Number:-&nbsp;
              <b>"{getValues("code").toLocaleUpperCase()}"</b>
            </span>
          ),
          life: 3000,
        });
        setTimeout(() => {
          reset();
        }, 200);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: error.response.data[0].description,
        });
      }
    }
  };

  // Form's Controller for validation
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  // Edit Vehicle Record
  const editBizPartner = async (bizPartnerRecord) => {
    setValue("code", bizPartnerRecord.code);
    setValue("name", bizPartnerRecord.name);
    setValue("businessRegNo", bizPartnerRecord.businessRegNo);
    setValue("address", bizPartnerRecord.address);
    setValue("pic", bizPartnerRecord.pic);
    setValue("phoneNumber1", bizPartnerRecord.phoneNumber1);
    setValue("phoneNumber2", bizPartnerRecord.phoneNumber2);
    setValue("phoneNumber3", bizPartnerRecord.phoneNumber3);
    setValue("email", bizPartnerRecord.email);
    setValue("commissionMotor", bizPartnerRecord.commissionMotor);
    setValue("commissionNonMotor", bizPartnerRecord.commissionNonMotor);
    setValue("overTheCounter", bizPartnerRecord.overTheCounter === "true");
    setValue("status", bizPartnerRecord.status);
    setValue("id", bizPartnerRecord.id);
    openAddBizPartnerModal();
  };

  // Delete Vehicle Record

  // onClick "Yes" button
  const accept = async () => {
    try {
      // eslint-disable-next-line
      const response = await axiosPrivate.delete(
        `${process.env.REACT_APP_API_URL}/biz_partner/${getValues("id")}`,
        JSON.stringify(),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      loadBizPartner();
      toast.current.show({
        severity: "info",
        summary: "Biz Partner Deleted",
        detail: (
          <span>
            Deleted Biz Partner:-&nbsp;
            <b>"{getValues("code").toLocaleUpperCase()}"</b>
          </span>
        ),
        life: 3000,
      });
      reset();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: error.response.data[0].description,
      });
    }
  };

  // onClick "No" button
  const reject = () => {
    reset();
  };

  // Open Delete Vehicle Modal
  const deleteBizPartner = () => {
    confirmDialog({
      message: (
        <span>
          Do you want to delete Biz Partner:-&nbsp;
          <b>"{getValues("code").toLocaleUpperCase()}"</b>
        </span>
      ),
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };
  // End of Delete Vehicle Record

  return (
    <section className="pt-2 pb-5 bg-theme">
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Business Partners Management</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={bizPartner}
              loading={loading}
              lazy={true}
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              header={header}
              onPage={onCustomPage}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              resizableColumns
              columnResizeMode="fit"
              showGridlines
              size="small"
            >
              <Column
                field="code"
                header="Code"
                filter
                filterMatchMode="Contains"
                sortable
              ></Column>
              <Column field="name" header="Name" sortable></Column>
              <Column field="pic" header="P.I.C" sortable></Column>
              <Column
                field="totalOutstanding"
                header="Oustanding Amount (RM)"
                sortable
                body={outstandingTemplate}
              ></Column>
              <Column field="status" header="Status" sortable></Column>
              <Column
                header="Action"
                body={actionBodyTemplate}
                exportable={false}
                style={{ width: "10%" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="form1">
        <Dialog
          onKeyUp={(e) => {
            const ENTER = 13;

            if (e.keyCode === ENTER) {
              e.preventDefault();
              formRef.current.click();
            }
          }}
          header={dialogTitle}
          visible={addBizPartnerModal}
          style={{ width: "35vw" }}
          onHide={openAddBizPartnerModal}
          footer={renderFooter()}
        >
          <div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="code"
                  control={control}
                  rules={{ required: "Code is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="code"
                  className={classNames({ "p-error": errors.name })}
                >
                  Code *
                </label>
              </span>
              {getFormErrorMessage("code")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Name is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="name"
                  className={classNames({ "p-error": errors.name })}
                >
                  Name *
                </label>
              </span>
              {getFormErrorMessage("name")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="businessRegNo"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="businessRegNo">Business Reg. No.</label>
              </span>
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="address"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="address">Address</label>
              </span>
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="pic"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="pic">P.I.C</label>
              </span>
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="phoneNumber1"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="phoneNumber1">Phone Number 1</label>
              </span>
              {getFormErrorMessage("phoneNumber1")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="phoneNumber2"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="phoneNumber2">Phone Number 2</label>
              </span>
              {getFormErrorMessage("phoneNumber2")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="phoneNumber3"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="phoneNumber3">Phone Number 3</label>
              </span>
              {getFormErrorMessage("phoneNumber3")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address. E.g. example@email.com",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="email">E-mail</label>
              </span>
              {getFormErrorMessage("email")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="commissionMotor"
                  control={control}
                  rules={{
                    required: "Commission Motor is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id={field.name}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                      ref={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e)}
                      mode="decimal"
                      minFractionDigits={2}
                      showButtons
                      suffix=" %"
                      min={0}
                      max={100}
                    />
                  )}
                />
                <label
                  htmlFor="commissionMotor"
                  className={classNames({ "p-error": errors.name })}
                >
                  Commission Motor *
                </label>
              </span>
              {getFormErrorMessage("commissionMotor")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="commissionNonMotor"
                  control={control}
                  rules={{
                    required: "Commission Non Motor is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id={field.name}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                      ref={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e)}
                      mode="decimal"
                      minFractionDigits={2}
                      showButtons
                      suffix=" %"
                      min={0}
                      max={100}
                    />
                  )}
                />
                <label
                  htmlFor="commissionNonMotor"
                  className={classNames({ "p-error": errors.name })}
                >
                  Commission Non Motor *
                </label>
              </span>
              {getFormErrorMessage("commissionNonMotor")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: "Status is required." }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      options={status_select}
                      optionLabel="name"
                      optionValue="name"
                      id={field.name}
                      {...field}
                      className={classNames("width_full", "padding-normal", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="status"
                  className={classNames({ "p-error": errors.name })}
                >
                  Status *
                </label>
              </span>
              {getFormErrorMessage("status")}
            </div>

            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="overTheCounter"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Checkbox
                      id={field.name}
                      checked={field.value}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="overTheCounter" className="custom-label">
                  Over The Counter
                </label>
                {getFormErrorMessage("overTheCounter")}
              </span>
            </div>
          </div>
        </Dialog>
      </form>
    </section>
  );
}
