import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useLocation, useParams } from "react-router-dom";
import ClientVehicleInfoContext from "context/ClientVehicleProvider";
import { StatusEnum } from "context/globalEnums";
import ModalBenefits from "./Modal/ModalBenefits";

export default function PremiumInfo() {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const { id } = useParams();
  const location = useLocation();

  const [benefitsModal, setBenefitsModal] = useState(false);

  function openBenefitsModal() {
    setBenefitsModal(!benefitsModal);
  }

  const {
    //Motor Premium
    premiumSumInsured,
    premiumExcess,
    premiumBasicPremium,
    premiumTrailerSchedulePremium,
    premiumAllRiderPercentage,
    premiumAllRider,
    premiumSubTotal1,
    premiumLoadingChargePercentage,
    premiumLoadingCharge,
    premiumSubTotal2,
    premiumNcdPercentage,
    premiumNcd,
    premiumServiceTaxPercentage,
    premiumServiceTax,
    premiumStampDuty,
    premiumTransferFees,
    premiumTotalMotorPremium,

    //Non Motor Premium
    nonMotorBasicPremium,
    nonMotorGovtTax,
    nonMotorTotal,
    nonMotorStampDuty,
    nonMotorOtherCharges,
    nonMotorTotalPremium,

    //Sup Svc
    premiumRoadTaxTotal,
    premiumLicenseTotal,
    premiumJpjRenewal,
    premiumOthersTotal,
    premiumTotalSupportingServices,

    //Benefits
    premiumExtraBenefitsTotal,

    //Total
    premiumTotalAmountPayable,
    premiumGrossPremium,
    premiumPrincipalCommission,
    premiumNetPremium,
  } = clientVehicleInfo;

  const onInputChange = (e) => {
    const { name, value } = e.target;

    // Parsing necessary values once
    const parsedValue = parseFloat(value);
    const premiumBasicPremium = parseFloat(
      clientVehicleInfo.premiumBasicPremium
    );
    const premiumSubTotal1 = parseFloat(clientVehicleInfo.premiumSubTotal1);

    // Initialize updates object with the base change
    let updates = {
      ...clientVehicleInfo,
      [name]: value,
    };

    // Compute dependent values based on the changed input
    switch (name) {
      case "premiumBasicPremium":
        updates.premiumAllRider = (
          parsedValue *
          (parseFloat(clientVehicleInfo.premiumAllRiderPercentage) / 100)
        ).toFixed(2);
        break;

      case "premiumAllRider":
        updates.premiumAllRiderPercentage = (
          (parsedValue / premiumBasicPremium) *
          100
        ).toFixed(2);
        break;

      case "premiumAllRiderPercentage":
        updates.premiumAllRider = (
          premiumBasicPremium *
          (parsedValue / 100)
        ).toFixed(2);
        break;

      case "premiumLoadingCharge":
        updates.premiumLoadingChargePercentage = (
          (parsedValue / premiumSubTotal1) *
          100
        ).toFixed(2);
        break;

      case "premiumLoadingChargePercentage":
        updates.premiumLoadingCharge = (
          premiumSubTotal1 *
          (parsedValue / 100)
        ).toFixed(2);
        break;

      default:
        break;
    }

    setClientVehicleInfo(updates);
  };

  useEffect(() => {
    const premiumSubTotal = (
      parseFloat(premiumBasicPremium) +
      parseFloat(premiumTrailerSchedulePremium) +
      parseFloat(premiumAllRider)
    ).toFixed(2);

    setClientVehicleInfo((prev) => ({
      ...prev,
      premiumSubTotal1: premiumSubTotal,
    }));
    // eslint-disable-next-line
  }, [premiumBasicPremium, premiumTrailerSchedulePremium, premiumAllRider]);

  useEffect(() => {
    const premiumSubTotal =
      parseFloat(premiumSubTotal1) + parseFloat(premiumLoadingCharge);
    setClientVehicleInfo((prev) => ({
      ...prev,
      premiumSubTotal2: premiumSubTotal.toFixed(2),
    }));
    // eslint-disable-next-line
  }, [premiumSubTotal1, premiumLoadingCharge]);

  useEffect(() => {
    const premiumSubTotal = parseFloat(premiumSubTotal2);
    const ncdPercentage = parseFloat(premiumNcdPercentage) / 100;
    const premiumNcd = premiumSubTotal * ncdPercentage;
    const premiumGross =
      premiumSubTotal + parseFloat(premiumExtraBenefitsTotal) - premiumNcd;

    setClientVehicleInfo((prev) => ({
      ...prev,
      premiumNcd: premiumNcd.toFixed(2),
      premiumGrossPremium: premiumGross.toFixed(2),
    }));
    // eslint-disable-next-line
  }, [premiumSubTotal2, premiumExtraBenefitsTotal, premiumNcdPercentage]);

  useEffect(() => {
    const parse = (value) => parseFloat(value) || 0;

    const calculate = (base, percentage) =>
      (parse(base) * (parse(percentage) / 100)).toFixed(2);

    const total = (...values) =>
      values.reduce((sum, value) => sum + parse(value), 0).toFixed(2);

    const premiumServiceTax = calculate(
      premiumGrossPremium,
      premiumServiceTaxPercentage
    );
    const billingDiscount = calculate(
      premiumGrossPremium,
      clientVehicleInfo.motorPartnerCommissionRate
    );
    const billingDiscountNonMotor = calculate(
      nonMotorBasicPremium,
      clientVehicleInfo.nonMotorPartnerCommission
    );

    const premiumTotalMotorPremium = total(
      premiumGrossPremium,
      premiumStampDuty,
      premiumTransferFees,
      premiumServiceTax
    );

    const premiumPrincipalCommission = calculate(
      premiumGrossPremium,
      clientVehicleInfo.motorPrincipalTariff
    );

    const premiumNetPremium = (
      parse(premiumTotalMotorPremium) - parse(premiumPrincipalCommission)
    ).toFixed(2);

    const premiumTotalAmountPayable = total(
      premiumGrossPremium,
      premiumStampDuty,
      premiumTransferFees,
      premiumServiceTax,
      nonMotorTotalPremium,
      premiumTotalSupportingServices
    );

    setClientVehicleInfo((prev) => ({
      ...prev,
      premiumServiceTax,
      billingDiscount,
      billingDiscountNonMotor,
      premiumTotalMotorPremium,
      premiumPrincipalCommission,
      premiumNetPremium,
      premiumTotalAmountPayable,
      billingSubtotal: premiumTotalAmountPayable,
      billingMotorPremium: premiumTotalMotorPremium,
      billingNonMotorPremium: parse(nonMotorTotalPremium).toFixed(2),
      billingSupportingServices: parse(premiumTotalSupportingServices).toFixed(
        2
      ),
    }));
    // eslint-disable-next-line
  }, [
    premiumGrossPremium,
    premiumStampDuty,
    premiumTransferFees,
    premiumServiceTaxPercentage,
    clientVehicleInfo.motorPrincipalTariff,
    clientVehicleInfo.motorPartnerCommissionRate,
    nonMotorTotalPremium,
    premiumTotalSupportingServices,
  ]);

  return (
    <div className="overflow-auto mt-4 container_custom">
      <Container>
        <Row>
          <Col sm>
            <div className="overflow-auto">
              <table className="premium_Table" border="primary">
                <tbody>
                  <tr>
                    <td colSpan="4" className="header_Title">
                      Motor Premium
                    </td>
                  </tr>
                  <tr>
                    <td>Sum Insured</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premiumSumInsured"
                        value={premiumSumInsured ?? "0.00"}
                        disabled={
                          clientVehicleInfo.status !== StatusEnum.DRAFT &&
                          location.pathname === `/eias/enquires/${id}/view`
                        }
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>Excess</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premiumExcess"
                        value={premiumExcess ?? "0.00"}
                        disabled={
                          clientVehicleInfo.status !== StatusEnum.DRAFT &&
                          location.pathname === `/eias/enquires/${id}/view`
                        }
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Basic Premium</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premiumBasicPremium"
                        value={premiumBasicPremium ?? "0.00"}
                        disabled={
                          clientVehicleInfo.status !== StatusEnum.DRAFT &&
                          location.pathname === `/eias/enquires/${id}/view`
                        }
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">+ Trailer Schedule Premium</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premiumTrailerSchedulePremium"
                        value={premiumTrailerSchedulePremium ?? "0.00"}
                        disabled={
                          clientVehicleInfo.status !== StatusEnum.DRAFT &&
                          location.pathname === `/eias/enquires/${id}/view`
                        }
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>+ All Rider</td>
                    <td colSpan="2">
                      <div className="d-flex float-end">
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control input_percentage"
                          name="premiumAllRiderPercentage"
                          value={premiumAllRiderPercentage ?? "0.00"}
                          disabled={
                            clientVehicleInfo.status !== StatusEnum.DRAFT &&
                            location.pathname === `/eias/enquires/${id}/view`
                          }
                          onChange={(e) => onInputChange(e)}
                        />
                        <span className="align-self-center">&nbsp;%</span>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premiumAllRider"
                        value={premiumAllRider ?? "0.00"}
                        disabled={
                          clientVehicleInfo.status !== StatusEnum.DRAFT &&
                          location.pathname === `/eias/enquires/${id}/view`
                        }
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Sub Total 1</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premiumSubTotal1"
                        value={premiumSubTotal1 ?? "0.00"}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td>+ Loading Charge</td>
                    <td colSpan="2">
                      <div className="d-flex float-end">
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control input_percentage"
                          name="premiumLoadingChargePercentage"
                          value={premiumLoadingChargePercentage ?? "0.00"}
                          disabled={
                            clientVehicleInfo.status !== StatusEnum.DRAFT &&
                            location.pathname === `/eias/enquires/${id}/view`
                          }
                          onChange={(e) => onInputChange(e)}
                        />
                        <span className="align-self-center">&nbsp;%</span>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premiumLoadingCharge"
                        value={premiumLoadingCharge ?? "0.00"}
                        disabled={
                          clientVehicleInfo.status !== StatusEnum.DRAFT &&
                          location.pathname === `/eias/enquires/${id}/view`
                        }
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Sub Total 2</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premiumSubTotal2"
                        value={premiumSubTotal2 ?? "0.00"}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td>- NCD</td>
                    <td colSpan="2">
                      <div className="d-flex float-end">
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control input_percentage"
                          name="premiumNcdPercentage"
                          value={premiumNcdPercentage ?? "0.00"}
                          disabled={
                            clientVehicleInfo.status !== StatusEnum.DRAFT &&
                            location.pathname === `/eias/enquires/${id}/view`
                          }
                          onChange={(e) => onInputChange(e)}
                        />
                        <span className="align-self-center">&nbsp;%</span>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premiumNcd"
                        value={premiumNcd ?? "0.00"}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">+ Extra Benefits</td>
                    <td className="text-end">
                      <FontAwesomeIcon
                        icon="database"
                        className="ms-2 icon"
                        onClick={openBenefitsModal}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premiumExtraBenefitsTotal"
                        value={premiumExtraBenefitsTotal ?? "0.00"}
                        disabled={
                          clientVehicleInfo.status !== StatusEnum.DRAFT &&
                          location.pathname === `/eias/enquires/${id}/view`
                        }
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Gross Premium</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premiumGrossPremium"
                        value={premiumGrossPremium ?? "0.00"}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td>+ Service Tax</td>
                    <td colSpan="2">
                      <div className="d-flex float-end">
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control input_percentage"
                          name="premiumServiceTaxPercentage"
                          value={premiumServiceTaxPercentage ?? "0.00"}
                          disabled={
                            clientVehicleInfo.status !== StatusEnum.DRAFT &&
                            location.pathname === `/eias/enquires/${id}/view`
                          }
                          onChange={(e) => onInputChange(e)}
                        />
                        <span className="align-self-center">&nbsp;%</span>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premiumServiceTax"
                        value={premiumServiceTax ?? "0.00"}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">+ Stamp Duty</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premiumStampDuty"
                        value={premiumStampDuty ?? "0.00"}
                        disabled={
                          clientVehicleInfo.status !== StatusEnum.DRAFT &&
                          location.pathname === `/eias/enquires/${id}/view`
                        }
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">+ Transfer Fees</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premiumTransferFees"
                        value={premiumTransferFees ?? "0.00"}
                        disabled={
                          clientVehicleInfo.status !== StatusEnum.DRAFT &&
                          location.pathname === `/eias/enquires/${id}/view`
                        }
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Total Motor Premium</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premiumTotalMotorPremium"
                        value={premiumTotalMotorPremium ?? "0.00"}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Principal Commission</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premiumPrincipalCommission"
                        value={premiumPrincipalCommission ?? "0.00"}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Net Premium</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premiumNetPremium"
                        value={premiumNetPremium ?? "0.00"}
                        readOnly
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="mt-5"></div>
            </div>
          </Col>
          <Col sm>
            <div className="overflow-auto">
              <div className="overflow-auto">
                <table className="premium_Table_Non_Motor" border="primary">
                  <tbody>
                    <tr>
                      <td colSpan="3" className="header_Title">
                        Non Motor / Medical Premium
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">Basic Premium</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control"
                          name="nonMotorBasicPremium"
                          value={nonMotorBasicPremium ?? "0.00"}
                          disabled={
                            clientVehicleInfo.status !== StatusEnum.DRAFT &&
                            location.pathname === `/eias/enquires/${id}/view`
                          }
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>+ Govt Tax</td>
                      <td>
                        <div className="d-flex float-end">
                          <input
                            type="number"
                            min="0"
                            step={0.01}
                            className="form-control input_percentage"
                            name="nonMotorGovtTax"
                            value={nonMotorGovtTax ?? "0.00"}
                            disabled={
                              clientVehicleInfo.status !== StatusEnum.DRAFT &&
                              location.pathname === `/eias/enquires/${id}/view`
                            }
                            onChange={(e) => onInputChange(e)}
                          />
                          <span className="align-self-center">&nbsp;%</span>
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="nonMotorTotal"
                          value={nonMotorTotal ?? "0.00"}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">+ Stamp Duty</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control"
                          name="nonMotorStampDuty"
                          value={nonMotorStampDuty ?? "0.00"}
                          disabled={
                            clientVehicleInfo.status !== StatusEnum.DRAFT &&
                            location.pathname === `/eias/enquires/${id}/view`
                          }
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">+ Other Charges</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control"
                          name="nonMotorOtherCharges"
                          value={nonMotorOtherCharges ?? "0.00"}
                          disabled={
                            clientVehicleInfo.status !== StatusEnum.DRAFT &&
                            location.pathname === `/eias/enquires/${id}/view`
                          }
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <p className="div_bottom_line"></p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">Total Non-Motor Premium</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="nonMotorTotalPremium"
                          value={nonMotorTotalPremium ?? "0.00"}
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mt-5"></div>

              <div className="overflow-auto">
                <table className="premium_Table_Sup_Svc" border="primary">
                  <tbody>
                    <tr>
                      <td colSpan="2" className="header_Title">
                        Supporting Services
                      </td>
                    </tr>
                    <tr>
                      <td>RoadTax Total</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premiumRoadTaxTotal"
                          value={premiumRoadTaxTotal ?? "0.00"}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>License Total</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premiumLicenseTotal"
                          value={premiumLicenseTotal ?? "0.00"}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>JPJ Renewal</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premiumJpjRenewal"
                          value={premiumJpjRenewal ?? "0.00"}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Others Total</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premiumOthersTotal"
                          value={premiumOthersTotal ?? "0.00"}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <p className="div_bottom_line"></p>
                      </td>
                    </tr>
                    <tr>
                      <td>Total Supporting Services</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premiumTotalSupportingServices"
                          value={premiumTotalSupportingServices ?? "0.00"}
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mt-5"></div>

              <div className="overflow-auto">
                <table className="premium_Table_Sup_Svc" border="primary">
                  <tbody>
                    <tr>
                      <td className="pe-4">Total Amount Payable</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premiumTotalAmountPayable"
                          value={premiumTotalAmountPayable ?? ""}
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="overflow-auto one"></div>
      <ModalBenefits toggle={benefitsModal} action={openBenefitsModal} />
    </div>
  );
}
