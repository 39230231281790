import { StatusEnum } from "context/globalEnums";
import useAxiosPrivate from "context/useAxiosPrivate";
import { format } from "date-fns";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Row } from "primereact/row";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../Enquires/ExistingEnquires/datatable.css";

export default function ProductionNonMotor() {
  const axiosPrivate = useAxiosPrivate();

  const [nonMotorReport, setNonMotorReport] = useState([]);
  const dt = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [nonMotorReportTotal, setNonMotorReportTotal] = useState([]);

  const [lazyState, setlazyState] = useState({
    sortField: "nonMotorIssuedDate",
    sortOrder: 1,
  });

  // Start of Search filter Value
  const [searchPrincipal, setSearchPrincipal] = useState(null);
  const [searchStatus, setSearchStatus] = useState("ALL");
  const [searchVehicleNo, setSearchVehicleNo] = useState(null);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
  );
  // End of Search filter Value

  // Start of Dropdown Options
  const [principalDropdown, setPrincipalDropdown] = useState([]);

  const statusDropdown = [
    { name: "ALL" },
    { name: StatusEnum.ISSUED },
    { name: StatusEnum.CLOSED },
    { name: StatusEnum.SUBMITTED },
  ];

  useEffect(() => {
    loadPrincipal();
    // eslint-disable-next-line
  }, []);

  const loadPrincipal = async (e) => {
    const result_principal = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/principal/names`
    );
    setPrincipalDropdown(result_principal.data);
  };
  // End of Dropdown Options

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Start of onSort -> set value and retrigger get method for both motor and non-motor
  const onSort = (event) => {
    setlazyState(event);
  };

  useEffect(() => {
    loadNonMotorReport();
    // eslint-disable-next-line
  }, [
    first,
    rows,
    lazyState,
    searchPrincipal,
    searchStatus,
    searchVehicleNo,
    startDate,
    endDate,
  ]);
  // End of onSort -> set value and retrigger get method for both motor and non-motor

  // Start of Load Data to Datatable with filter search
  const loadNonMotorReport = async () => {
    setLoading(true);

    const sortOrder = lazyState.sortOrder === -1 ? "desc" : "asc";

    const result = await axiosPrivate.post(
      `${process.env.REACT_APP_API_URL}/report/production_non_motor/search`,
      {
        vehicleNo: searchVehicleNo ? searchVehicleNo.trim() : null,
        principalId: searchPrincipal ? searchPrincipal : null,
        status: searchStatus === "ALL" ? null : searchStatus,
        startDate: format(new Date(startDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        endDate: format(new Date(endDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      },
      {
        params: {
          page: page,
          size: rows,
          sort: lazyState.sortField + "," + sortOrder,
        },
      }
    );

    let resultData = result.data.content;
    let getNonMotorBasicPremium = 0.0;
    let getNonMotorStampDuty = 0.0;
    let getNonMotorOtherCharges = 0.0;
    let getNonMotorTotal = 0.0;
    let getNonMotorPrincipalCommission = 0.0;
    let getNonMotorGrossPremium = 0.0;

    resultData.forEach((item) => {
      getNonMotorBasicPremium += parseFloat(item.nonMotorBasicPremium);
      getNonMotorStampDuty += parseFloat(item.nonMotorStampDuty);
      getNonMotorOtherCharges += parseFloat(item.nonMotorOtherCharges);
      getNonMotorTotal += parseFloat(item.nonMotorTotal);
      getNonMotorPrincipalCommission += parseFloat(
        item.nonMotorPrincipalCommission
      );
      getNonMotorGrossPremium += parseFloat(item.nonMotorGrossPremium);
    });

    setNonMotorReportTotal({
      nonMotorBasicPremium: numberWithCommas(getNonMotorBasicPremium),
      nonMotorStampDuty: numberWithCommas(getNonMotorStampDuty),
      nonMotorOtherCharges: numberWithCommas(getNonMotorOtherCharges),
      nonMotorTotal: numberWithCommas(getNonMotorTotal),
      nonMotorPrincipalCommission: numberWithCommas(
        getNonMotorPrincipalCommission
      ),
      nonMotorGrossPremium: numberWithCommas(getNonMotorGrossPremium),
    });

    setNonMotorReport(result.data.content);
    setTotalRecords(result.data.totalElements);
    setLoading(false);
  };
  // End of Load Data to Datatable with filter search

  // Start of Motor Datatable Options
  // -- Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  // Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  // -- Datatable Header
  const header = (
    <div className=" overflow-auto">
      <div className="container ms-0 ps-0 mt-4">
        <div className="row row-cols-auto">
          <div className="col">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className="pi pi-briefcase"></i>
              </span>
              <Dropdown
                value={searchPrincipal}
                options={principalDropdown}
                optionLabel="code"
                optionValue="id"
                placeholder="Principal"
                className="custom_padding"
                onChange={(event) => setSearchPrincipal(event.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className="pi pi-file"></i>
              </span>
              <Dropdown
                value={searchStatus}
                options={statusDropdown}
                onChange={(event) => setSearchStatus(event.target.value)}
                optionLabel="name"
                optionValue="name"
                placeholder="Status"
                className="custom_padding"
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className="pi pi-car"></i>
              </span>
              <InputText
                placeholder="Vehicle No"
                value={searchVehicleNo ?? ""}
                onChange={(event) => setSearchVehicleNo(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row row-cols-auto mt-3">
          <div className="d-flex col-12">
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="startDate"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.value);
              }}
            />
            <span className="d-flex align-self-center px-2">to</span>
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="endDate"
              value={endDate}
              onChange={(e) => {
                const selectedDate = e.value;
                if (selectedDate) {
                  selectedDate.setHours(23, 59, 59, 999);
                  setEndDate(selectedDate);
                }
              }}
            />
          </div>
        </div>
      </div>
      <br></br>
      <div className="table-header">
        <h5>
          <b>Motor:</b>
        </h5>
        <div className="table-header-2">
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
            onClick={exportCSV}
          />
        </div>
      </div>
    </div>
  );

  // -- Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={6}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={nonMotorReportTotal.nonMotorBasicPremium}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={nonMotorReportTotal.nonMotorStampDuty}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={nonMotorReportTotal.nonMotorOtherCharges}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={nonMotorReportTotal.nonMotorTotal}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={nonMotorReportTotal.nonMotorPrincipalCommission}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={nonMotorReportTotal.nonMotorGrossPremium}
          footerStyle={{ textAlign: "right" }}
        />
      </Row>
    </ColumnGroup>
  );
  // End of Motor Datatable Options

  const nonMotorCNBody = (rowData) => {
    let id = rowData.id;
    let cn = rowData.nonMotorCoverNote;
    return (
      <React.Fragment>
        <Link to={`/eias/enquires/${id}/view`} target={"_blank"}>
          {cn ? cn : ""}
        </Link>
      </React.Fragment>
    );
  };

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Production: Non Motor</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={nonMotorReport}
              loading={loading}
              lazy={true}
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              header={header}
              onPage={onCustomPage}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              columnResizeMode="fit"
              showGridlines
              size="small"
              footerColumnGroup={footerGroup}
            >
              <Column
                field="nonMotorIssuedDate"
                header="Date"
                sortable
                body={(data) =>
                  format(new Date(data.nonMotorIssuedDate), "dd/MM/yyyy") ?? "-"
                }
              ></Column>
              <Column
                field="principalCode"
                header="Principal"
                sortable
              ></Column>
              <Column
                header="Name (NRIC)"
                field="name"
                sortable
                body={(data) => data.name.toUpperCase() ?? "-"}
              ></Column>
              <Column field="vehicleNo" header="Vehicle No." sortable></Column>
              <Column
                field="nonMotorCoverNote"
                header="Cover Note No."
                body={nonMotorCNBody}
              ></Column>
              <Column field="status" header="Status"></Column>
              <Column
                field="nonMotorBasicPremium"
                header="Gross Prem."
                style={{ textAlign: "right" }}
                body={(data) =>
                  numberWithCommas(data.nonMotorBasicPremium) ?? "-"
                }
              ></Column>
              <Column
                field="nonMotorStampDuty"
                header="Stamp Duty"
                style={{ textAlign: "right" }}
                body={(data) => numberWithCommas(data.nonMotorStampDuty) ?? "-"}
              ></Column>
              <Column
                field="nonMotorOtherCharges"
                header="Other Charges"
                style={{ textAlign: "right" }}
                body={(data) =>
                  numberWithCommas(data.nonMotorOtherCharges) ?? "-"
                }
              ></Column>
              <Column
                field="nonMotorTotal"
                header="Govt Tax"
                style={{ textAlign: "right" }}
                body={(data) => numberWithCommas(data.nonMotorTotal) ?? "-"}
              />
              <Column
                field="nonMotorPrincipalCommission"
                header="Principal Comm."
                style={{ textAlign: "right" }}
                body={(data) =>
                  numberWithCommas(data.nonMotorPrincipalCommission) ?? "-"
                }
              />
              <Column
                field="nonMotorGrossPremium"
                header="Net Prem."
                style={{ textAlign: "right" }}
                body={(data) =>
                  numberWithCommas(data.nonMotorGrossPremium) ?? "-"
                }
              />
            </DataTable>
          </div>
        </div>
      </div>
    </section>
  );
}
